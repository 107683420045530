import React from "react";
import '../components/Style.css';
import Navbar from "../components/header/Navbar";
import img from '../asset/images/img.png'

import dwd from '../asset/images/dowload-icon.png'
import { useLocation, useNavigate, useParams } from "react-router-dom";



export default function PaymentSuccess() {
    const nav =useNavigate()
    const {state}=useLocation()
    let { name, invoice } = useParams();
  return (
    <React.Fragment>
      <section>
       

        <div className="flex flex-col items-center justify-around mx-4 my-10 mt-[130px] md:flex-nowrap flex-wrap">
<img src={img} alt="successimg" className="md:w-[25%] w-[50%]" />
<h2 className="font-[700] text-[32px] my-4">Thank You.</h2>
<p className="font-[500] text-center text-[15px] max-w-[450px] text-[#1F1F1F] mt-4">Thanks for subscribing to {name}, you should receive a confirmation email soon</p>
<p className="font-[500] text-center text-[15px] max-w-[450px] text-[#1F1F1F] flex mt-4">Download Invoice Here <a href={"https://knotrite.in/backend/storage/invoice/"+invoice}> <img src={dwd}  className="ml-2 w-[25px] cursor-pointer" alt="" /> </a></p>
<p className="font-[700] text-center text-[17px]  text-[#363636] mt-4">Go to <br /> <span className="text-[#CD64A3] mt-4 cursor-pointer" onClick={()=>nav("/myaccount")}>My Account</span> </p>

    </div>
</section>
     

    </React.Fragment>
  );
}
