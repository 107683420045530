import { useNavigate } from "react-router-dom";

// import '../../asset/style/style.css'
export default function Content(props) {
  const Nav=useNavigate()
  return (
    <>
    <div className="feature_wrapper flex justify-center">
      <div className={"feature_container mt-[4%] w-[100%] "+props.flow} >
     
        
       
       
          <h4 className="feature_heading md:h-12 text-[#232323] font-[700] text-[18px]">{props.heading}</h4><br />
          <img
            src={props.img}
            alt="chat"
            width={'100%'}
            className="md:min-w-[350px] max-w-[350px] md:my-0 my-4 float-left mr-5 inline rounded-3xl"
          />
          <div className="feaure_sub-heading  inline font-[400] text-[13px] text-justify text-[#363636] leading-7" dangerouslySetInnerHTML={{ __html: props.sub_heading }}>
           
          </div>
          <div >
          {props.taketestbutton==true?<button  className="bg-[#480740] md:w-[20%] font-[600] md:mx-0 mx-auto text-white text-[13px] mt-2  flex justify-around items-center py-[5px] px-5 md:px-2 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer"
                      type="button">Take Free Test</button>: <button
                      onClick={() => Nav("/pricing")}
                      className="  md:w-[20%] mt-2 bg-[#480740] font-[600] mx-auto md:mx-0 text-white text-[13px] mb-4  flex justify-around items-center py-[5px] px-5 md:px-2 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer"
                    >
                      EXPLORE PREMIUM SERVICES
                    </button>
                      }
                   </div>   
          
        
      </div>
      </div>
    </>
  );
}
