
import { Route, Router, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Homepage from './pages/FreeAssessment';
import PaidAssessment from './pages/PaidAssessment.jsx';
import MyAccount from './pages/myAccount';
import ChangePassword from './pages/changePassword';
import ForgotPassword from './components/modal/forgotPassword';
import PricingPlan from './pages/pricingPlan';
import PaymentSuccess from './pages/paymentSuccess';
import TermsCondition from './pages/terms&condition';
import Services from './pages/services';
import AboutUs from './pages/aboutUs';
import ContactUs from './pages/contactUs';
import PlanSummary from './pages/planSummary';
import Home from './pages/home';
import Faq from './pages/Faq';

import MarriageToolkit from './pages/marriageToolkit.jsx';
import MarriageToolkitInsights from './pages/mariageToolkitInsights';
import Footer from './components/Footer';
import Navbar from './components/header/Navbar';
import { ToastContainer } from 'react-toastify';
import KnotRiteCoupleMatch from './reports/coupleMatch';
import IndividualReport from './reports/individualReport';
import KnotRiteReport from './reports/knotRiteReport';
import ProtectedRoute from './services/protetedRoute';
import { useEffect, useState } from 'react';
import { get } from './services/apiHandler';
import LoginPage from './pages/login.jsx';
import SingnUp from './pages/signup.jsx';



function App() {
  
  const [isFixBtnClicked,setisFixBtnClicked]=useState(false)
  const [paidAuth,setPaidAuth]=useState({auth:false,msg:''})
  const [freeAuth,setFreeAuth]=useState({auth:true,msg:'Accessed'})
  const location =useLocation()
  useEffect(()=>{
    get("api/user")
    .then((res) => {
      const freeReport=res.data.data.reports.filter((data)=>data.question_category==4)
      const paidReport=res.data.data.reports.filter((data)=>data.question_category==5&&data.report_data!=null)
      const pauseReport=res.data.data.reports.filter((data)=>data.question_category==5&&data.report_data==null)
      if(pauseReport.length>0)  setPaidAuth({auth:true,msg:"Accessced"})
     else if(paidReport.length>0){
        setPaidAuth({auth:false,msg:"You Already Taken The Test"})
        
      }
      // else if(freeReport.length==0){
      //   setPaidAuth({auth:false,msg:"Please Give Free Assesment Test First"})
      
      // }
     else{
      get("api/user").then((res) => {
        if (res.data.success) {
          

          
  
        if( res.data.data.paid_assessment_remaining<=0){
          setPaidAuth({auth:false,msg:"This is a paid test.Please subscribe in Pricing page"})
          
        }
        else{
          setPaidAuth({auth:true,msg:"Access True"})
          
        }
        }})
       
     }
     if(freeReport.length>0){
      setFreeAuth({auth:false,msg:"You Already Taken The Test"})
    }
    else{
      setFreeAuth({auth:true,msg:"Accessced"})
    }
    
       
    }).catch(()=>{
      // setFreeAuth({auth:false,msg:"Please Login to Access Page"})
      localStorage.removeItem("login");
      setPaidAuth({auth:false,msg:"Please Login to Access Page"})
    })

  },[location.pathname])

//   useEffect(()=>{
// if(window.location.origin!="https://knotrite.in/")window.location.href="https://knotrite.in/"
//   },[location])

  return (
    <>
   
    <Navbar isFixBtnClicked={isFixBtnClicked}/>
    <ToastContainer />
    <Routes>
      <Route path='/' element={<Home/>}/>
    {paidAuth.msg&&  <Route path='/individualPaidTest' element={<ProtectedRoute auth={paidAuth.auth} msg={paidAuth.msg} Component={PaidAssessment}/>}/>}
      <Route path='/myAccount' element={ <MyAccount />}/>
      <Route path='/changepassword' element={ <ChangePassword />}/>
      <Route path='/forgotpassword' element={ <ForgotPassword />}/>
      <Route path='/pricing/:status' element={ <PricingPlan />}/>
      <Route path='/pricing/' element={ <PricingPlan />}/>
      <Route path='/successPayment' element={<PaymentSuccess/>}/>
      <Route path='/successPayment/:name/:invoice' element={<PaymentSuccess/>}/>
      <Route path='/t&c' element={<TermsCondition/>}/>
      <Route path='/services' element={<Services/>}/>
      <Route path='/about-us' element={<AboutUs/>}/>
      <Route path='/contact-us' element={<ContactUs/>}/>
      <Route exact path="/plansummary" element={<PlanSummary/>}/>
     {freeAuth.msg&& <Route path="/free-test" element={<ProtectedRoute auth={freeAuth.auth} msg={freeAuth.msg} Component={Homepage}/>}/>}
      <Route path="/faq" element={<Faq/>}/>
      <Route path="/marriage-toolkit" element={<MarriageToolkit/>}/>
      <Route path="/marriage-toolkit/:url" element={<MarriageToolkitInsights/>}/>
      <Route path="/coupleMatchReport" element={<KnotRiteCoupleMatch/>}/>
      <Route path="/individualReport" element={<IndividualReport/>}/>
      <Route path="/knotritereport" element={<KnotRiteReport/>}/>
      <Route path="/login" element={<LoginPage/>}/>
      <Route path="/signup" element={<SingnUp/>}/>
    </Routes>
    <Footer/>
    
    </>
  );
}

export default App;
