import React from "react";
import "./../Style.css";
import { useNavigate } from "react-router-dom";

const Wave = (props) => {
  const Nav = useNavigate();
  return (
    <>
      <div className=" w-full md:mt-[120px] mt-[90px] ">
        <div className="   flex w-[90%] mx-auto justify-between py-5 shadow-[rgba(0, 0, 0, 0.24)_0px_3px_8px;] items-center">
          <div className="right w-fit">
          
              {" "}
              <p className=" size-20  font-[600] text-[#641662] text-[25px] md:text-[40px]  pb-1 ">
                {" "}
                {props.title}
              </p>
           
            <p className="size-20  sm:text-[15px] font-[600] text-[#641662] text-[12px]  sm:w-fit">
              {" "}
              {props.subTitle}
            </p>
          </div>
          <div className="left  items-center justify-end hidden md:flex">
            <p
              className=" text-[#641662] font-bold w-fit cursor-pointer"
              onClick={() => Nav("/")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0,0,256,256"
              >
                <g
                  fill="#510c70"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                
                >
                  <g transform="scale(5.12,5.12)">
                    <path d="M24.96289,1.05469c-0.20987,0.00724 -0.41214,0.08036 -0.57812,0.20898l-23,17.94727c-0.43579,0.33978 -0.51361,0.96851 -0.17383,1.4043c0.33978,0.43579 0.96851,0.51361 1.4043,0.17383l1.38477,-1.08008v26.29102c0.00006,0.55226 0.44774,0.99994 1,1h13.83203c0.10799,0.01785 0.21818,0.01785 0.32617,0h11.67383c0.10799,0.01785 0.21818,0.01785 0.32617,0h13.8418c0.55226,-0.00006 0.99994,-0.44774 1,-1v-26.29102l1.38477,1.08008c0.2819,0.21983 0.65967,0.27257 0.991,0.13833c0.33133,-0.13423 0.56586,-0.43504 0.61526,-0.7891c0.0494,-0.35406 -0.09386,-0.70757 -0.37579,-0.92736l-7.61523,-5.94141v-7.26953h-6v2.58594l-9.38477,-7.32227c-0.18607,-0.14428 -0.41707,-0.21828 -0.65234,-0.20898zM25,3.32227l19,14.82617v26.85156h-12v-19h-14v19h-12v-26.85156zM37,8h2v3.70898l-2,-1.5625zM20,28h10v17h-10z"></path>
                  </g>
                </g>
              </svg>
            </p>
            {props.subCurrent&&<><h2 className="text-[#641662] font-extrabold  text-3xl mb-2">
              {" "}
             &nbsp; &#8594;    &nbsp;
            </h2>
           <p className="text-[#641662] sm:text-[15px] text-[10px] cursor-pointer" onClick={()=>Nav(props.link)}>
              {props.subCurrent}
            </p></> }
            <h2 className="text-[#641662] font-extrabold  text-3xl mb-2">
              {" "}
             &nbsp; &#8594;    &nbsp;
            </h2>
            <p className="text-[#641662] sm:text-[15px] text-[10px] cursor-pointer">
              {props.current}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wave;
