import React, { useState }  from "react";
import Login from "./Login";


const Button = (props) => {
    const [showModal, setShowModal] = useState(false)
   const handleOnClose = () => setShowModal(false)
    return (
         <div>
            <div>
               <p onClick={()=> setShowModal(true)} className={props.class?props.class:"text-white font-[Poppins] cursor-pointer "}>
                {props.children}     
               </p>
            </div>
           <div>
            <Login onClose={handleOnClose} visible={showModal} />
           </div>
         </div>
    )
}
export default Button