import sky from "../asset/images/sky.jpg";
import highmeter from "../asset/images/highmeter.png";
import knot1 from "../asset/images/knot1.jpg";
import finalFlag from '../asset/images/finalFlag.jpg'
export default function KnotRiteReport() {
  return (
    <>
    <div className="mt-[15%] p-8 flex flex-col items-center">
      <h1 className="text-[#67365E] font-[600]  text-[30px]">Findings</h1>
      <h3 className="text-white bg-[#67365E] w-full my-3 font-500 px-3 ">
        The “Mr. /Ms Right”
      </h3>
      <h1 className="text-[#67365E] font-[600]  text-[15px]">
        You seem to be relationship ready(….well almost )
      </h1>
      <p className="text-[#67365E] text-[13px] text-justify ">
        Great News! You seem to be truly relationship-ready. In other words, you
        have ability to share a deep and meaningful bond with your significant
        other ,and you can experience close and intimate feelings with your
        partner. You are warm, friendly, available, comfortable in your skin,
        emotionally grounded, have a non-defensive and non-threatening
        personality. You believe that a healthy relationship is the foundation
        for all other worldly achievements in life.
        <img src={highmeter} alt="" />
        You are very comfortable with intimacy, you are emotionally expressive
        and affectionate and you don't mind showing vulnerability to your
        partner. As you can spend time together, you can also enjoy time alone
        without having to be glued with your companion always. You don’t mind
        giving others space, you can enjoy your own space also. You don’t need
        continuous confirmation, connection or approval for moral boosting. You
        treat your partner with respect and kindness, you do not play any
        mind-game and can have a difficult dialogue with utmost honesty, when
        needed. You are good at conflict management and quick to forgive.
        <img src={sky} alt="" />
        <br />
        Now, though you seem to be near-perfect as a partner as far as emotional
        readiness goes, you may want to evaluate a bit deeper about how ready
        you are to navigate through nuances of marriage. A marriage based on
        love and respect doesn't just happen. Both spouses have to do their
        part. Marriage and Relationships are not logical like math. They are
        about the space between people, the back-and-forth flow, feeling the
        whole being of the other. That takes time, determination, an open heart,
        and a sensitive touch.
        <img src={knot1} alt="" />
        <br />
        To take things forward we suggest you take the detailed individual
        assessment test which would give deeper insights on your personality
        from the marriage perspective and your readiness for it. If you have
        already chosen your future partner, we urge you to take the couple
        compatibility test. This compatibility test will give you in-depth
        analysis of how you as a couple are geared up to address the challenges
        and also savor the joys of togetherness.
      </p>
      <h1 className="text-[#67365E] font-[600]  text-[30px]">Conclusions</h1>
      <div className="bg-[#deb7d7ad] p-5 rounded-md ">
        <p className="text-[#67365E] text-[13px] text-justify ">
          To take things forward we suggest you take the detailed individual
          assessment test which would give deeper insights on your personality
          from the marriage perspective and your readiness for it. If you have
          already chosen your future partner, we urge you to take the couple
          compatibility
        </p>
      </div>
      <img src={finalFlag} alt="" />
      </div>
    </>
  );
}
