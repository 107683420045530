import { useState } from "react"

export default function Expander(props) {
    const [isActive,setisac]=useState(false)
    return <>
      
            <div className="w-full mt-4 border-b-2 border-gray-400 py-4 mx-2">
              <div className="flex min-w-full justify-between">
                <h1 className={!isActive?"text-[#646464] font-[700] text-[18px] ":"text-[#67365E] font-[700] text-[18px]"}>
                 {props.question}
                </h1>
               { isActive? <svg
                onClick={()=>setisac(!isActive)}
                  width="24"
                  height="24"
                  className="min-w-[20px] max-w-[20px] cursor-pointer"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_440_40)">
                    <path
                      d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM7 11H17V13H7V11Z"
                      fill="#AC4884"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_440_40">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>:
                <svg className="max-w-[20px] min-w-[20px] cursor-pointer"  onClick={()=>setisac(!isActive)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_440_33)">
<path d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20Z" fill="#363636"/>
</g>
<defs>
<clipPath id="clip0_440_33">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>}

              </div>
              <div className=" overflow-hidden">
              <p className={isActive==true?"duration-1000   block font-[400] text-[13px] text-[#5A5A5A] text-justify leading-8 mt-4 ":"duration-1000  font-[400] translate-y-[-150%] hidden text-[13px] text-[#5A5A5A] text-justify leading-8 mt-4 "}>
                {props.answer}
              </p>
              </div>
           
          </div>
    </>
}