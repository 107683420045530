import React, { useState } from "react";
import {get, post} from '../../services/apiHandler'
import { useFormik } from "formik";
import { AddPartnerSchema } from "../../schemas";
export default function AddPartner({ visible, onClose,loadData ,gender}) {

  const [error, seterror] = useState("");
  const [partner_sts,setpart]=useState("")
  const initialValues={
    name:"",
    email:"",
    gender:gender,
    mobile_number:''
  }

const{values,errors,handleChange,handleBlur,handleSubmit}=useFormik({
  initialValues:initialValues,
  validationSchema:AddPartnerSchema,
  onSubmit:(value)=>{
    SubmitForm(value)
  }

})

  if (!visible) return null;
  const handleOnClose = () => {
    onClose();
  };
  function SubmitForm(data) {
    
   

    post("api/partners", data)
      .then((res) => {
        if (res.data.success == true) {
     
      seterror(res.data.message)

      get("api/partners").then((pres) => {
        if (pres.data.success) {
          let presd=pres.data.data[0]
          
        
       
        post("api/couple_match", { user_id: presd.user_id, couple_id: presd.couple_id }).then((res) => {
          
         
          loadData()
          
        }).catch((er) => {}
     
      )}})

      handleOnClose()
        }
        else{
          if(res.data.message=="no remaining referrals buy add on plan to add partner")
          seterror("This is a premium service. You do not have enough balance. Please visit pricing page to buy a plan")
          else if( res.data.message=="partner not registered")
          seterror("This person is not registered on Knotrite. Please ask the person to signup")
         else if(res.data.message=="user and partner gender should not be same")
         seterror("Invited partner cannot be of same gender")
         else if(res.data.message=="should take paid assessment to add partner")
         seterror("Please complete KnotRiteType (Individual assessment) test before adding partner")
        else if(res.data.message=='Attempt to read property "gender" on null')
        seterror("Wrong partner details. Please check and re-enter")
      else if(res.data.message=='entered partner already exists')
      seterror("You had already assessed CoupleMatch with this partner in the past, check your email inbox for the report")
    else if(res.data.message=="user should have credits for paid test to refer new user")
    seterror(" Invited partner is a free user .To  send invite,  please buy additional plan or ask the partner to subscribe to standard or premium plan.")
         else
        seterror(res.data.message)
        
        }
        setTimeout((()=>seterror("")),100000)
      })
         
         
          // window.location.reload();
          //  onClose();
        
      
      .catch((error) => {
        
      });
  }
  return (
    
    <div className="fixed inset-0 mt-6 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
       {error != "" && (
              <div
                class="mb-4 flex max-w-[500px]  text-white justify-around absolute top-[120px] right-0 rounded-lg bg-red-600 px-6 py-5 text-base text-primary-600"
                role="alert"
              >
                {error}
                <button
                  className="float-right ml-16"
                  onClick={() => seterror("")}
                >
                  X
                </button>
              </div>
            )}
      <div className=" p-2 m-4 rounded md:w-[30%] w-[98%] max-h-full mx-auto bg-gradient-to-t from-[#641662] to-pink-200">
        <button className="float-right mr-2" onClick={handleOnClose}>
          X
        </button>
        <div className="columns flex space-x-52">
          <div className="bg-icon w-[20%] h-12 bg-no-repeat bg-contain  ml-4 mt-4"></div>
        </div>
        <div>
          <h2 className="text-white  text-[20px] font-[700] text-center justify-center">
            Add Partner
            <p className="text-black mt-2 text-[10px] font-[600] text-center justify-center">This will invite partner for assessment . Your account will be debited for required cost . In case your account does not have balance, please buy add on plans</p>
          </h2>
          <form className="max-w-full w-full flex flex-col justify-center items-center" onSubmit={handleSubmit}>
            <div className="text-white mt-1 font-[600] w-full ml-10 text-[13px]  ">
              <label>Name</label>
              <br />
              <input
                type="text"
                placeholder="Enter Your Partner's Name "
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className="mt-1  text-black flex   items-center rounded-md justify-center border px-2 py-1 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
              <p className="text-yellow-300">{errors.name}</p>
            </div>
            {/* <div  className="text-white my-1 font-[600] w-full ml-10 text-[13px]  ">
                    <label>Gender</label>
                    <br />
                    <select
                        className="mt-2 text-black flex   items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="male">male</option>
                      <option value="female">female</option>
                    </select>
                    <p className="text-yellow-300">{errors.gender}</p>
                  </div> */}
            <div className="text-white mt-1 font-[600] w-full ml-10 text-[13px]  ">
              <label>Email</label>
              <br />
              <input
                type="text"
                placeholder="Enter Your Partner's Email "
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className="mt-1 text-black flex   items-center rounded-md justify-center border px-2 py-1 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
                <p className="text-yellow-300">{errors.email}</p>
            </div>
            <div className="text-white mt-1 font-[600] w-full text-[13px] ml-10  ">
              <label>Phone</label>
              <br />
              <input
                type="number"
                placeholder="Enter Your Partner's Mobile Number"
                name="mobile_number"
                value={values.mobile_number}
                onChange={(e)=>{
                 
                  handleChange(e)}}
                onBlur={handleBlur}
                className="mt-1  text-black flex   items-center rounded-md justify-center border px-2 py-1 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
                <p className="text-yellow-300">{errors.mobile_number}</p>
            </div>
            {/* <p className="text-[18px] text-yellow-300">{partner_sts}</p> */}
            <div className="mb-3  mt-4 w-full ml-10">
              <button className="rounded-full  bg-[#CD64A3] w-[85%] font-[700] text-[12px] px-5 py-2    text-white transition duration-200  hover:bg-[#9c4c7c]">
                Submit
              </button>
            </div>
          </form>
         
        </div>
      </div>
    </div>
  );
}
