import { useEffect, useState } from "react";
import Navbar from "../components/header/Navbar";
import Wave from "../components/header/Wave";
import Footer from "../components/Footer";
import Expander from "../components/faq/expander";
import img from "../asset/images/faq01.png";
import TestHeading from "../components/test/testHeading";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "../services/apiHandler";
export default function Faq() {
  const Nav = useNavigate();
  const {state}=useLocation()
  console.log(state);
  const[data,setData]=useState([])
  let btn = [
    "KNOTRITE",
    "SERVICES",
    "SECURITY & PRIVACY",
    "PROCESSES",
    "PAYMENTS AND PRICINGS",
  ];
  const [act, setact] = useState(state&&state.id?state.id:0);
  function loadData() {
    
   

    get("api/get_faq")
      .then((res) => {
      
      if(res.data.success){
        const result=res.data.data
        result.sort((a,b)=>new Date(b.updated_at)-new Date(a.updated_at))
        setData(result)
      }
          
         
          // window.location.reload();
          //  onClose();
        
      })
      .catch((error) => {
        
      });
  }
  useEffect(() => {
    
  
  loadData()
  }, [])
  return (
    <>
      <section>
      
        <Wave title="Frequently Asked Questions" subTitle="" current="FaQ" />
        <div className="flex flex-col justify-around w-[90%] mx-auto my-5 md:flex-nowrap flex-wrap">
          {/* <TestHeading heading="Frequently Asked Questions" /> */}

          <p className="feaure_sub-heading font-[500] text-[15px]  text-[#641662] leading-7">
            Here are a few of the questions we get the most. Click on the
            relevant tab below to find your question. If you don't see what's on
            your mind, reach out to us anytime through the <span className="cursor-pointer font-[700] text-[#c5bf1b]" onClick={()=>Nav("/contact-us")}>contact link.</span>
          </p>
        </div>
        <div className="flex justify-center w-full flex-col items-center">
          <div className="md:flex justify-around w-[90%] rounded-lg mt-[1%]">
            {btn.map((data, i) => {
              return (
                <button
                  className={
                    act == i
                      ? "text-white font-[700] text-[15px] bg-[#67365E] px-2 uppercase py-4 w-full"
                      : "text-black font-[700] text-[15px] bg-[#f0daf4d0] uppercase px-2 py-4 w-full duration-75"
                  }
                  onClick={() => {
                    setact(i);
                  }}
                >
                  {data}
                </button>
              );
            })}
          </div>
          <div className="flex w-full md:w-[90%] my-8 flex-wrap md:flex-nowrap">
            <div  className=" w-full mx-8 md-m-0 md:w-[50%] h-[100%] md:min-h-[400px]">
              
                <>
                {
                  data.map((data)=>{
                    if(btn[act]==data.type)
                   { return  <Expander
                    question= {<p className="w-[100%] "  dangerouslySetInnerHTML={{ __html: data.question, }}/>}
                    answer={<p className="w-[100%] "  dangerouslySetInnerHTML={{ __html: data.answer, }}/>}
                  />}
                  })
                }
                 
                 
                </>
            
              
             
              <button
                className="bg-[#480740] text-white  text-[15px] font-[Poppins] flex justify-around items-center py-2 px-5 rounded-3xl mt-8  hover:bg-pink-800 duration-500 cursor-pointer"
                type="button"
                onClick={() => {
                  Nav("/contact-us");
                }}
              >
                Contact Us
              </button>
            </div>
            <div className="md:w-[50%] flex justify-center items-start">
              <img src={img} width="80%" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
