export default function OfferCard(props) {
    return<>
    <div className="w-[230px] bg-[#CD64A3] rounded-md flex flex-col mt-[15%] md:mt-0 justify-center items-center p-5 translate-y-[50%]">
        <div className="rounded-full h-[100px] w-[100px] bg-[#ffff] p-5 border-[#AC4884] border-2 translate-y-[-60%]">
            <img src={props.img} alt="" width={"100%"} /> 

        </div>
        <p className="font-[700]  text-[15px] text-white">{props.text}</p>

    </div>
    </>
}