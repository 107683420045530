import React, { useState } from "react";
import "../components/Style.css";

import Wave from "../components/header/Wave";
import sh1 from "../asset/images/sh1.png";
import sh2 from "../asset/images/sh2.png";

import OfferCard from "../components/aboutUs/offerCard";
import ser1 from "../asset/images/about01.png";
import ser2 from "../asset/images/about02.png";
import ser3 from "../asset/images/about03.png";
import ser4 from "../asset/images/about04.png";

import banner from "../asset/images/about-bann.png";
import TestHeading from "../components/test/testHeading";
import aboutPicture from "../asset/images/about-picture.png"
import Login from "../components/modal/Login";
export default function AboutUs() {
  const [showLogin, setShowLogin] = useState(false);
  

  const handleClose = () => setShowLogin(false);
  return (
    <React.Fragment>
      <section>

        <Wave title="About us" subTitle="" current="aboutus" />
        <div className="md:mx-32 mx-4">
          {/* <TestHeading heading="Who We Are" /> */}
          <h1 className=" text-[18px] font-[600]">Indian Marriages increasingly affected by changing socio-economic landscape</h1><br/>
          <div className="text-center ">

            <p className="feaure_sub-heading font-[400] text-[13px] text-justify text-[#363636] leading-7">
              {" "}
              <img src={aboutPicture} className="md:w-[300px] float-left md:m-5" alt="" />
              <br />
            
              The Indian society is undergoing a sea-change as far as beliefs, values, education, and economy are concerned. The economic aspects being the primary amongst them. Growing family income with dual earning members encourage their aspirations to have a higher quality of life. This has also engendered independent thinking influencing values and beliefs including the ways to conduct relationships and marital life. The involvement of family including parents and relatives are gradually waning with the upsurge of more nuclear families. Geographical mobility for education and livelihood has also contributed to smaller families. These changes have profoundly impacted the dynamics of a family, including spouses and kids. The dominance of a traditional head of the family is no longer prevalent. Also, the instances of the ever-suffering wife sacrificing all her personal aspirations and joys for the greater good of a family has taken a beating. Now, with proper education and economic independence, she duly demands an equal footing in a family. Also, the advent of technology, intrusion of the internet in daily life, easy access to information and rising of social media have redefined the concept of happiness and bliss.
            </p>
          </div>
         
          <h1 className="md:mt-14 mt-5 font-[600] w-full"> But Indian Matchmaking has not kept up with the change</h1><br/>
          <div className="text-center ">

            <p className="feaure_sub-heading font-[400] text-[13px] text-justify text-[#363636] leading-7">
              {" "}
              However, the process of finding the right match has not changed much. Many studies indicate that some changes occurred in this respect, mostly in the metro cities. Few people prefer to select their own match, spend time with them to know them well before marriage but for most people the process of selecting and finalizing their prospective bride and groom pretty much remain the same. Parents and family members may have relaxed the grip about meeting, choosing, and finalizing the match a bit, but their involvement remained the same about other factors, i.e. – the ethnicity, religion, community and caste of the prospective bride and groom and most importantly, matching horoscopes and kundli. In most families in India unless the kundli is matched, the marriage is not finalized, even when the couple know each other beforehand. The online dating and matrimony portals have enabled wider reach but the selection criteria remain unchanged.<br/><br/>
              Matching horoscopes and kundli may be one way of ensuring the success of marriage but unfortunately it doesn’t take care of various aspects like marriage readiness, emotional resilience, individual traits as a romantic partner and finally couple compatibility. This had skyrocketed unhappy marriages not aligned with new socio-economic realties. The factors considered for compatibility seemed inadequate in today’s date. Though India still has lower divorce rates than many other Western countries but marital conflicts are rising. Separations and rapid increase of divorce cases filed in family courts are glaring examples of these mismatched marriages.
            </p>
          </div>
          <h1 className="mt-5 font-[600]">  Need for a new matrimonial match process adapted to Indian milieu.</h1><br/>
          <div className="text-center ">

            <p className="feaure_sub-heading font-[400] text-[13px] text-justify text-[#363636] leading-7">
              {" "}
              We at Knotrite recognized the gap that could not be filled with the current solutions at hand. The family friend, extended relatives, and marriage broker network is proving to be inadequate. The online matrimonial sites while projecting a seemingly modern tech-savvy way to select prospective matches also use the orthodox and traditional checklists - community, religion, caste, education, financial position, horoscopes, kundli etc. The marriage aspirants are left to sift through numerous profiles and decide for oneself. The problem is compounded with growing incidences of unreliable, fake or at times deliberate misleading profiles.<br/><br/>
              We realized what required is a scientific way to assess marriage readiness, emotional resilience, individual personality, and behavioral traits, along with couple compatibility of potential partners to ensure greater success to find the right match. We at KnotRite have developed a framework called <strong>KnotRiteMetric®</strong>. These assessment reports would provide a fair prediction of whether someone is ready for marriage, how emotionally stable he/ she is, how would they come across as a romantic partner, how they would prefer to relate to each other and address the potential challenges while managing conflict and how they would behave in a marriage. We at KnotRite believe that these will ensure a much stronger and deeper bond creating through conscious awareness
              Our methodology is based on sound, research-backed, ground breaking theory of “Collective Unconscious” and “Psychological Types” published by the famous Swiss Psychologist and Psychiatrist Carl Jung in early 1900, “Attachment Style theory” by psychiatrist John Bowlby and psychologist Mary Ainsworth, and “Big 5 Personality” theory by Lewis Goldberg, the American psychologist in 1960s.
            </p>
            <h1 className="text-[15px] text-justify mt-2 font-[600]">While personality assessments have long been prevalent in organizations for employee hiring and development, we have deployed these proven principles for the first time in the matrimonial space.

            </h1><br/>
            <p className="text-justify text-[15px] mt-2 font-[600] md:text-left" >KnotRiteMetric® is a set of the following tools:</p>
          </div>


        </div>
        {/* <div className="bg-[#FFD0EC] min-h-40 md:mx-32 my-[4%]"> */}
          {/* <p className="text-center  after:font-[500] text-[30px] text-[#67365E] pt-[2%]"> */}
            {/* We Offer
          </p> */}
          <div className="flex flex-wrap justify-around md:mx-32 mx-4">
            <OfferCard img={ser1} text="Free assessments" />
            <OfferCard img={ser2} text="Premium services for detailed assessments" />
            <OfferCard img={ser3} text="Relationship
Resources" />
            <OfferCard img={ser4} text="Counselling" />
          {/* </div> */}
        </div>
        <div className="md:mx-32 mx-4">
        <h1 className=" font-[600] md:mt-[10%] mt-[35%] ">  The Road Ahead..</h1><br/>
        <div className="text-center ">

          <p className="feaure_sub-heading font-[400] text-[13px] text-justify text-[#363636] leading-7">
            {" "}
            We are strongly committed to the idea of marriage as a union of destinies. We believe these set of tools will enable prospective partners to make informed, and conscious choices for the most important decision in their lives. As we move ahead ,we will be continuously working to upgrade our solutions, add new assessment and coaching tools, increase reach through regional language versions and much more. We recognize that this approach has significant potential to have a transformative effect not only on individuals and families but also to communities and society in general. 
          </p>
         
        </div>
        </div>
        {/* <center className="my-4"> */}
          <div className="md:mx-32 mx-4 my-8 mb-10"><h1 className=" font-[600] ">Our Leadership Team</h1></div>
        <div className="flex  md:mx-32 mx-4 md:justify-between justify-center flex-wrap my-10 md:w-[40%]">
        
        {/* <Content
          img={man}
          className="mt-[14%]"
          heading="Sirsendu Roy"
          subHeading="Founder & CEO"
          text={<><p>Ex Corporate professional with over three decades in various leadership positions in Engineering MNCs. He leverages his education (Engineer and MBA from IIM-C) and corporate experience to provide management and strategy inputs to the organization.</p><br/>
         <h1 className="font-[600]"> Success Mantra: </h1><br/>
          “The secret of the success depends on who you marry”- <br/> Warren Buffet</>
  }         
        />
       

        <Content
          img={woman}
          heading="Kathakali Chatterjee"
          subHeading="Managing Partner"
          text={<><p> L&D Strategist with decades of experience in the training industry involving stints at IIT-KGP and ISB-Hyderabad. With her experience and education (MS, Univ Of Wisconsin), she brings tremendous expertise in Psychological profiling, assessments and counselling.
            </p><br/>
          <h1 className="font-[600]"> Success Mantra:  </h1><br/>
          “If we had to vanish tomorrow, what would we want as our legacy?” – <br/>Randy Pausch</>
   }       
          flow="flex-row-reverse"
        /> */}
        <img src={sh1} width={250} className="md:mb-0 mb-5" alt="" />
        <img src={sh2} width={250} alt="" />
        </div>
        {/* </center> */}
        {/* <div className="md:flex justify-between md:mx-16 my-20">
          <div className="p-5 pr-[9%] ">
            <h1 className=" font-[700] text-[15px] flex items-center my-[2%] ">
              <img src={obj} width={40} alt="objective" />
              &nbsp; Our Objective
            </h1><br/>
            <p className="font-[400] text-[13px] leading-8 mt-[2%] text-justify">
              Our scientific approach towards relationship readiness evaluation,
              individual assessment and couple-compatibility tests represent a
              paradigm shift on how people choose their matrimonial partners
              moving beyond traditional process (astrology, family matchmaking,
              marriage agencies) and even modern methods (matrimonial sites,
              dating apps ..etc), and also help the existing couples to mend
              their differences by understanding each other better.
            </p>
          </div>
          <div className="p-5 ">
            <h1 className=" font-[700] text-[15px] flex items-center my-[2%]">
              <img src={metho} width={40} alt="objective" /> &nbsp;Our Methodology
            </h1><br/>
            <p className="font-[400] text-[13px] leading-8 mt-[2%] text-justify">
              {" "}
              Our scientific approach towards relationship readiness evaluation,
              individual assessment and couple-compatibility tests represent a
              paradigm shift on how people choose their matrimonial partners
              moving beyond traditional process (astrology, family matchmaking,
              marriage agencies) and even modern methods (matrimonial sites,
              dating apps ..etc), and also help the existing couples to mend
              their differences by understanding each other better.
            </p>
          </div>
        </div> */}
        <div className="relative md:mx-32 mx-4 mb-10 ">
          <img src={banner} className="md:h-[400px] h-[240px]  w-full rounded-lg" />
          <p className="absolute top-[3%] px-2 md:top-[3%] md:p-10 md:w-[50%] text-justify text-white text-[15px] font-[300] md:leading-10">
            <h1 className=" font-[400] text-[25px] mb-2 md:mb-6 ">Connect With Us </h1>
            Our aim is to create a safe, fun, and informative place to learn
            about oneself as well as one’s prospective or existing partners. If
            you have questions, comments, suggestions, please don't hesitate to
            contact us.
            <button
              className="bg-[#480740]  text-white font-[Poppins] flex justify-around items-center py-2 px-5 rounded-3xl mt-2 md:mt-6  hover:bg-pink-800 duration-500 cursor-pointer"
              type="button"
              onClick={()=>setShowLogin(true)}
            >
              GET STARTED
            </button>
          </p>
        </div>
      </section>
      <Login onClose={handleClose} visible={showLogin} />
    </React.Fragment>
  );
}
