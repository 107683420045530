import { useEffect, useState } from "react";
import Modal from "../components/modal/Modal";
import ForgotPassword from "../components/modal/forgotPassword";
import Login from "../components/modal/Login";
import { useNavigate } from "react-router-dom";
import { get } from "../services/apiHandler";

export default function LoginPage() {
    const [showLogin, setShowLogin] = useState(true);
    const [img, setImg] = useState({ desktop: '', mobile: '' });
    const [showSignUp, setShowSignUp] = useState(false);
    const [fv, setFv] = useState(false);

    const handleClose = () => setShowLogin(false);
    const handleSignUpClose = () => setShowSignUp(false);
    const navigate = useNavigate();
    
    const handleLogin = () => {
        handleSignUpClose();
        setShowLogin(true);
        setFv(false);
    };
    
    const handleSignUp = () => {
        navigate("/signup");
    };
    
    const handleForgotPasswordClose = () => {
        setFv(!fv);
        handleClose();
    };

    const loadData = async () => {
        try {
            const data = await get(`api/AuthBanner/`);
            const image = {};
            data.data.data.forEach((ele) => {
                if (ele.name === 'imageDesktopLogin') image.desktop = ele.image_path;
                if (ele.name === 'imageMobileLogin') image.mobile = ele.image_path;
            });
            setImg({
                mobile: "https://knotrite.in/backend/storage/" + image.mobile,
                desktop: "https://knotrite.in/backend/storage/" + image.desktop
            });
        } catch (error) {
            console.error("Error loading data", error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div
            className="h-[100vh] flex justify-center items-center bg-cover"
            style={{
                backgroundImage: `url(${img.desktop})`,
                backgroundSize: 'cover',
            }}
        >
            <style>
                {`
                    @media (max-width: 768px) {
                        .background-image {
                            background-image: url(${img.mobile}) !important;
                        }
                    }
                `}
            </style>
            <div className="background-image h-[100vh] flex justify-center items-center bg-cover  mt-32 w-full h-full">
                <Login onClose={handleClose} onforgot={handleForgotPasswordClose} onSignUp={handleSignUp} visible={true} />
                <Modal onClose={handleSignUpClose} onLogin={handleLogin} visible={showSignUp} />
                <ForgotPassword onClose={handleForgotPasswordClose} onLogin={handleLogin} visible={fv} />
            </div>
        </div>
    );
}
