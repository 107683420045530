import React, { useEffect, useState } from "react";
import Subbutton from "./Subbutton";
import ResetPassword from "./resetPassword";
import { post } from "../../services/apiHandler";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

export default function VerifyOtp({ visible, onClose, mobile_number }) {
  const [fv, setfv] = useState(false);
  const navigate=useNavigate()
  const [time,setTime]=useState(0)
  let inputRefs=[]
  const handleOnClose = () => {
    onClose();
  };
  const handleClose = () => {
    setfv(false);
  };
  const countdown=function cc(){
    let act=60
    setInterval(() => {
      
      if(act==-1){  clearInterval(cc)
      clearInterval(countdown)
      }
  else
  setTime(act--)
    }, 1000); 
  
  }
  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });

  const resendOtp = (e) => {
    e.preventDefault();
    post("api/forgot_password_request", {mobile_number:mobile_number})
    .then((res) => {
      if (res.data.success == true) {
        toast.success('OTP sent Successfully', {
          position: toast.POSITION.TOP_RIGHT
        });
        setTime(60);
        countdown();
      } else {
        toast.error(res.data.data[Object.keys(res.data.data)][0], {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    })
    .catch((error) => {
      
    });
}

useEffect(()=>{
  setTime(60);
  countdown();
},[])
const VerifyOtp = (e) => {
  e.preventDefault(); 
    post("api/verify_forgot_password_otp", {mobile_number, otp:otp.otp1+otp.otp2+otp.otp3+otp.otp4+otp.otp5+otp.otp6})
    .then((res) => {
      if (res.data.success == true) {
        toast.success('OTP Verified Successfully', {
          position: toast.POSITION.TOP_RIGHT
        });
        setOtp({
          otp1: "",
          otp2: "",
          otp3: "",
          otp4: "",
          otp5: "",
          otp6: "",
        })
        setfv(true);
        handleOnClose();
        // navigate("/myaccount")
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        setOtp({
          otp1: "",
          otp2: "",
          otp3: "",
          otp4: "",
          otp5: "",
          otp6: "",
        })
      }
    })
    .catch((error) => {
      
    });
}

const handleInputChange = (e,index) => {
    setOtp({ ...otp, [e.target.name]: e.target.value });
    if (e.target.value !== '' && index < 6 - 1) {
      
      inputRefs[index + 1].focus();
    }
  };

  return (
    <>

    <div className={visible?"fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center ":" fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center hidden"}>
      <div className=" p-2 m-4 rounded md:w-[30%] w-[98%] max-h-full mx-auto bg-gradient-to-t from-[#641662] to-pink-200">
        <button className="float-right mr-2" onClick={()=>handleOnClose()}>
          X
        </button>
        <div className="columns flex space-x-52">
          <div className="bg-icon w-[22%] h-[45px] bg-contain  bg-no-repeat  ml-4 mt-4"></div>
        </div>
        <div>
          <h2 className="text-white mt-6 text-3xl font-[700] text-center justify-center">
            Verify Your Mobile Number
          </h2>
          <p className="text-white  text-[13px] font-[400] text-center justify-around">
            Please enter 6 digit code sent to {mobile_number}
          </p>
          <form className="flex flex-col items-center">
            <div className="flex justify-around mx-4">
          {Array.from({ length: 6 }, (_, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            name={`otp${index+1}`}
           value={otp[`otp${index+1}`]}
            className="[appearance:textfield] p-4  [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none mt-2 flex h-10  items-center rounded-md justify-center border w-[15%]  text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2] dark:!border-black-400 dark:!text-black-400 dark:placeholder:!text-black-400 bg-slate-50"
            ref={(input) => (inputRefs[index] = input)}
              
            onChange={(e)=>handleInputChange(e,index)}
          />
        
        ))}
          </div>
            <div className="mb-3 mt-7  text-center w-full">
              <button type="button" onClick={(e)=>VerifyOtp(e)} className="rounded-full  bg-[#CD64A3] w-[85%] font-[700] text-[13px] px-5 py-2 text-base   text-white transition duration-200  hover:bg-[#9c4c7c]">
                Verify
              </button>
            </div>

            <div className="md:flex flex sm:flex lg:flex w-full justify-center text-center mb-5">
            {time<=0?  <p onClick={(e)=>{
                 if(time==0)
                resendOtp(e)}} className="cursor-pointer text-center justify-center font-[13px]  mt-2 pl-5 text-[13px]s w-full  text-white">
                <span
                  className="font-[700] text-[#FFBAE3] ml-1 cursor-pointer"
                >
                  Resend Code

                </span>
             
              </p> : <p className="text-white font-[600] text-center text-[12px]"> Not received OTP? You can press resend button after  {time}sec</p>}
              <b className=""></b>
            </div>
          </form>
        </div>
      </div>
    </div>
   <ResetPassword onClose={handleClose} mobile_number={mobile_number} visible={fv} /></>
  );
}
