import React, { useEffect } from "react";
import './Style.css';
import img from '../asset/images/knotrite_logo_white.png'
import {FaFacebook, FaTwitter, FaLinkedin, FaInstagram} from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
const Footer = () => {
   useEffect(()=>{
      var body=document.getElementsByTagName("body")[0];
      var parent=document.getElementsByClassName("graphics");
     
      body.onscroll = function(){
      
      for(let i=0;i<parent.length;i++){
      if(document.documentElement.scrollTop>=parent[i].offsetTop-700)//Adjust Tolerance as you want
      {
       
         parent[i].style.opacity=1
      }
      else {
      
        parent[i].style.opacity=0;}
      };}
   },[])
   const Nav=useNavigate()
    return(
        <React.Fragment>
        <div className="w-full justify-center text-center py-3 sm:py-2 sm:px-10 relative z-[50]   md:shadow-xl bg-[#67365e]">
               {/* <div className="md:w-fit md:text-center md:justify-center md:mx-80 sm:mx-4 sm:mt-12 xl:text-center 
               xl:justify-center mx-80  xl:mx-30  xl:px-30 xl:ml-96 xl:pl-36 2xl:py-3 2xl:my-0 2xl:mx-96 ">
                 <div className="md:bg-icon md:w-44 md:h-20 md:bg-no-repeat md:ml-80 sm:w-24 sm:h-16 lg:ml-28
                 sm:bg-icon bg-icon mt-3 xl:bg-icon  "></div>
                 </div> */}
                 <div className="flex justify-center sm:translate-y-10 ">
                  <div>
                 

                 <a href="/"><img src={img} alt="" className=" h-fit w-32 sm:w-32  " /></a>
                  <p className="text-white text-[13px] font-[500] mb-5">Uniting Destinies</p>
                  </div>
                  </div>

             <div className="flex md:mt-6 md:items-center xl:text-center  xl:space-x-10 text-center itmeas-around justify-center space-y-1 text-sm 2xl:my-2">
             
                <ul className="flex flex-wrap md:flex-nowrap md:w-full md:space-x-16 md:ml-13 md:mx-20 text-[13px]s xl:text-lg justify-center xl:justify-arround sm:h-fit sm:space-y-6 
                lg:space-x-5 lg:w-fit xl:space-x-10 2xl:text-base 2xl:pb-3 2xl:space-x-10 2xl:my-1 text-[#f6dff5] ">
                    {/* <div className="bg-icon md:w-44 md:h-20 md:bg-no-repeat md:ml-80 sm:w-24 sm:h-16 lg:ml-28
                 mt-3 xl:bg-icon  "></div> */}
                
                    <li className=" mr-5 my-2  text-[13px] md:mt-6 cursor-pointer hover:text-white" onClick={()=>Nav("/")}>Home</li>
                   
                    <li className=" mr-5 my-2 text-[13px] cursor-pointer hover:text-white" onClick={()=>Nav("/about-us")}>About us</li>
                    <li className=" mr-5 my-2 text-[13px] cursor-pointer hover:text-white"onClick={()=>Nav("/contact-us")}>Contact us</li>
                    <li className=" mr-5 my-2  text-[13px] cursor-pointer hover:text-white"onClick={()=>Nav("/faq")}>FAQ's</li>
                    <li className=" mr-5 my-2 text-[13px] cursor-pointer hover:text-white" onClick={()=>Nav("/t&c")}>Terms of use</li>
                </ul>
             </div>
             <div className="flex mt-3 text-center justify-center space-x-3
              md:flex md:mt-2 md:text-center md:justify-center bg-[#67365e] 
              md:space-x-14 sm:flex sm:text-center sm:justify-center sm:mt-3 "> 
              <a href="https://www.facebook.com/KnotRite" target="_blank">  <FaFacebook  className="fill-[white] text-[20px] hover:fill-[yellow] cursor-pointer"/></a>
               {/* a <FaTwitter className="fill-[white] hover:fill-[yellow] cursor-pointer" /> */}
                {/* <FaLinkedin className="fill-[white] hover:fill-[yellow] cursor-pointer" /> */}
              <a href="https://instagram.com/knotritepsychometrics?igshid=OGQ5ZDc2ODk2ZA==" target="_blank"> <FaInstagram className="fill-[white] text-[20px] hover:fill-[yellow] cursor-pointer" /></a> 
              <a href="https://twitter.com/KnotriteMatches" target="_blank">  <FaTwitter  className="fill-[white] text-[20px] hover:fill-[yellow] cursor-pointer"/></a>
              <a href="https://www.linkedin.com/company/knotritepsychometric/" target="_blank">  <FaLinkedin  className="fill-[white] text-[20px] hover:fill-[yellow] cursor-pointer"/></a>
             </div>
             <div className="">
           
             <div className="mt-8 text-[13px] text-center bg-[#67365e] w-full justify-center text-white">
             All rights reserved © 2023 Knotrite Pyschometrics LLP
             </div>
             <div className="md:mt-8 mt-2 text-[11px] md:text-left text-center md:w-[unset] w-full bg-[#67365e] justify-center text-white">
            Developed By <a href="https://techmindscape.in/" target="_blank">Techmindscape Pvt Ltd</a>
             </div>
             </div>
        </div>
        </React.Fragment>
    )
}
export default Footer