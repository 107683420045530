
import React, { useState } from "react";
import { post } from "../../services/apiHandler";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function ResetPassword({ visible, onClose, mobile_number }) {

  const [showNewPassword,setShowNewPassword] = useState(false);
  const [showConfirmPassword,setShowConfirmPassword] = useState(false);
  let [data,setData] = useState({
    mobile_number: mobile_number,
    password: "",
    password_confirmation: "",
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  if (!visible) return null;
  const handleOnClose = () => {
    onClose();
  };

  const changePassword = () => {
    data.mobile_number=mobile_number
    setData({ ...data, mobile_number:mobile_number});
    post("api/reset_password", data)
    .then((res) => {
      if (res.data.success == true) {
        toast.success('Password reset successfully. Please login with new password', {
          position: toast.POSITION.TOP_RIGHT
        });
        onClose();
        localStorage.removeItem("login");
        navigate('/')
      } else {
        toast.error(res.data.data[Object.keys(res.data.data)][0], {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    })
    .catch((error) => {
      
    });
}

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className=" p-2 m-4 rounded md:w-[30%] w-[98%] max-h-full mx-auto bg-gradient-to-t from-[#641662] to-pink-200">
        <button className="float-right mr-2" onClick={handleOnClose}>
          X
        </button>
        <div className="columns flex space-x-52">
          <div className="bg-icon w-[25%] h-12 bg-no-repeat bg-contain  ml-4 mt-4"></div>
        </div>
        <div>
        <h2 className="text-white mt-6 text-3xl font-[700] text-center justify-center">
            Reset Password
            </h2>
          <div
            className="max-w-full p-5"
          >
            
            <div className="mb-3 mt-7">
                    <label className="text-[15px] text-navy-700  font-[700] text-white">NEW PASSWORD</label>

                    <div className="relative">
                    <input type={showNewPassword ? "text" : "password"} name="password" onChange={(e)=>handleInputChange(e)} placeholder="New Password" className="relative mt-2 flex h-12  items-center justify-center rounded-sm border bg-white/0 p-3 w-[100%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2] dark:!border-black-400 dark:!text-black-400 dark:placeholder:!text-black-400 bg-slate-50"/>
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                      <span
                        onClick={()=>setShowNewPassword(!showNewPassword)}
                        className="absolute right-0 pr-8 cursor-pointer"
                      >
                        {showNewPassword ? (
                          <AiFillEye className="cursor-pointer text-lg text-white" />
                          ) : (
                            <AiFillEyeInvisible className="cursor-pointer text-lg text-white" />
                            )}
                      </span>
                      </div>
                      </div>
                </div>

                <div className="mb-3 mt-7">
                    <label className="text-[15px] text-navy-700  font-[700] text-white">CONFIRM PASSWORD</label>

                    <div className="relative">
                    <input type={showConfirmPassword ? "text" : "password"} name="password_confirmation" onChange={(e)=>handleInputChange(e)} placeholder="Confirm Password" className="relative mt-2 flex h-12  items-center justify-center rounded-sm border bg-white/0 p-3 w-[100%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2] dark:!border-black-400 dark:!text-black-400 dark:placeholder:!text-black-400 bg-slate-50"/>
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                      <span
                        onClick={()=>setShowConfirmPassword(!showConfirmPassword)}
                        className="absolute right-0 pr-8 cursor-pointer"
                      >
                        {showConfirmPassword ? (
                          <AiFillEye className="cursor-pointer text-lg text-white" />
                          ) : (
                            <AiFillEyeInvisible className="cursor-pointer text-lg text-white" />
                            )}
                      </span>
                      </div>
                      </div>
                </div>

            <div className="mb-3 mt-7 w-full text-center">
            <button onClick={()=>changePassword()} className="rounded-full  bg-[#CD64A3] w-[85%] font-[700] text-[12px] px-5 py-2    text-white transition duration-200  hover:bg-[#9c4c7c]">
              SAVE
                </button> 
                </div>

           
            <div className="md:flex flex sm:flex lg:flex w-full text-center mb-5">
              <p className="text-center justify-center font-[13px]  mt-2 pl-5 text-[13px]s w-full text-white">
               Back To <span className="font-[700] text-[#CD64A3] ml-1 cursor">Login</span>
                
              </p>
              <b className="">
                
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
