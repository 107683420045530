import React, { useEffect, useState } from "react";
import { get, post } from '../../services/apiHandler'

export default function PenndingInvitations({ visible, prdata, onClose, loadData, is_canAccept }) {
  // const [data, setData] = useState({gender:"male"});
  const [error, seterror] = useState("");

  const [prt_data, setprt_data] = useState([])

  const get_invitation = async () => {
    const data = await get("api/get_invitations")

    setprt_data(data.data?.data?.pending_invitations);
    if (data.data?.data?.pending_invitations.length > 0)
      prdata(true)
    else prdata(false)

  }
  useEffect(() => {
    get_invitation()
  }, [])
  const acceptInvitation = async (id) => {
    if (is_canAccept) {
      const data = await post("api/accept_invitation", { id })
      window.location.reload()
    }
    else {
      seterror("You are allowed upto 3 partners. Please delete one existing partner before accepting this")
    }

  }


  if (!visible) return null;
  const handleOnClose = () => {
    onClose();
  };

  return (

    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      {error != "" && (
        <div
          class="mb-4 flex  text-white justify-around absolute top-[120px] right-0 rounded-lg bg-red-600 px-6 py-5 text-base text-primary-600"
          role="alert"
        >
          {error}
          <button
            className="float-right ml-16"
            onClick={() => seterror("")}
          >
            X
          </button>
        </div>
      )}
      <div className=" p-2 m-4 rounded md:w-[35%] w-[78%] max-h-full mx-auto bg-gradient-to-t from-[#641662] to-pink-200">
        <button className="float-right mr-2" onClick={handleOnClose}>
          X
        </button>
        <div className="columns flex space-x-52">
          <div className="bg-icon w-[25%] h-12 bg-no-repeat bg-contain  ml-4 mt-4"></div>
        </div>
        <div>
          <h2 className="text-white  text-[20px] font-[700] text-center justify-center">
            Pending Invitations

          </h2>
          <div className="block py-3 my-5 mx-5 md:justify-between justify-center items-center  flex-wrap px-10 border-[#8a4971]  border-solid border-[2px] rounded-xl  ">

            {prt_data.map((data) => {
              return <div className="w-full justify-between items-center md:flex block"> <div className="font-[700] md:text-left text-center pb-2 md:w-[50%] w-[100%]  text-[white]  text-[13px]">

                {data.users_invited_partners[0].name} <br />
              </div>


                <div className="md:w-[45%] w-[100%]  ">
                  <div className="flex md:justify-end justify-center items-center pb-2  w-[100%] font-[700]  text-[#AC4884] text-[15px]">
                    <button

                      className=" full shadow-lg bg-[#FFFFFF]  font-[600] w-full text-[#67365E]  text-[13px]  flex justify-around items-center py-[5px] px-2 rounded-3xl  hover:text-pink-800 duration-500 cursor-pointer"
                      onClick={() => { acceptInvitation(data.id) }}
                    >
                      Accept Invitation
                    </button>


                  </div>
                </div></div>
            })}

          </div>
        </div>
      </div>
    </div>
  );
}
