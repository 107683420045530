import { useEffect, useState } from "react";
import Modal from "../components/modal/Modal";
import ForgotPassword from "../components/modal/forgotPassword";
import Login from "../components/modal/Login";
import des_signupp from '../asset/images/signUpDesktop.jpeg'
import mob_signupp from '../asset/images/signUpMobile.jpeg'
import { get } from "../services/apiHandler";
export default function SingnUp() {
    const [showLogin, setShowLogin] = useState(true);
    const [showSignUp, setshowSignUp] = useState(false);
    const [img,setImg]=useState({desktop:''})
  const [fv,setfv]=useState(false)
    const handleClose = () => setShowLogin(false);
    const handleSingUpClose = () => setshowSignUp(false);

    const loadData = async () => {
      try {
        const data = await get(`api/AuthBanner/`);
        const image={}
        data.data.data.map((ele)=>{
          if(ele.name=='imageDesktopSignup')
              image.desktop=ele.image_path
              if(ele.name=='imageMobileSignUp')
                  image.mobile=ele.image_path
        })
      
        setImg(
         { mobile:"https://knotrite.in/backend/storage/" +
            image.mobile,
            desktop:"https://knotrite.in/backend/storage/" +
            image.desktop}
        );
      
       
      } catch {
       
      }
    };
   
    const handleLogin = () => {
      
      handleSingUpClose()
      setShowLogin(true);
      setfv(false)
    }
  
   const handleForgotPasswordClose=()=>{
  setfv(!fv)
  handleClose()
    }
    useEffect(()=>{
      loadData()
    },[])
    return <div className=" shadow-lg flex justify-center  md:justify-end  md:gap-x-2 items-start md:flex-nowrap flex-wrap bg-white">
        <img src={img.desktop} className="min-w-[100%]   md:block hidden shadow-lg rounded-md mt-10 min-h-full" alt="" />
        <img src={img.mobile} className="w-full   block md:hidden  shadow-lg md:rounded-l-md  mt-14 min-h-full" alt="" />
        <div className="md:absolute right-14 md:-bottom-3 xl:bottom-0 md:w-fit w-full">
          <Modal onClose={handleSingUpClose} onLogin={handleLogin} visible={true} />
          </div>
          <ForgotPassword onClose={handleForgotPasswordClose} onLogin={handleLogin} visible={fv} />

    </div>
}