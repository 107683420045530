import React from "react";
import PaidForm from "../form/paidForm";
import "./../Style.css";
import TestHeading from "../test/testHeading";

const PaidInstruct = () => {
  return (
    <>
      <div className=" h-fit bg-white  text-black bg-fixed w-full ">
        <div className="md:text-center md:scroll-px-10 md:mx-40 m-5   ">

          <TestHeading heading="Test  Instructions" />
          <p className="text-[13px] md:text-justify ">
            {" "}
            Congratulations! You are well on your way to relationship success. <br /> <br />

This comprehensive assessment will probe deeper into your personality. Gain insights on how you handle romantic relationships, how you communicate, what irritates you, how you resolve conflict, and how emotionally resilient you are.
<br /><br />
Please rest assured you are in good hands! This test is the result of detailed research and drawn from proven principles of Carl Jung and Lewis Goldberg.
<br />

          </p>
         
         
        </div>
        <div className=" m-5 md:mx-40 md:mt-10   md:my-5 sm:mx-5 text-[13px] md:text-justify  justify-left  2xl:mt-0">
          <div className="flex mb-5">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>Please click on the appropriate radio button to answer your question. Most questions have two alternate answers, while others have three options.</p>
          </div>
          <div className="mb-5 flex">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>
            DO NOT think like a student, employee or any other prescribed role you play in your daily life. Focus on your natural preferences and behaviour in a romantic relationship and answer. 


            </p>
          </div>
          <div className="mb-5 flex">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>
            	Don’t think of what your partner/ parents/ society might want, but pay attention to how you behave and react in a particular situation and answer accordingly.
            </p>
          </div>
         
          <div className=" mb-5 flex">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>
            	Answer all 84 questions and choose the response that reflects the most natural way you think and respond.
            </p>
          </div>
          <div className=" mb-5 flex">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>
            The validity of the test results depends on how honestly you answer the questions without any bias or prejudices.
            </p>
          </div>
          <div className=" mb-5 flex">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>
            You can pause the test in between. Your answers will remain saved .You can resume later from where you left off by pressing the resume button in my account page.
            </p>
          </div>
          <div className=" mb-5 flex">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>
            For any other queries, ping on the chat icon alongside.
            </p>
          </div>
         
         
          <PaidForm />
        </div>
      </div>
    </>
  )
};
export default PaidInstruct;
