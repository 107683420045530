import { useEffect, useState } from 'react'
import img from '../../asset/images/l01.png'
export default function Topic(props) {
    const [searchResult,setSearchResult]=useState(props.searchResult)
    useEffect(()=>{
        setSearchResult(props.searchResult)
    },[props.searchResult])
    return <>
    <div className="border-[1px] max-w-[300px] border-[#814576] rounded-lg shadow-xl">
<h2 className="font-[700] py-2 px-2 text-[15px] border-b-[1px] border-[#814576]">TOPICS</h2>
   <div>
   {props.data.map((data)=>{
    return  <button className={searchResult==data.name?'py-1 px-2 text-center border-[1px] m-2 rounded-lg text-white bg-[#814576] font-[500] text[13px]':'py-1 px-2 text-center border-[1px] m-2 rounded-lg text-[#814576] border-[#814576] font-[500] text[13px]'} onClick={()=>props.datafilter(data.name)}>{data.name}</button>
   })}
   </div>
    </div>
    </>
}