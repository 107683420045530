import React from "react";
import "../components/Style.css";
import Navbar from "../components/header/Navbar";
import Wave from "../components/header/Wave";
import Instruct from "../components/instruction/paidins";
import Footer from "../components/Footer";


export default function Homepage() {
  return (
    <React.Fragment>
      <section>
    
        <Wave
          title="KnotRiteType"
          subTitle="Detailed Individual Assessment"
          current="Paid Assesment"
        />
    <Instruct/>

      
      </section>
    </React.Fragment>
  );
}
