import React from "react";
import "../components/Style.css";
import Navbar from "../components/header/Navbar";
import Wave from "../components/header/Wave";

import Footer from "../components/Footer";
import Price from "../components/Pricing/price";
import Content from "../components/services/Content";
import service1 from "../asset/images/home03.jpg";
import service2 from "../asset/images/service2.png";
import service3 from "../asset/images/service3.png";
import service4 from "../asset/images/service4.png";
export default function Services() {
  return (
    <React.Fragment>
      <section>
        <Wave title="Services" subTitle="" current="services" />
        <div className="flex flex-col justify-around mx-[5%]  md:flex-nowrap flex-wrap">
          <p className="feaure_sub-heading font-[400] text-[13px] text-justify text-[#363636] leading-7">
            Knotrite had developed KnotRiteMetric®, a suite of assessment instruments and customized relationship coaching plans focusing on the state of preparedness for relationship/marriage in the Indian societal space. These are one-of-a-kind tools based on proven behavioral, and psychological principles adapted to the Indian traditions and customs.
          </p>
          <Content
            img={service1}
            taketestbutton={true}

            heading="Preliminary Assessment for Relationship Readiness "
            sub_heading={"<span>This free assessment called “KnotRiteReadiness” measures your relationship/ marriage readiness. The report predicts your attitude and behavior in a relationship – how comfortable you are with closenes  s and intimacy, how much independence and autonomy you want within a relationship, the amount of trust you place in others, your emotional resilience, etc. This, in turn, will determine the quality of your relationship in the long run.<br/> This is a methodology developed to capture different ways in which people go about forming intimate connections with others specifically for the relationship and marriage compatibility of couples.  <br/>This is a free test. Go ahead and gauge your marriage readiness with the <strong>KnotRiteReadiness</strong> Score.  <p>"}
          />
          <Content
            img={service2}

            heading="Comprehensive Assessments for Individuals and Couples"
            sub_heading={"<p>The Individual  assessment tool <strong>“ KnotRiteType “</strong> probes deeper into your individual personality. The report of this assessment predicts how you handle romantic relationships, how you communicate, how you resolve conflict, and much more. This report will make you aware of your own strength and weaknesses as an individual which in turn will help you make necessary changes and adjust to having a fulfilling relationship.<br/><br/> The Couple Compatibility tool <strong>“KnotRiteCoupleMatch”</strong> enables prospective or current couples to gauge the quality of the match. This is based on the results of the Individual Personality report <strong>“KnotRiteType”</strong>  of both partners, The match Assesment  algorithm has been specifically built for Indian couples like you, and the relevant situations and challenges you might face when you start to live together. This report predicts your emotional and physical compatibility, and your views on various aspects of marriages i.e. – money, religion, rituals, in-laws and other such practical realities of modern Indian society. Addressing these facets of life and understanding the repercussions of these on your marriage is important to have a meaningful and peaceful, enriching life.   <br/><br/>  These tools are based on the ground-breaking theory of “Collective Unconscious” and “Psychological Types” published by the famous Swiss Psychologist and Psychiatrist Carl Jung in early 1900, “Attachment Style theory” by psychiatrist John Bowlby and psychologist Mary Ainsworth, and “Big 5 Personality” theory by Lewis Goldberg, the American psychologist in 1960s. <br /><br />This is part of our premium services. Please sign up/ to access these services</p>"}
          />
          
          {/* <Content
            img={service3}

            // heading="KnotRiteCoupleMatch Assessment"
            sub_heading={"<p></p>"}
          /> */}
          <Content
            img={service4}
            heading="Customised Coaching Plans"
            sub_heading={"<p>Based on the assessment results, KnotRite provides customized action plans to address specific areas of improvement. These personalized action plans meticulously crafted by seasoned professionals. These are designed to help Individuals and couples navigate challenges and strengthen their bond over time. The content and duration of these plans are tailored to the individual needs as determined by the assessment reports.  <br/>We believe that you will be able to look gain deeper insights into what it takes to make a relationship and marriage happy.Managing expectations are crucial, our plans can help you build a nearly-perfect life.<br/><br/>This is part of our premium services. Please sign up to access these services</p>"}


  

        />
      </div>
    </section>
    </React.Fragment >
  );
}
