
import React, { useState } from "react";
import { post } from "../../services/apiHandler";
import VerifyOtp from "./VerifyOtp";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';



export default function ForgotPassword({ visible, onClose,onLogin }) {
  const [mobile_number, setMobile_number] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const handleCloselg = () => setShowLogin(false);
  const [fv, setfv] = useState(false);
  const navigate = useNavigate();

  const handleOnClose = () => {
    onClose();
  };
  const handleClose = () => {
    setfv(false);
    
  };

  const reqOtp = (e) => {
    e.preventDefault();
    post("api/forgot_password_request", {mobile_number:mobile_number})
    .then((res) => {
      
      if (res.response == true) {
        toast.success('OTP sent Successfully', {
          position: toast.POSITION.TOP_RIGHT
        });
        setfv(true);
        // handleOnClose();
      } else {
        toast.error(res.data.data[Object.keys(res.data.data)][0], {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    })
    .catch((error) => {
      
    });
}
const [showModal, setShowModal] = useState(false)
const handleOnCloselg = () => setShowModal(false)
if(!visible)return null
  return (
    <>
      <div className={"fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center "}>
        <div className=" p-2 m-4 rounded md:w-[30%] w-[98%] max-h-full mx-auto bg-gradient-to-t from-[#641662] to-pink-200">
          <button className="float-right mr-2" onClick={handleOnClose}>
            X
          </button>
          <div className="columns flex space-x-52">
          <div className="bg-icon w-[22%] h-[45px] bg-no-repeat bg-contain  ml-4 mt-4"></div>
        </div>
          <div>
            <h2 className="text-white mt-6 text-3xl font-[700] text-center justify-center">
              Forgot Password
            </h2>
            <form  className="max-w-full ">
              <div className="my-3 font-[600] w-full flex flex-col items-center text-[13px] ">
                <div className="mx-auto w-[85%] ">
                 
                <label className="text-white">Phone*</label>
                <br />
                <input
                  type="number"
                  placeholder="Phone"
                  name="mobile_number"
                  value={mobile_number}
                  onChange={(e) => setMobile_number(e.target.value)}
                  className="[appearance:textfield] min-w-[100%] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none mt-2 flex h-10  items-center rounded-md justify-center border p-3 w-[100%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2] dark:!border-black-400 dark:!text-black-400 dark:placeholder:!text-black-400 bg-slate-50"
                />
              </div>
              </div>
              <div className="mb-3 mt-7 w-full text-center">
                <button
                  className="rounded-full  bg-[#CD64A3] w-[85%] font-[700] text-[13px] px-5 py-2    text-white transition duration-200  hover:bg-[#9c4c7c]"
                  onClick={(e) => reqOtp(e)}
                >
                  GET OTP
                </button>
              </div>

              <div className="md:flex flex w-full text-center mb-5">
                <p className="text-center justify-center font-[16px]  mt-2 pl-5 text-[13px]s w-full text-white">
                  Back To{" "}
                  <span className="font-[700] text-[white] cursor-pointer ml-1 cursor" onClick={()=>{
                    
                  onLogin()}}>
                    Login
                  </span>
                </p>
                <b className=""></b>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <Login onClose={handleOnCloselg} visible={showModal} /> */}
      {/* <Login onClose={handleCloselg} visible={showLogin} /> */}
      <VerifyOtp onClose={handleClose} mobile_number={mobile_number} visible={fv} />
    </>
  );
}
