export default function Content(props) {
    return <>
   
    <h3 className="text-white bg-[#67365E] w-full my-3 font-500 px-3 ">
          {props.heading}
          </h3>
          {props.dataheading&& <h1 className="text-[#67365E] font-[600]  text-[15px]">{props.dataheading}</h1>}
          <p className="text-[#67365E] text-[13px] text-justify ">
           {props.data.substring(0,props.data.length/2)}
            <img src={props.img} alt="" />
            {props.data.substring(props.data.length/2,props.data.length)}
            
          </p>
    </>
}