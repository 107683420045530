import React,{useState} from "react";
import '../components/Style.css';
import Wave from "../components/header/Wave";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { post } from "../services/apiHandler";
import { useNavigate } from 'react-router-dom';

export default function ChangePassword() {

  const [showOldPassword,setShowOldPassword] = useState(false);
  const [showNewPassword,setShowNewPassword] = useState(false);
  const [showConfirmPassword,setShowConfirmPassword] = useState(false);
  const [data,setData] = useState({
    old_password: "",
    password: "",
    password_confirmation: "",
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const changePassword = () => {
    post("api/update_password", data)
    .then((res) => {
      if (res.data.success == true) {
        localStorage.removeItem("login");
        navigate('/')
      }
    })
    .catch((error) => {
      
    });
}

  return (
    <React.Fragment>
      <section>
      
        <Wave title="Change Password" subTitle="" current="Change Password"/>
        <div className="w-full flex flex-col items-center mb-32  ">
       
            <div className="md:w-[30%] w-[90%] bg-[#FDF1F9] rounded-xl p-8">
              
                <div className="relative flex flex-col justify-between">

                <div className="mb-3 mt-7">
                    <label className="text-[15px] text-navy-700  font-[700] text-[#333333]">OLD PASSWORD</label>

                    <div className="relative">
                    <input type={showOldPassword ? "text" : "password"} name="old_password" onChange={(e)=>handleInputChange(e)} placeholder="Old Password" className="relative mt-2 flex h-12  items-center justify-center rounded-sm border bg-white/0 p-3 w-[100%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2] dark:!border-black-400 dark:!text-black-400 dark:placeholder:!text-black-400 bg-slate-50"/>
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                      <span
                        onClick={()=>setShowOldPassword(!showOldPassword)}
                        className="absolute right-0 pr-8 cursor-pointer"
                      >
                        {showOldPassword ? (
                          <AiFillEye className="cursor-pointer text-lg text-blue" />
                          ) : (
                            <AiFillEyeInvisible className="cursor-pointer text-lg text-blue" />
                            )}
                      </span>
                      </div>
                      </div>
                </div>
            
            
                <div className="mb-3 mt-7">
                    <label className="text-[15px] text-navy-700  font-[700] text-[#333333]">NEW PASSWORD</label>

                    <div className="relative">
                    <input type={showNewPassword ? "text" : "password"} name="password" onChange={(e)=>handleInputChange(e)} placeholder="New Password" className="relative mt-2 flex h-12  items-center justify-center rounded-sm border bg-white/0 p-3 w-[100%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2] dark:!border-black-400 dark:!text-black-400 dark:placeholder:!text-black-400 bg-slate-50"/>
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                      <span
                        onClick={()=>setShowNewPassword(!showNewPassword)}
                        className="absolute right-0 pr-8 cursor-pointer"
                      >
                        {showNewPassword ? (
                          <AiFillEye className="cursor-pointer text-lg text-blue" />
                          ) : (
                            <AiFillEyeInvisible className="cursor-pointer text-lg text-blue" />
                            )}
                      </span>
                      </div>
                      </div>
                </div>

                <div className="mb-3 mt-7">
                    <label className="text-[15px] text-navy-700  font-[700] text-[#333333]">CONFIRM PASSWORD</label>

                    <div className="relative">
                    <input type={showConfirmPassword ? "text" : "password"} name="password_confirmation" onChange={(e)=>handleInputChange(e)} placeholder="Confirm Password" className="relative mt-2 flex h-12  items-center justify-center rounded-sm border bg-white/0 p-3 w-[100%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2] dark:!border-black-400 dark:!text-black-400 dark:placeholder:!text-black-400 bg-slate-50"/>
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                      <span
                        onClick={()=>setShowConfirmPassword(!showConfirmPassword)}
                        className="absolute right-0 pr-8 cursor-pointer"
                      >
                        {showConfirmPassword ? (
                          <AiFillEye className="cursor-pointer text-lg text-blue" />
                          ) : (
                            <AiFillEyeInvisible className="cursor-pointer text-lg text-blue" />
                            )}
                      </span>
                      </div>
                      </div>
                </div>
            <div className="mb-3 mt-7 w-full">
            <button onClick={()=>changePassword()} className="rounded-full bg-[#CD64A3] w-[100%] font-[700] text-[17px] px-5 py-3 text-base   text-white transition duration-200  hover:bg-[#9c4c7c]">
            SAVE
                </button> 
                </div>
            </div>
        
               
</div>
</div>
</section>
     

    </React.Fragment>
  );
}
