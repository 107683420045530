import React,{useState, useEffect} from "react";
import "../components/Style.css";

import Wave from "../components/header/Wave";
import contactImg from '../asset/images/contactusboy.png'

import TestHeading from "../components/test/testHeading";
import { BsStarFill } from "react-icons/bs";
import { post } from "../services/apiHandler";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Contact } from "../schemas";

export default function ContactUs() {
  const mails=['support@knotrite.in','feedback@knotrite.in']
 const [data,setData]=useState({})
 const [error, seterror] = useState(false);
//  const handleChange=(e)=>{
// setData({...data,[e.target.name]:e.target.value})
//  }
const initialValues={
  message: "",
 
  name: "",
  subject: "",
  email: "",
  
  
}
const{values,errors,handleChange,handleBlur,handleSubmit}=useFormik({
  initialValues:initialValues,
  validationSchema:Contact,
  onSubmit:(value)=>{
    SubmitData(value)
  }

})

 const SubmitData=async(val)=>{
  seterror("")
  const er=Object.keys(errors)
  
  
if(er?.length>0)
 { seterror(errors[Object.keys(errors)[0]])
return
}

 else if(!data.rating)seterror("Please Fill Rating")
 else{ const res=await post("api/feedback",{...val,rating:data.rating})
  if(res.data.success){
    toast.success("Thanks For Sharing Feedback !")
  }
  else toast.error("Somthing Went Wrong !")
 }
 }

  return (
    <React.Fragment>
      <section>
      {error != "" && (
              <div
                class="mb-4 flex  text-white justify-around fixed right-0 rounded-lg bg-red-600 px-6 py-5 text-base text-primary-600"
                role="alert"
              >
                {error}
                <button
                  className="float-right ml-16"
                  onClick={() => seterror("")}
                >
                  X
                </button>
              </div>
            )}
        <Wave title="Contact us" subTitle="" current="contactus" />
        <div className="md:mx-28">
         <TestHeading heading="   Get In touch with us"/>
         
         
          <div className="flex justify-center flex-wrap md:flex-nowrap">
            <div className="min-w-[50%] bg-[#FDF1F9] p-5">
              <h1 className="font-[700] text-[20px]">Share Your Feedback</h1>
              <div className="">
                <form>
                  <div className="mb-3 mt-7 w-[100%]">
                    <label
                      for="email2"
                      className="text-sm text-navy-700  font-[600] text-[#333333]"
                    >
                      Name  <span className={"text-[red]"}>*</span>
                    </label>
                    <input
                      type="text"
                      id="email2"
                      name="name"
                     
                      value={values.name}
                      onChange={handleChange}
                  onBlur={handleBlur}
                      placeholder=" input"
                      className="mt-2 flex h-12  items-center justify-center rounded-md border bg-white/0 p-3 md:w-[90%] w-[100%] text-sm outline-none border-[#AC4884] text-[#AC4884]   bg-slate-50"
                    />
                     <p className=" text-[10px] font-[600] text-[red]">{errors.name}</p>
                  </div>
                  <div className="mb-3 mt-7 w-[100%]">
                    <label
                      for="email2"
                      className="text-sm text-navy-700  font-[600] text-[#333333]"
                    >
                      Email  <span className={"text-[red]"}>*</span>
                    </label>
                    <input
                      type="email"
                      id="email2"
                      placeholder=" input"
                      name="email"
                      value={values.email}
                    onChange={handleChange}
                onBlur={handleBlur}
                      className="mt-2 flex h-12  items-center justify-center rounded-md border bg-white/0 p-3 md:w-[90%] w-[100%] text-sm outline-none border-[#AC4884] text-[#AC4884]   bg-slate-50"
                    />
                     <p className=" text-[10px] font-[600] text-[red]">{errors.email}</p>
                  </div>
                  <div className="mb-3 mt-7 w-[100%]">
                    <label
                      for="email2"
                      className="text-sm text-navy-700  font-[600] text-[#333333]"
                    >
                      Subject  <span className={"text-[red]"}>*</span>
                    </label>
                    <input
                      type="text"
                      id="email2"
                      name="subject"
                      value={values.subject}
                    onChange={handleChange}
                onBlur={handleBlur}
                      placeholder=" input"
                      className="mt-2 flex h-12  items-center justify-center rounded-md border bg-white/0 p-3 md:w-[90%] w-[100%] text-sm outline-none border-[#AC4884] text-[#AC4884]   bg-slate-50"
                    />
                  </div>
                  <p className=" text-[10px] font-[600] text-[red]">{errors.subject}</p>
                  <div className="mb-3 mt-7 w-[100%]">
                    <label
                      for="email2"
                      className="text-sm text-navy-700  font-[600] text-[#333333]"
                    >
                      Message  <span className={"text-[red]"}>*</span>
                    </label>
                    <textarea
                     
                      id="email2"
                      cols={450}
                      name="message"
                      value={values.message}
                    onChange={handleChange}
                onBlur={handleBlur}
                      placeholder=" input"
                      className="mt-2 flex h-28   items-center justify-center rounded-md border bg-white/0 p-3 md:w-[90%] w-[100%] text-sm outline-none border-[#AC4884] text-[#AC4884]   bg-slate-50"
                    />
                   <p className=" text-[10px] font-[600] text-[red]">{errors.message}</p>  
                    <div className="mb-3 mt-7 w-[100%]">
                    <label
                      for="email2"
                      className="text-sm text-navy-700  font-[600] text-[#333333]"
                    >
                      Rating <span className={"text-[red]"}>*</span>
                    </label>
                    <StartComponent ratingprop={(rat)=>{setData({...data,rating:rat})}} />
                  </div>
                    <button  className="bg-[#480740]  w-[20%] min-w-[100px] uppercase text-white font-[Poppins] flex justify-around items-center py-2 px-5 rounded-3xl mt-[5%]  hover:bg-pink-800 duration-500 cursor-pointer"
                      type="button" onClick={()=>SubmitData(values)}>Submit</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="contact p-5 pl-8 min-w-[51%]">
              <h1 className="font-[700] text-[20px]">Contact Us</h1>
              <div className="flex justify-start md:justify-start  flex-wrap mt-3">
                {mails.map((val,i)=>{
                  return <p key={i} className="font-[400] flex text-[16px] text-[#5A5A5A] md:mr-4 mt-2">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.868516 5.71166L8.57435 1.09166C8.70386 1.01395 8.85206 0.9729 9.0031 0.9729C9.15414 0.9729 9.30233 1.01395 9.43185 1.09166L17.1302 5.71249C17.1919 5.7495 17.2431 5.80189 17.2786 5.86455C17.3141 5.9272 17.3327 5.99798 17.3327 6.06999V16.6667C17.3327 16.8877 17.2449 17.0996 17.0886 17.2559C16.9323 17.4122 16.7204 17.5 16.4993 17.5H1.49935C1.27834 17.5 1.06637 17.4122 0.910093 17.2559C0.753813 17.0996 0.666016 16.8877 0.666016 16.6667V6.06916C0.666001 5.99715 0.684647 5.92637 0.720137 5.86371C0.755627 5.80106 0.806748 5.74867 0.868516 5.71166ZM14.2877 6.86999L9.05018 11.4025L3.70518 6.86499L2.62685 8.13499L9.06018 13.5975L15.3777 8.13083L14.2877 6.86999Z"
                      fill="#480740"
                    />
                  </svg>&nbsp;
                 {val}
                </p>
                })}
                
               
              </div>
              <div className="flex justify-center mt-5">
              <img src={contactImg} width={"600px"} alt="contact" />
              </div>
           
            </div>
          </div>
        </div>
      </section>
    
    </React.Fragment>
  );
}


export const StartComponent = ({ratingprop}) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  useEffect(() => {
  }, [rating]);
  return <>
  <div className="mt-2">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            onClick={() => {setRating(index) 
              ratingprop(index)}}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
              <BsStarFill className={index <= (hover || rating) ? "text-yellow-400 text-3xl ml-2" : "text-white text-3xl ml-2"}
            onClick={() => setRating(index)} />
          </button>
        );
      })}
    </div>
  </>
}