import cmr1 from "../asset/images/cmr1.png";
import cmr2 from "../asset/images/cmr2.png";
import cmr3 from "../asset/images/cmr3.png";
import cmrmt from "../asset/images/cmr-mt.png";
import knotmeter from "../asset/images/knotmeter.png";
import Content from "../components/reports/filledColorHeading";
import finalFlag from '../asset/images/finalFlag.jpg'
export default function KnotRiteCoupleMatch() {
  return (
    <>
      <div className="mt-[12%] p-8">
        <h1 className="text-[#67365E] font-[600]  text-[30px]">Findings</h1>
        <p className="text-[#67365E] text-[13px] text-justify ">
          This report includes results of your compatibility quotient as a
          couple. Both of your individual KnotRiteType assessments have been
          taken as inputs for this match report. The premise of this
          match-report has been evaluated against various behavioral and
          societal parameters which impact us the most when we start living with
          our partner under the same roof. Your score against these parameters
          is indicated through a KnotRiteMeter icon (red as poor and green as
          excellent).
        </p>
        <div className="flex flex-col items-center">
          <h3 className="text-white bg-[#67365E] font-500 px-3 ">
            Your KnotRiteCoupleMatch The Fair
          </h3>
          <img src={cmr1} alt="person" />
          <img src={knotmeter} alt="person" width={800} />
          <h3 className="text-white my-3 w-full bg-[#67365E] font-500 px-3 ">
            Overview
          </h3>
          <p className="text-[#67365E] text-[13px] text-justify ">
            This assessment is based on the premise that some behaviours of
            partners in relationship may be more commonly associated with
            personality traits of each of them. We have identified several
            parameters relevant to marital life and analysed personality traits
            against each them. On each parameter, we identify positives and
            negatives and improvement areas wherever applicable.
            <img src={cmr2} alt="" />
            <b>
              W e find your prospects as a couple as Fair- a mix of some
              positives and negatives. Some traits are complementary while
              others could be source of potential conflicts.
            </b>
            <br />
            We believe you will find this read interesting , revealing and help
            you in deciding future course of action. <br />
            Please note that In the following analysis the pronouns ”you” or “
            your” could be applicable to either of you or to the couple jointly
            and not exclusively to you as an individual.
          </p>
          <img src={cmr3} alt="" />
          <Content
            img={cmrmt}
            data={
              "As a couple you have differing emotional styles and needs, which can affect  emotional compatibility. One of you  are typically more attuned to own emotions and the emotions of others. You are often warm, empathetic, and nurturing, and value creating emotional connections in a relationship. The partner , on the other hand, tend to be more focused on logic and efficiency, and  may prioritize problem-solving over emotional expression. While you may seek emotional intimacy and closeness, your partner while not necessarily neglecting emotions, may have a tendency to approach emotions from a more rational standpoint. There are some mutually supportive traits too. One of you have a high  tolerance for emotional intensity and may be less affected by fluctuations in  own emotions or the emotions of others. This enables you to provide support to your partner who may be more influenced by the emotional atmosphere This quality can bring about stability and grounding  during turbulent times.Both of you need to put in some effort to build understanding, empathy, and a willingness to meet each other's emotional needs which are crucial for building a strong emotional connection"
            }
            heading={"Emotional Compatibility"}
          />
          <Content
            img={cmrmt}
            data={
              "As a couple you may approach conflict resolution in different ways due to your contrasting personality traits and priorities.One of you are typically direct and straightforward in your communication style. You  tend to address conflicts head-on and may express your thoughts and concerns openly with logical, rational, result oriented solutions. Your partner on the other hand ,during conflicts, may prioritize addressing and resolving emotional tensions, seeking understanding, compromise, and diplomacy. For your partner, preserving harmony and emotional well-being is more important in resolving disagreements.These differences require open and honest communication. Both individuals should express their thoughts, feelings, and concerns while actively listening to the other person's perspective. Developing empathy and understanding for each other's emotional needs and perspectives is equally important. It's crucial to keep the focus on the specific issue at hand rather than engaging in personal attacks or criticism"
            }
            heading={"Solving disagreements through communication"}
          />
             <Content
            img={cmrmt}
            data={
              "According to your match profile, this is one are where there is a certain degree of complementariness and mutually supportive traits. One of you is highly organised, scheduling tasks systematically, and delegating work as required. You can also bring about innovative ways to carry out mundane repetitive housework. The other partner while equally r esponsible to carry out household work, brings in a sense of  aesthetics, tidying spaces, and making the home inviting and comfortable. This other partner often embodies a caring and nurturing nature, which extends to his/her approach to domestic chores. He/she may see these tasks as opportunities to provide comfort and support for  loved ones. This partner generally loves preparing meals, doing laundry, or creating a cosy atmosphere in the home.Nevertheless, to avoid any potential resentment , it is important to discuss and agree upon a fair distribution of tasks that considers each person's strengths, availability, and personal preferences. Willingness to adapt and support each other in times of change can foster harmony and cooperation.. "
            }
            heading={"Managing household responsibilities"}
          />
             <Content
            img={cmrmt}
            data={
              "As a couple ,you may have different approaches to handling financial matters due to  distinct personality traits and priorities. One of you may focus on long-term financial goals, such as investments, savings. You are likely to analyse financial data, research opportunities, and develop investment plans. You are often comfortable taking calculated risks. Your partner on the other hand, may prefer a more cautious approach to finances, seeking stable income sources and prioritizing savings for the future. He/she  may be risk-averse and prefer conservative investment strategies. Your spending patterns are similar to the extent that you value things which are practical and useful  such as household necessities, healthcare. One of you could be inclined towards  making others feel appreciated and cherished through thoughtful gifts for family and friends . You are also not averse to spending on vacations, or events that strengthen bonds and create lasting memories. This could create a bit of discord with a partner who is more functionally inclined.It is crucial for both of you to learn each other's preferences before starting your life together. It is highly advisable that you opt for counselling sessions and meet the experts to go forward. "
            }
            heading={"Managing money"}
          />
             <Content
            img={cmrmt}
            data={
              "As a couple, you have good similarities  in your respective personality traits on this parameter. Both of you tend to be energetic and optimistic in  most situations. You love taking  on new challenges with excitement, sense of adventure and generally face life with confidence .Both of you value deep emotional connections and support in  intimate relationships .One of you could view intimacy as an opportunity for personal and relationship development. The other partner may enjoy creating a warm and inviting atmosphere, engaging in romantic activities, and making the loved one feel valued and appreciated. While one of you may express  emotion and needs in direct clear manner, the other might prefer a more nuanced communication involving thoughtful gestures, acts of service, and expressions of love.To reinforce and strengthen your bond it will be good to engage in open and honest communication about each other's expectations, desires, and boundaries regarding intimacy. Discuss preferences, needs, and concerns to foster understanding and address any potential conflicts or misunderstandings."
            }
            heading={"Views on intimacy"}
          />
             <Content
            img={cmrmt}
            data={
              "As a couple you may have different views on extended family based on your distinct personality traits. One of you may value autonomy and personal freedom, and  may prioritize  immediate family   over extensive involvement with extended family members. You may consider engaging with extended family only if you perceive it as contributing to  personal or professional advancement, such as networking opportunities or strategic alliances. Whereas the other partner could may actively seek opportunities to spend time with and support  relatives, fostering a sense of belonging and unity within the extended family. For this partner, who has a strong nurturing nature ,  providing emotional support, organizing family gatherings, etc. comes naturally.To prevent  discord on this aspect, do have open communication to find a balance between one partner’s inclination for autonomy and the other’s desire for family connection. It is important to respect each other's boundaries and navigate extended family dynamics together."
            }
            heading={"Views on extended family"}
          />
          <Content
            img={cmrmt}
            data={
              "As with other parameters, as a couple you may have divergent perspectives here. One of you approaches religion with a logical and analytical mindset. You may be more inclined to question religious doctrines and seek rational explanations. Though yo u may not conform to traditional religious practices, you may embrace a personal and individualistic form of spirituality seeking personal growth, & self-improvement. Whereas ,the other partner may be more attuned to tradition and may have a deep respect for religious customs and rituals. Also this partner often appreciates the sense of community and belonging that religious organizations offer. He/she may value the opportunity to connect with others who share common beliefs, engaging in collective worship, and participating in religious events and gatherings.As with other contrasting parameters, we urge you to engage in respectful discussions about religious beliefs and perspectives. Seek to understand each other's viewpoints without judgment or attempts to change the other's beliefs. Practice active listening and empathy to create a safe space for open dialogue."
            }
            heading={"Views on religion, custom, tradition"}
          />
             <Content
            img={cmrmt}
            data={
              "This aspect brings out another instance on contrasting personality traits. One of you have a strong drive for achievement and success in your  career and  may have a tendency to prioritize work over personal life. You may see work as an integral part of  identity and derive satisfaction and fulfilment from making progress towards personal goals. Your partner in the other hand may give higher priority to  the importance of nurturing relationships, spending time with loved ones, and engaging in activities outside of work to maintain overall well-being and happiness. He/She  seeks a sense of purpose and meaning in  work and personal lives.Achieving work-life balance is a personal journey for both partners. It involves self-awareness, setting boundaries, and prioritizing one's physical and emotional well-being. Communication and understanding maybe with expert advise from counsellors  can help navigate the challenges and find a balance that suits both individuals' needs and preferences."
            }
            heading={"Views on work-life balance"}
          />
          
             <Content
            img={cmrmt}
            data={
              "While , this report can work as a road-map for you and partner in terms of strength and weaknesses and provide  recommendations and guidelines to make it healthier. But at the end of the day, it is both of your responsibilities to put effort and make it work.The above analysis takes care to maintain confidentiality of  personality traits of either of you and therefore presents a generalised assessment on the prospects of the match. It's important to note that personality types provide a general framework and individuals can deviate from these patterns. If you ,as a couple decide to explore deeper into the possibilities of the match, we can provide an individualised counselling for each of you separately and also jointly . The interaction during the counselling sessions will bring out an indepth analysis on specific personality attributes and identify the strengths and potential challenges of the match. We will also discuss in greater depth of possible solutions to overcome pitfalls. At the end of these sessions, you will be considerably well informed and be confident to take the crucial life decision - of whether to go ahead with the match or seek other alternatives. "
            }
            heading={"Conclusions"}
          />
          <img src={finalFlag} alt="" />
        </div>
      </div>
    </>
  );
}
