import { useNavigate } from "react-router-dom";
import dwdIcon from '../../asset/images/dowload-icon.png'
export default function Card(props) {
  const navigate = useNavigate()
  return (
    <>
      <div className="shadow-xl md:min-w-[30%] md:max-w-[30%] w-full rounded-3xl py-5 px-6 mr-3 mb-16 bg-[#98597f7b] border-2 border-[#98597f7b] flex flex-col items-center">
        <h1 className="font-[700] break-all text-[20px] text-center text-[#341a30] mb-3">
          {props.name}
        </h1>

        <h2 className="font-[600] text-[black] text-[13px] mb-3">
          {props.mobile}
        </h2><br />
        {props.file_path != null ?<> 
        <button className={props.file_path != null ? "    bg-[#AC4884] font-[600] mt-2 text-white text-[10px]  flex justify-around items-center py-[5px] px-4 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer" : "     font-[600] bg-white  text-[rgb(186,114,172)] text-[10px]  flex justify-around items-center py-[5px] px-4 rounded-3xl cursor-auto"} onClick={() => {
         
       
        }}>
            Couple Match Score: <br /> {props.score}
        </button>
        
        <div className="flex mt-2"><button className={props.file_path != null ? "    bg-[#AC4884] font-[600]  text-white text-[10px]  flex justify-around items-center py-[5px] px-4 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer" : "     font-[600] bg-white  text-[rgb(186,114,172)] text-[10px]  flex justify-around items-center py-[5px] px-4 rounded-3xl cursor-auto"} onClick={() => {
    window.screen.width<768?window.open("https://knotrite.in/backend/" + props.mobile_file_path,'_blank', 'noopener,noreferrer'):window.open("https://knotrite.in/backend/" + props.file_path,'_blank', 'noopener,noreferrer')}     
          
       }>
          View Couple Match <br /> Report
       </button>
       {/* <img src={dwdIcon} className="ml-2  w-[35px] h-[30px]"/> */}
       </div>
         </>
        :
          <div className=" w-full   relative flex-col  flex items-center justify-center ">
            <button className={"   font-[600] mt-2text-[10px] opacity-0  flex justify-around items-center py-[5px] px-4 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer" }>
            Couple Match Score: <br /> Somthing here
        </button>
             <a
             
              class="transititext-primary flex justify-center items-center text-black transition duration-150 ease-in-out hover:text-primary-600 focus:text-red active:text-primary-700 "
              data-te-toggle="tooltip"
              title={"Button not active since partner has not completed test"}
            >
                <button  className={" font-[600] bg-white  text-[rgb(186,114,172)] text-[10px]  flex justify-around items-center py-[5px] px-4 rounded-3xl cursor-auto"} onClick={() => {
                props.file_path == null?props.disableReport():
                  window.open("https://yrpitsolutions.com/knotrite_backend/storage/" + props.file_path, '_blank', 'noopener,noreferrer');
                }}>
                View Couple Match <br /> Report
                </button>
              </a>
          
          </div>

        }

        <br />
        <button onClick={() => props.delete()} className="    bg-[#67365E] font-[600]  text-white text-[10px]  flex justify-around items-center py-[5px] px-10 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer">
          Delete Partner
        </button>
      </div>
    </>
  );
}
{/* <div class=" w-[10px] inset-y-0    flex items-center flex-row-reverse  text-sm leading-5">
                      <span
                       
                        className="absolute right-[-15%] top-[55%] w-fit  pr-8 cursor-pointer"
                      >
                       
                        {errors.password && (
                          <p>
                           
                           <a
                          href="#"
                          class="transititext-primary flex justify-center items-center text-black transition duration-150 ease-in-out hover:text-primary-600 focus:text-red active:text-primary-700 "
                          data-te-toggle="tooltip"
                          title={errors.password}
                          > <img src={info} className="w-[20px]"></img> </a>
                        </p>
                          )}
                      </span>
                      </div> */}