import { useNavigate } from "react-router-dom";

import Wave from "../components/header/Wave";
import { useEffect, useState } from "react";
import { get, post } from "../services/apiHandler";

import { useLocation } from "react-router-dom";
import { Dna } from "react-loader-spinner";


export default function PlanSummary() {
  const Nav = useNavigate();
  const [open, setOpen] = useState(false);
  const [haveCoupon, setHaveCoupon] = useState(false);
  const[coupon_discount_amount,setcoupon_discount]=useState(0)
  const[coupon_discount_percent,setcoupon_discount_percent]=useState(0)
  const[error,seterror]=useState({data:"",color:"red"})
  const[active,setactive]=useState(null)
  const[partnerPlan,setPartnerPlan]=useState({active:0})
  const[Data,setData]=useState({})
  const[loader,setLoader]=useState(false)
  const[couponValue,setCouponValue]=useState('')
  const {state} = useLocation();

  let { id, discountId, price,discount_amount,preprice,name,PremiumData ,des} = state||{};

  useEffect(()=>{loadData()},[]) 
 
  const loadData=()=>{
  
    if(PremiumData!=null){
    setactive(PremiumData)
    setData({id:PremiumData[0].id,price:PremiumData[0].price,name:PremiumData[0].name})
  }
  else{
    setData({id:id,discountId,price,discount_amount,preprice,name})
  }
  }
function HandleChange(index){
  handleremoveCoupon(0)
  setData({id:PremiumData[index].id,price:PremiumData[index].price,name:PremiumData[index].name})
  setPartnerPlan({active:index})
}


function loadScript(src) {
  return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
          resolve(true);
      };
      script.onerror = () => {
          resolve(false);
      };
      document.body.appendChild(script);
  });
}

const handlePayment = async (id, discountId, price,discount_amount) => {
 
  
  // const loadRazorPay = await loadScript("https://checkout.razorpay.com/v1/checkout.js" );

  //   if (!loadRazorPay) {
  //       
  //       setLoader(false)
  //       return;
  //   }
   
  // var options = {
  //     "key": process.env.RAZOR_PAY_ID,
  //     "amount": 0,
  //     "name": "YRP",
  //     "description": "Testing",
  //     'order_id':"",
  //     "handler": function(response) {
       
  //       
  //         
  //         var values ={
  //           'plan_id' : id,
  //          'discount_id' : discountId,
  //           'signature' : response.razorpay_signature,
  //           'order_id' : response.razorpay_order_id,
  //           'payment_id' : response.razorpay_payment_id,
  //           'amount' : price,
  //           'discount_amount' : discount_amount == "" ? 0 : discount_amount,
  //         }
  //         if(!discountId)
  //          values ={
  //           'plan_id' : id,
          
  //           'signature' : response.razorpay_signature,
  //           'order_id' : response.razorpay_order_id,
  //           'payment_id' : response.razorpay_payment_id,
  //           'amount' : price,
  //           'discount_amount' : discount_amount == "" ? 0 : discount_amount,
  //         }
  //         if(couponValue){
  //           values.code = couponValue.trim()
  //         }
  //         setLoader(true)
  //         post('api/user_subscription',values)
  //         .then((res)=>{
  //           if(res.data.success)
  //        {  seterror({data:'Transaction Success',color:"green"});
  //           Nav("/successPayment",{state:{name:name,invoice_data:res.data?.data.invoice_data?.file}})
  //         }
  //         else{
  //           setLoader(false)
  //           seterror({data:'Transaction Failed',color:"red"});
  //         }
  //         })
  //         .catch(e=>{
  //           seterror({data:'Transaction Failed',color:"red"});
           
  //         })
           
          
          
  //     },
  //     prefill: {
  //       name: "",
  //       email: "",
  //       contact: "",
  //     },
  //     "notes": {
  //       "address": "Bangalore"
  //     },
  //     "theme": {
  //       "color": "#528ff0"
  //     }
  //   };
       
          let payload = {
            amount: price * 100,
            plan_id:id,
            redirect_url:'http://localhost:3000/plansummary',
            discount_amount : discount_amount == "" ? 0 : discount_amount,
          }

          if(discountId !== ""){
            payload.discount_id = discountId;
          }

          if(couponValue){
            payload.code = couponValue.trim()
          }
    
    post('api/create_order',payload)
    .then(res=>{
      if(res.data.success)
      {  
        window.location.href = res.data.data.data.instrumentResponse.redirectInfo.url;
      }
        else{
          setLoader(false)
          seterror({data:res.data.message,color:"red"})
        }
    })
  .catch(e=> {
    
    seterror({data:e.data.message,color:"red"})})
  
};
const handleCoupon=async(val) => {
  try{
    setCouponValue(val)
const data=await get(`api/is_coupon_valid/${val}`)
if(data.data.success===true){
 
  // setcoupon_discount(Number(data.data.data.persentage))
  if(PremiumData==null){
  

  setcoupon_discount(Math.ceil(Number(price*data.data.data.percentage/100)))
}
else{
  // alert(Data.price)
  setcoupon_discount(Math.ceil(Number(Data.price*data.data.data.percentage/100)))
}
  setcoupon_discount_percent(`${data.data.data.percentage}%`)
  setOpen(true)
  setHaveCoupon(false)
  seterror({data:"Coupon Applied Successfully",color:"green"})
}
else{
  
  if(data.data.message=="entered coupon code redeemed already")
  seterror({data:"You have already redeemed this code",color:"red"})
else
seterror({data:"wrong coupon ",color:"red"})
}}
catch{
  seterror({data:"wrong coupon ",color:"red"})
}
}
const handleremoveCoupon=(val)=>{
setOpen(false)
if(val==1)
seterror({data:"Coupon removed Successfully",color:"green"})
setcoupon_discount(0)
  setcoupon_discount_percent(0)
}

  return (
    
    <>
      {
     loader?
    <div className="min-h-[100vh] flex justify-center items-center ">
    <Dna
    visible={true}
    height="80"
    width="80"
    ariaLabel="dna-loading"
    wrapperStyle={{}}
    wrapperClass="dna-wrapper"
  /></div>
    :<>
      <section>
      { error.data!=""&&(
         
         <div
           className={error.color=="red"?`mb-4 flex  text-white bg-red-600 justify-around sticky top-[120px] w-fit left-[90%] right-0 rounded-lg bg-red px-3 py-2 text-base text-primary-600`:`mb-4 flex  text-white justify-around sticky top-[120px] w-fit left-[90%]  rounded-lg bg-[green] px-3 py-3 text-base text-primary-600`}
           role="alert"
         >
          
           {error.data}
           <button
             className="float-right ml-16"
             onClick={() => seterror({...error,data:""})}
           >
             X
           </button>
         </div>
       )}
        <Wave title="Plan Summary" subTitle="" current="plan summary" /> 
       
        <div className="flex justify-around mx-4 my-10 md:flex-nowrap flex-wrap">
          <div className="flex md:flex-nowrap flex-wrap-reverse justify-between shadow-lg p-8 w-full md:max-w-[50%]">
           <div className="details md:mt-0 mt-5 w-full">
              <h2 className="font-[700] md:block hidden text-[20px]">{name}</h2>
              <h2 className="font-[500] text-justify text-[10px]">{name=="Add a partner"&&PremiumData&&PremiumData?.length>1&&"You can choose one of the plans below based on the partner you are inviting. If the invited partner is free member you have to choose the  3rd  option. Otherwise the first or second option will be  enough depending on your status of partner."}</h2>
             {(name!="Add a partner") ?<div className="details">
             {des.map((data,i)=><p key={i} className="font-[500] text-[13px] flex  mt-[4%]">
              <div className="max-w-[25px] min-w-[25px] h-full mr-2">
                <svg
                 
                  viewBox="0 0 24 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2593 8.33336L12.7513 9.80419L21.6969 0.985441L23.191 2.45836L12.7513 12.75L6.02674 6.12086L7.52085 4.64794L9.76624 6.86148L11.2593 8.33232V8.33336ZM11.2614 5.38753L16.494 0.228149L17.9838 1.6969L12.7513 6.85627L11.2614 5.38753ZM8.27424 11.2781L6.78119 12.75L0.0566406 6.12086L1.55075 4.64794L3.0438 6.11982L3.04275 6.12086L8.27424 11.2781Z"
                    fill="#CD64A3"
                  />
                </svg>
                </div>
                {data}
              </p>

             ) }
             
            </div>:
            active&&
            <>
            
            {active.map((data,i)=>{
              return  <>
              <p className="font-[500] text-[13px] flex mt-[4%] w-full items-start">
              <div className="w-[28px] h-[28px] min-w-[28px] min-h-[28px] mr-2  cursor-pointer  border-neutral-200 border-2" onClick={()=>{HandleChange(i)}}>
             {partnerPlan.active==i&& <svg
                width="24"
                height="28"
                viewBox="0 0 24 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2593 8.33336L12.7513 9.80419L21.6969 0.985441L23.191 2.45836L12.7513 12.75L6.02674 6.12086L7.52085 4.64794L9.76624 6.86148L11.2593 8.33232V8.33336ZM11.2614 5.38753L16.494 0.228149L17.9838 1.6969L12.7513 6.85627L11.2614 5.38753ZM8.27424 11.2781L6.78119 12.75L0.0566406 6.12086L1.55075 4.64794L3.0438 6.11982L3.04275 6.12086L8.27424 11.2781Z"
                  fill="#CD64A3"
                />
              </svg>}
              </div>
               {data.name}
            </p>
           
            </>
            })}
            </>
           
            }
            </div>
            
            <div></div>
            <div className="md:text-right w-full text-center">
            <h2 className="font-[700] md:hidden block text-[20px]">{name}</h2>
              <h1 className="font-[700] text-[20px] text-[#7B7B7B] mt-4">
               {Data.preprice&& <strike>₹{Data.preprice}</strike>}{" "}
              </h1>
              <h1 className="font-[700] text-[25px] text-[#AC4884] mt-4">
                ₹{Data.price}
              </h1>
             {Data.discount_amount&& <button className="rounded-full  md:min-w-[100px] w-[unset] bg-[#4CC314] text-[14px] md:px-1 px-4 py-1 text-base mt-4  font-medium text-white transition duration-200 ">
              {Data.discount_amount}% OFF
              </button>}
            </div>
          </div>

          <div className="flex justify-between shadow-lg w-full mt-6 md:mt-0 p-8 md:w-[30%]">
            <div className="details w-full">
              <h2 className="font-[700] text-[20px]">{name}</h2>
              <div className=" border-b-2 border-gray-200 pb-8 w-full">
                <div className="flex justify-between items-baseline w-full">
                  <p className="font-[400] text-[13px] mt-[10%]">Price</p>
                  <p className="font-[600] text-[20px] text-[#545454] mt-[10%]">
                   {Data.preprice? `₹${Data.preprice}`:`₹${Data.price}`}
                  </p>
                </div>
                {Data.discount_amount&&<div className="flex justify-between items-baseline">
                  <p className="font-[400] text-[13px] mt-[10%]">
                    Discount Price
                  </p>
                  <p className="font-[600] text-[20px] text-[#E40A0A] mt-[10%]">
                    -₹{Math.ceil(Data.preprice*Data.discount_amount/100)}
                  </p>
                </div>}
                {coupon_discount_amount>0&&<div className="flex justify-between items-baseline">
                  <p className="font-[400] text-[13px] mt-[10%]">
                    Coupon Discount
                  </p>
                  <p className="font-[600] text-[20px] text-[#E40A0A] mt-[10%]">
                    -₹{coupon_discount_amount}
                  </p>
                </div>}
                {/* <div className="flex justify-between items-baseline">
                  <p className="font-[400] text-[13px] mt-[10%]">Tax</p>
                  <p className="font-[600] text-[20px]  text-[#545454] mt-[10%]">
                    ₹00
                  </p>
                </div> */}
              </div>
              <div className="flex justify-between items-baseline">
                <p className="font-[400] text-[13px] mt-[10%]">Total Amount</p>
                <p className="font-[700] text-[25px] text-[#AC4884] mt-4">
                  ₹{Math.ceil(Data.price-coupon_discount_amount)}
                </p>
              </div>
              <div className="flex justify-between items-baseline">
                <p onClick={()=>setHaveCoupon(!haveCoupon)} className="font-[600] hover:underline hover:text-[#85277a] duration-100 text-[13px] mt-[10%] text-[#480740] cursor-pointer">
                  Have a coupon code?
                </p>
              </div>
             {haveCoupon&&<> <div className="flex justify-between items-baseline">
                <input
                  type="text"
                  id="coupon"
                  placeholder="Enter a coupon code?"
                  className="mt-2 flex h-[40px]  items-center justify-center rounded-md border bg-white/0 p-3 w-[50%]  text-sm outline-none border-[#AC4884] text-[#AC4884]   bg-slate-50"
                />
                <button
                  className="bg-[#480740] w-[30%] text-white font-[Poppins] flex justify-around items-center py-2 px-5 rounded-3xl mt-6  hover:bg-pink-800 duration-500 cursor-pointer"
                  type="button"
                  onClick={() => {
                    // setOpen(true);setHaveCoupon(false)
                    handleCoupon(document.getElementById("coupon").value)
                  }
                  }
                >
                  Apply
                </button>
              </div></>}
             {open &&
              <div className="flex justify-between w-[80%] items-baseline ">
                <p className="text-[#c5bf1b] font-[600] flex items-center"> 
                <svg
                  width="24"
                  height="13"
                  viewBox="0 0 24 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2593 8.33336L12.7513 9.80419L21.6969 0.985441L23.191 2.45836L12.7513 12.75L6.02674 6.12086L7.52085 4.64794L9.76624 6.86148L11.2593 8.33232V8.33336ZM11.2614 5.38753L16.494 0.228149L17.9838 1.6969L12.7513 6.85627L11.2614 5.38753ZM8.27424 11.2781L6.78119 12.75L0.0566406 6.12086L1.55075 4.64794L3.0438 6.11982L3.04275 6.12086L8.27424 11.2781Z"
                    fill="#c5bf1b"
                  />
                </svg>{" "}Coupon applied!</p>
             <div
                class=" mt-5 center relative inline-block select-none whitespace-nowrap rounded-lg bg-gradient-to-tr from-pink-600 to-[#480740] py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white"
                data-dismissible="chip"
              >
               
                <div class="mr-5 mt-px">{coupon_discount_percent}</div>
                <div
                  class="absolute top-1 right-1 mx-px mt-[0.5px] w-max rounded-md bg-pink-800 transition-colors hover:bg-pink-900"
                  data-dismissible-target="chip"
                >
                  <div role="button" onClick={()=>{handleremoveCoupon(1)}} class="h-5 w-5 p-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="3"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              </div>
              }
              <button
                className="bg-[#480740] w-[100%] text-white font-[Poppins] flex justify-around items-center py-2 px-5 rounded-3xl mt-6  hover:bg-pink-800 duration-500 cursor-pointer"
                type="button"
                onClick={() => handlePayment(Data.id, Data.discountId, Math.ceil(Data.price-coupon_discount_amount))}
              >
                Make Payment
              </button>
            </div>
          </div>
        </div>
      </section>
      </>
      }
    </>
    
  );
}
