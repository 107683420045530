import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


import Modal from "../modal/Modal";
import { get } from "../../services/apiHandler";
import Login from "../modal/Login";
import { Audio, Dna } from "react-loader-spinner";

export default function Price(props) {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [Loading, setLoading] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const [isdisable, setIsDisable] = useState(false)
  const [isdisableUpgrade, setIsDisableUpgrade] = useState(false)
  const [isdisableAddpartner, setIsDisableAddpartner] = useState(false)
  // subscription?.current_plan?.plan_id!=2
  // useEffect(()=>{ 
  //  get("api/user_subscription").then((res) => 
  //  {
  //   if (res.data.success) {

  //     setSubscription(res.data.data);
  //   }}),[]})
  useEffect(() => {

    get("api/user").then((res) => {
      setLoading(true)
      if (res.data.success) {

        setSubscription(res.data.data);
        
        // if(props.id==1&&res.data.data?.current_plan?.plan_id!=6)setIsDisable(true);
        let isPremium = false;
        let isStandard = false;
        let isCouple = false;
        let isPartnerGive=false
        // isCouncling=false;
        res.data.data.plans.map((data) => {

          if (data.plan_id == 2||data.plan_data?.parent_id == 2||data.plan_data?.id==9) isPremium = true
          if (data.plan_id == 1) isStandard = true
          if (data.plan_id == 6||data.parent_id == 7) isCouple = true

        })
        
        if(res.data.data.paid_assessment_remaining>0&&res.data.data.plans.length==0){isPremium = true
        isPartnerGive=true
        }
        res.data.data.reports.map((data) => {

          if (data.question_category == 5&&res.data.data.plans.length==0) {
            isPremium=true
            isPartnerGive = true}
         

        })
        
        if (props.id == 1 && isPremium) {
          setIsDisable(true);
          setIsDisableUpgrade(true)

          // setIsDisableUpgrade(true)
        }
        // if(res.data.data.plans.length>0){
        //   isPartnerGive=false
        // }
        if (props.id == 1 && isStandard) {
          setIsDisable(true);



        }
        if (props.id == 1 && isPremium) {
          setIsDisable(true);



        }
        
        if (props.id == 1 &&( res.data.data.plans.length == 0||isPartnerGive)) {



          setIsDisableUpgrade(true)
        }
        if (props.id == 6 && isStandard && !isCouple) {

          setIsDisableAddpartner(true)
        }

        if (props.id == 2 && isPremium) {
          setIsDisable(true);



        }
        if (props.id == 2 && isStandard || isCouple) setIsDisable(true);
        if (props.id == 2 && !isPremium) setIsDisableAddpartner(true);

        if (props.id == 6 && isPremium) {
          setIsDisable(true);
          setIsDisableAddpartner(true)
        }

        if (props.id == 6 && isCouple) setIsDisable(true);
        if (props.id == 6 && res.data.data.plans.length == 0 &&!isPartnerGive) {
          setIsDisableAddpartner(true)
          setIsDisable(true);
        }
        // if (props.id == 6 && res.data.data?.current_plan?.plan_id == 1)  setIsDisableAddpartner(true);
        if (props.id == 8) setIsDisable(false);

        // res.data.data.reports.map((data) => {
        //   if (data.question_category == 5 && data.is_completed) {
        //     setIsDisableUpgrade(false)
        //   }
        // })

      }
      else{
        // document.getElementById("navLogin").click()
        // setLoading(true)
      }
    }).catch(()=>{
    //   document.getElementById("navLogin").click()
    // setLoading(true)
  })
  }, [])
  const choosePlan = (id, discountId, price, discount_amount, preprice, name = "data", PremiumData = null) => {
  let des=[]
    if(id==1){
      des=[
        "One user KnotRiteType (individual assessment)",
       "Qualitative personal assessment on 7 parameters",
      "Recommendation on potential matches",
      ]
     
    }
   else if(id==9){
     des=[
     
        "One user KnotRiteType individual assessments  for your prospective partner .",
        "Qualitative personal assessment report on 7 parameters for partner.",
    "Recommendation on potential matches",
     "KnotRiteCoupleMatch Assessment. This detailed  report will evaluate the extent of Couple compatibility based on the respective KnotRiteTypes(Individual assessment) of both partners  on various parameters."
      
    ]
    }
   else if(id==2){
      des=[
        "Two user KnotRiteType individual assessment both for you and your prospective partner who does not have to pay.",
"Qualitative personal assessment report on 7 parameters for each partner.",
"Recommendation on potential matches",
"	KnotRiteCoupleMatch Assessment. This detailed  report will evaluate the extent of Couple compatibility based on the respective KnotRiteTypes(Individual assessment) of both partners  on various parameters."


    
  ]
    }
   
   else if(id==8){
      des=[
        "This includes an hour-long session  with our experts for a personalized discussion based on your Knotrite reports and the way forward."
       ]
    }
     else{
      des=[
        "This detailed  report will evaluate the extent of Couple compatibility based on the respective KnotRiteTypes(Individual assessment) of both partners  on various parameters."
       ]
    }
    if (JSON.parse(localStorage.getItem("login"))?.login != null) {
      
      navigate('/plansummary', { state: { id, discountId, price, discount_amount, preprice, name, PremiumData,des } })
    }
    else {
      navigate("/login")
    }
  }
  const handleOnClose = () => {
    setShowModal(false)
  }


  return (
    
    <React.Fragment>
      
      <section className="w-full md:block contents">
     
        <div className="shadow-xl md:min-w-[90%] w-[85%] md:max-w-[90%] py-5 px-6 mb-16  border-2 border-[#E8E1E6] flex flex-col items-center">
          {props.discount_amount && <button className="rounded-full  md:min-w-[100px] translate-y-[-39px] w-[unset] bg-[#c5bf1b] text-[14px] md:px-1 px-4 py-1 text-base  font-medium text-white transition duration-200 ">
            {props.discount_amount}% OFF
          </button>}
          <h1 className="font-[700] text-[22px] text-center mb-3">
            {props.type}
          </h1>
          {props.discount_amount && <h3 className="font-[700] text-[#545454] text-[20px] mb-2">
            <strike>₹{props.preprice}</strike>
          </h3>}
          <h2 className={`font-[800] text-[#AC4884]  text-[25px] ${props.id==8?" mb-3 ":" mb-7"}`}>
            ₹{props.actualprice} {props.id==8&&<p className="text-[13px] text-center text-black font-[500] ">&nbsp;per hour</p>}
          </h2>


          {!Loading?<div className=" flex justify-center items-center ">
    <Audio
    visible={true}
    height="40"
    width="80"
    ariaLabel="dna-loading"
    wrapperStyle={{}}
    wrapperClass="dna-wrapper"
  /></div>: <div className=" w-full mb-5 mt-2   relative  flex items-center justify-center  text-sm leading-5">
            
            <span

              className="absolute mt-2 h-[30px] w-full flex items-center justify-center py-6 cursor-pointer"
            >

               <a
              
              class="transititext-primary flex justify-center items-center text-black transition duration-150 ease-in-out hover:text-primary-600 focus:text-red active:text-primary-700 "
              data-te-toggle="tooltip"
              title={isdisable ? "This button is disabled to ensure you do not overpay. Please see note  below" : "Buy Plan"}
            >
                <button className={!isdisable ? "rounded-full mb-3 md:min-w-[230px] w-[unset] bg-[#67365E] text-[14px] md:px-1 px-4 py-1 text-base  font-medium text-white transition duration-200 " : "rounded-full mb-3 md:min-w-[230px] w-[unset] shadow-[rgba(0,0,0,0.16)_0px_1px_4px] bg-[#67365E] text-[14px] md:px-1 px-4 py-1 text-base  font-medium text-[rgb(186,114,172)] transition duration-200 "} disabled={isdisable}
                  onClick={() => choosePlan(props.id, props.discount_id, props.actualprice, props.discount_amount, props.preprice, props.name)}>
                  Buy Now
                </button>
              </a>
            </span>
          </div>}
          {props.des?.map((data, i) => {
            return (
              <div
                key={i}
                className="flex md:items-baseline items-start  w-[100%] mx-4  mb-4"
              >
                <svg
                  width="23"
                  height="13"
                  viewBox="0 0 23 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg "
                  className="min-w-[20px] h-full mr-2"
                >
                  <path
                    d="M11.0857 8.33336L12.5566 9.80419L21.3753 0.985441L22.8482 2.45836L12.5566 12.75L5.92741 6.12086L7.40033 4.64794L9.61387 6.86148L11.0857 8.33232V8.33336ZM11.0878 5.38753L16.2462 0.228149L17.7149 1.6969L12.5566 6.85627L11.0878 5.38753ZM8.14303 11.2781L6.67116 12.75L0.0419922 6.12086L1.51491 4.64794L2.98678 6.11982L2.98574 6.12086L8.14303 11.2781Z"
                    fill="#CD64A3"
                  />
                </svg>

                <p className="text-[13px] text-left">{data}</p>
              </div>
            );
          })}

          {props.addPartner && (
            <>
              <div className="bg-[#f0daf4d0] rounded-lg text-[13px] text-center p-5">
                {props.id==2?"Premium Plus Add Partner":"CoupleMatch Plus Add Partner"}
                <h2 className="font-[800] text-[#AC4884] text-[25px] mb-3">
                  {props.addPartner > 0 && <>₹{props.addPartner}</>}
                </h2>
                {!Loading?<div className=" flex justify-center items-center ">
    <Audio
    visible={true}
    height="40"
    width="80"
    ariaLabel="dna-loading"
    wrapperStyle={{}}
    wrapperClass="dna-wrapper"
  /></div>:
                props.isPremimum ? <button className={!isdisableAddpartner ? "rounded-full mb-3 md:min-w-[230px] w-[unset] bg-[#67365E] text-[14px] md:px-1 px-4 py-1 text-base  font-medium text-white transition duration-200 " : "rounded-full mb-3 md:min-w-[230px] w-[unset] shadow-[rgba(0,0,0,0.16)_0px_1px_4px] bg-[#67365E] text-[14px] md:px-1 px-4 py-1 text-base  font-medium text-[rgb(186,114,172)] transition duration-200 "} disabled={isdisableAddpartner} onClick={() => choosePlan(props.sub_plan_id, "", props.actualprice, "", props.preprice, "Add a partner", props.addPartner)}>
                  Buy Now
                </button> : <button className={!isdisableAddpartner ? "rounded-full mb-3 md:min-w-[230px] w-[unset] bg-[#67365E] text-[14px] md:px-1 px-4 py-1 text-base  font-medium text-white transition duration-200 " : "rounded-full mb-3 md:min-w-[230px] w-[unset] shadow-[rgba(0,0,0,0.16)_0px_1px_4px] bg-[#67365E] text-[14px] md:px-1 px-4 py-1 text-base  font-medium text-[rgb(186,114,172)] transition duration-200 "} onClick={() => choosePlan(props.sub_plan_id, "", props.actualprice, "", props.preprice, "Add a partner")}>
                  Buy Now
                </button>}
              </div>
              {props.id == 2 ? <p className="font-[400] text-[13px] text-justify mt-2 text-[#7B7B7B]">
              Discounted Prices for further potential partners Individual and Couple Compatibility tests. Invited Partners need not be a paid members.
              </p> : <p className="font-[400] text-[13px] text-justify mt-2 text-[#7B7B7B]">Discounted Prices for further potential partners couple compatibility tests . The Invitations can be sent to only paid members who have already completed the KnotRiteType Test(individual assesment) </p>}
            </>
          )}
          {props.upgradePlan && (
            <>
              <div className="bg-[#f0daf4d0] min-w-[200px] rounded-lg text-[13px] text-center p-5">

                <h2 className="font-[800] text-[#AC4884] text-[25px] mb-3">
                  ₹{props.upgradePlan}
                </h2>
                {!Loading?<div className=" flex justify-center items-center ">
    <Audio
    visible={true}
    height="40"
    width="80"
    ariaLabel="dna-loading"
    wrapperStyle={{}}
    wrapperClass="dna-wrapper"
  /></div>:
                <button className={!isdisableUpgrade ? "rounded-full mb-3 md:min-w-[100px] w-[unset] bg-[#67365E] text-[14px] md:px-1 px-4 py-1 text-base  font-medium text-white transition duration-200 " : "rounded-full mb-3 md:min-w-[200px] w-[unset] shadow-[rgba(0,0,0,0.16)_0px_1px_4px] bg-[#67365E] text-[14px] md:px-1 px-4 py-1 text-base  font-medium text-[rgb(186,114,172)] transition duration-200 "} disabled={isdisableUpgrade} onClick={() => choosePlan(9, "", props.upgradePlan, "", null, "Upgrade To Premium")}>
                  Upgrade To Premium
                </button>}
              </div>
              <p className="font-[400] text-[13px] text-center mt-2 text-[#7B7B7B]">You can avail of discounted prices of the premium package for partner evaluations.</p>

            </>
          )}
        </div>
      </section>
      <Login onClose={handleOnClose} visible={showModal} />
          
    </React.Fragment>
  );
}
