import React, { useState } from "react";
import {post, get} from '../../services/apiHandler'
export default function TestPriceModal({ visible, onClose,loadData }) {

  const [result1,setresult1]=useState([{paid:0,couple:0},{paid:1,couple:0},{paid:1,couple:1}])
  const addOnPlan=[{paid:0,couple:0},{paid:1,couple:0},{paid:0,couple:1},{paid:1,couple:1}]
  // const [result2,setresult2]=useState([{paid:0,couple:0},{paid:1,couple:0},{paid:1,couple:1},{paid:0,couple:1}])
const [ActiveIndex,setActiveIndex]=useState(0)
let [subres,setSubres]=useState([{paid:null,couple:null},{paid:null,couple:null}])
const [AddOnIndex,setAddOnIndex]=useState(0)
const [ch,setch]=useState([false,false])
const [AddOnIndex1,setAddOnIndex1]=useState(0)
const [ActiveIndex1,setActiveIndex1]=useState(0)
  if (!visible) return null;
  const handleOnClose = () => {
    onClose();
  };
  async function handleSubmit(event) {
    let ch0=document.getElementById("paid0").value
    let ch1=document.getElementById("paid1").value
    
    event.preventDefault();
    if(ActiveIndex==2&&ActiveIndex1==1){
     
    subres[0]={ paid:result1[ActiveIndex].paid-1,couple: result1[ActiveIndex].couple-1}
    subres[1]={ paid:result1[ActiveIndex1].paid+1,couple: result1[ActiveIndex1].couple}


    }
    else if(ActiveIndex==2&&ActiveIndex1==2){
      subres[0]={ paid:result1[ActiveIndex].paid,couple: result1[ActiveIndex].couple-1}
      subres[1]={ paid:result1[ActiveIndex1].paid,couple: result1[ActiveIndex1].couple}

    }
    else if(ActiveIndex==2&&ActiveIndex1==0){
      subres[0]={ paid:result1[ActiveIndex].paid-1,couple: result1[ActiveIndex].couple-1}
      subres[1]={ paid:result1[ActiveIndex1].paid+1,couple: result1[ActiveIndex1].couple}
    }
    setSubres([...subres])
 
  } 
  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className=" p-2 m-4 rounded md:w-[30%] w-[98%] max-h-full mx-auto bg-gradient-to-t from-[#641662] to-pink-200">
        <button className="float-right mr-2" onClick={handleOnClose}>
          X
        </button>
        <div className="columns flex space-x-52">
          <div className="bg-icon w-[25%] h-12 bg-no-repeat bg-cover  ml-4 mt-4"></div>
        </div>
        <div>
          <h2 className="text-white mt-6 text-[20px] font-[700] text-center justify-center">
                     Test Pricing Plan
          </h2>
          <form className="max-w-full w-full flex flex-col justify-center items-center">
            <div className="flex">
            <div className="text-white my-3 font-[600] min-w-[50%] w-full  text-[13px]  ">
              <label>Partner 1 Pricing Plan</label>
              <br />
              <select name="price"  onChange={e=>setActiveIndex(e.target.value)} className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50">
                <option value="0">Free</option>
                <option value="1">Standard</option>

                <option value="2">Premium</option>
                {/* <option value="3">Couple Match</option> */}
              </select>
            </div>
            
            <div className="text-white my-3 font-[600] w-full ml-5 min-w-[50%] text-[13px]  ">
              <label>Partner 2 Pricing Plan</label>
              <br />
              <select name="price"   onChange={e=>setActiveIndex1(e.target.value)} className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50">
                <option value="0">Free</option>
                <option value="1">Standard</option>

                <option value="2">Premium</option>
                {/* <option value="3">Couple Match</option> */}
              </select>
            </div>
            </div>
            <div className="flex justify-around w-100">
           <div className="text-white my-3 font-[600] w-full ml-10 min-w-[100%] max-w-[100px] text-[13px]  ">
           {ActiveIndex==2&&<><label>Partner 1 Add On Plan</label>
              <br />
              <select name="price"  onChange={e=>setAddOnIndex(e.target.value)} className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50">
              <option value="0">Select</option>
                <option value="1">onlyAssesment</option>
                <option value="2">couple Test</option>

                <option value="3">Both</option>
               
              </select></> }
            </div>
            
            <div className="text-white my-3 font-[600] min-w-[100%] max-w-[100px] w-full  text-[13px]  ">
             {ActiveIndex1==2&& <>
              <label>Partner 2 Add On Plan</label>
              <br />
              <select name="price"   onChange={e=>setAddOnIndex1(e.target.value)} className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50">
              <option value="0">Select</option>
                <option value="1">onlyAssesment</option>
                <option value="2">couple Test</option>

                <option value="3">Both</option>
              </select>
              </>}
            </div>
            </div>
            
            <div className="flex">
            <div className="text-white my-3 font-[600] w-full ml-10 text-[13px]  ">
                <div className="flex">
                    <div>
              <label>Paid credit</label>
              <br />
              <input
                type="text"
                placeholder="Enter Your Partner's Name "
                name="text"
                value={ActiveIndex==2?result1[ActiveIndex].paid+addOnPlan[AddOnIndex].paid:result1[ActiveIndex].paid}
              
                disabled
                className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
               </div>
               <div>
               <label>Couple credit</label>
              <br />
             
              <input
                type="text"
                placeholder="Enter Your Partner's Name "
                name="text"
                value={ActiveIndex==2?result1[ActiveIndex].couple+addOnPlan[AddOnIndex].couple:result1[ActiveIndex].couple}
               
                disabled
                className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
              </div>
              </div>
              <input type="checkbox" className="mt-4 p-5 w-10" name="paid0" id="paid0" onChange={()=>{
              ch[0]=!ch[0];
              setch([...ch])
            }} />Already Given paid Test
            </div>
            <div className="text-white my-3 font-[600] w-full ml-10 text-[13px]  ">
            <div className="flex">
            <div className="text-white font-[600] w-full  text-[13px]  ">
                <div className="flex">
                    <div>
              <label>Paid credit</label>
              <br />
              <input
                type="text"
                placeholder="Enter Your Partner's Name "
                name="text"
                value={ActiveIndex1==2?result1[ActiveIndex1].paid+addOnPlan[AddOnIndex1].paid:result1[ActiveIndex1].paid}
                disabled
                className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
               </div>
               <div>
               <label>Couple credit</label>
              <br />
             
              <input
                type="text"
                placeholder="Enter Your Partner's Name "
                name="text"
                value={ActiveIndex1==2?result1[ActiveIndex1].couple+addOnPlan[AddOnIndex1].couple:result1[ActiveIndex1].couple}
                disabled
                className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
              </div>
              </div>
            </div>
           
            </div>
            <input type="checkbox" className="mt-4 p-5 w-10" name="paid1" id="paid1" onChange={()=>{
              ch[1]=!ch[1];
              setch([...ch])
            }} />Already Given paid Test
            </div>
            </div>
           
            <div className="mb-3  mt-7 w-full ml-10">
              <button onClick={(e)=>handleSubmit(e)} className="rounded-full  bg-[#CD64A3] w-[85%] font-[700] text-[12px] px-5 py-2    text-white transition duration-200  hover:bg-[#9c4c7c]">
                Submit
              </button>
            </div>
          
          
            
            <div className="flex">
            <div className="text-white my-3 font-[600] w-full ml-10 text-[13px]  ">
                <div className="flex">
                    <div>
              <label>Paid credit</label>
              <br />
              <input
                type="text"
                placeholder="Enter Your Partner's Name "
                name="text"
                value={subres[0].paid}
              
                disabled
                className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
               </div>
               <div>
               <label>Couple credit</label>
              <br />
             
              <input
                type="text"
                placeholder="Enter Your Partner's Name "
                name="text"
                value={subres[0].couple}
               
                disabled
                className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
              </div>
              
           
           </div></div>
           <div className="text-white my-3 font-[600] w-full ml-10 text-[13px]  ">
                <div className="flex">
                    <div>
              <label>Paid credit</label>
              <br />
              <input
                type="text"
                placeholder="Enter Your Partner's Name "
                name="text"
                value={subres[1].paid}
              
                disabled
                className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
               </div>
               <div>
               <label>Couple credit</label>
              <br />
             
              <input
                type="text"
                placeholder="Enter Your Partner's Name "
                name="text"
                value={subres[1].couple}
               
                disabled
                className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
              </div>
              
           
           </div></div>
           
           </div>
            {/* {resultData?.reportId && 
            <div className="text-white my-3 font-[600] w-full ml-10 text-[13px]  ">
              <label>Report Id</label>
              <br />
              <input
                type="text"
                placeholder=" "
                name="result"
                value={resultData.reportId}
               disabled
                className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
            </div>} */}
          </form>
        </div>
      </div>
    </div>
  );
}
