import React, { useState } from "react";
import {post, get} from '../../services/apiHandler'
export default function TestModal({ visible, onClose,loadData }) {
  const [data, setData] = useState({partner1_jung_score:null,partner2_jung_score:null,partner1_neuroticism:null,partner2_neuroticism:null,});
  const [resultData, setResultData] = useState({reportId:'',score:''});


  if (!visible) return null;
  const handleOnClose = () => {
    onClose();
  };
  async function handleSubmit(event) {
    
    event.preventDefault();
    try{
      const res = await get(`api/test_couple?partner1_jung_score=${data.partner1_jung_score}&partner2_jung_score=${data.partner2_jung_score}&
      partner1_neuroticism=${data.partner1_neuroticism}&partner2_neuroticism=${data.partner2_neuroticism}&`)
      if(res.data.success){
        setResultData({reportId:res.data.data.report_id,score:res.data.data.score})
      }
      }catch(e){
        
      }
  } 
  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className=" p-2 m-4 rounded md:w-[30%] w-[98%] max-h-full mx-auto bg-gradient-to-t from-[#641662] to-pink-200">
        <button className="float-right mr-2" onClick={handleOnClose}>
          X
        </button>
        <div className="columns flex space-x-52">
          <div className="bg-icon w-[25%] h-12 bg-no-repeat bg-cover  ml-4 mt-4"></div>
        </div>
        <div>
          <h2 className="text-white mt-6 text-[20px] font-[700] text-center justify-center">
            Test Couple Match
          </h2>
          <form className="max-w-full w-full flex flex-col justify-center items-center">
            <div className="flex">
            <div className="text-white my-3 font-[600] w-full ml-10 text-[13px]  ">
              <label>Partner 1 Jung Score</label>
              <br />
              <input
                type="text"
                placeholder="Enter Your Partner's Name "
                name="text"
                value={data.partner1_jung_score}
                onChange={(e) => setData({...data,partner1_jung_score:e.target.value})}
                className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
            </div>
            <div className="text-white my-3 font-[600] w-full ml-10 text-[13px]  ">
              <label>Partner 2 Jung Score</label>
              <br />
              <input
                type="text"
                placeholder="Enter Your Partner's Name "
                name="text"
                value={data.partner2_jung_score}
                onChange={(e) => setData({...data,partner2_jung_score:e.target.value})}
                className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
            </div>
            </div>
            <div className="flex">
            <div className="text-white my-3 font-[600] w-full ml-10 text-[13px]  ">
              <label>Partner 1 Neurotism</label>
              <br />
              <input
                type="text"
                placeholder="Enter Your Partner's Name "
                name="text"
                value={data.partner1_neuroticism}
                onChange={(e) => setData({...data,partner1_neuroticism:e.target.value})}
                className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
            </div>
            <div className="text-white my-3 font-[600] w-full ml-10 text-[13px]  ">
              <label>Partner 2 Neurotism</label>
              <br />
              <input
                type="text"
                placeholder="Enter Your Partner's Name "
                name="text"
                value={data.partner2_neuroticism}
                onChange={(e) => setData({...data,partner2_neuroticism:e.target.value})}
                className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
            </div>
            </div>
           
            <div className="mb-3  mt-7 w-full ml-10">
              <button onClick={(e)=>handleSubmit(e)} className="rounded-full  bg-[#CD64A3] w-[85%] font-[700] text-[12px] px-5 py-2    text-white transition duration-200  hover:bg-[#9c4c7c]">
                Submit
              </button>
            </div>
            {resultData?.score && 
            <div className="text-white my-3 font-[600] w-full ml-10 text-[13px]  ">
              <label>Score</label>
              <br />
              <input
                type="text"
                placeholder=" "
                name="result"
                value={resultData.score}
               disabled
                className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
            </div>}
            {resultData?.reportId && 
            <div className="text-white my-3 font-[600] w-full ml-10 text-[13px]  ">
              <label>Report Id</label>
              <br />
              <input
                type="text"
                placeholder=" "
                name="result"
                value={resultData.reportId}
               disabled
                className="mt-2  text-black flex h-10  items-center rounded-md justify-center border p-2 w-[85%] text-[17px] font-[400] outline-none border-[#EFD0E2]  placeholder:text-[#EFD0E2]  bg-slate-50"
              />
            </div>}
          </form>
        </div>
      </div>
    </div>
  );
}
