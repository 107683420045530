import React, { useEffect, useState } from "react";

// import './Style.css'


import Modal from "../modal/Modal";
import { get, post } from "../../services/apiHandler";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Dna } from "react-loader-spinner";

export default function PaidForm() {
  const [showModal, setShowModal] = useState(false);
const nav=useNavigate()
  let [progressCount, setprogressCount] = useState(0);
  const [answersData, setAnswersData] = useState([]);
  const [subBtn, setsubBtn] = useState(false);
  const [navstate,setNav]=useState(false)
  const [Score, setScore] = useState(null);
  const [ErrBtn, setErrBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [testStatus,setTestStatus]=useState('Pause')
  const [ErrMsg, setErrMsg] = useState("Please Tick All Questions");
  let [qno, setQueno] = useState(Array(84).fill(0));
  let [opans, setopans] = useState({});
  const navigate=useNavigate()
  const {state}=useLocation()


  const handleOnClose = () => {
    navigate("/myaccount")
    setShowModal(false);}
  const handleChange = (question, category, answer, option_id) => {
    
    
    if (qno[answer] == 0) {
      qno[answer] = 1;
      setprogressCount(progressCount + 1);
    }
  
    
    let data = {
      question_category: category,
      question: question,
      answers: answer,
      question_no: parseInt(answer) + 1,
      option_id: option_id,
    };
    const index = answersData.findIndex(
      (item) => item.question == data.question
    );
    if (index !== -1) {
      answersData[index] = data;
    } else {
      answersData.push(data);
    }
  };

  const [ques, setQues] = useState([]);

  const submitAnswers = async() => {
    // if (localStorage.getItem("login") != null) {
      // const a = JSON.parse(localStorage.getItem("login"));
      // const config = {
      //   headers: {
      //     "content-type": "text/json",
      //     Authorization: "Bearer " + a.token,
      //   },
      // };
      if(loading==true)return
      setLoading(true)
      setErrMsg("")
 await pauseFun(false)
    

      
      post("api/submit_assessment")
        .then((res) => {
          
          setScore(`Report Score is :  ${res.data.report_id}`)
          setErrMsg(
            res.data.message
          );
          setNav(true)
          setLoading(false)
          setErrBtn(true)
        })
        .catch((e) => {
          setLoading(false) 
          setErrMsg("Action Failed. Score is : " + e.response.data.report_id);
          setErrBtn(true)
        });
      
    // } else {
    
    //   setShowModal(true);
    // }
  };


  let options = (key, qId, qCatId, data) => {
    let option_arr = [];
    for (let i = 0; i < data.questions_options.length; i++) {
     
      
      option_arr.push(
        <div
          key={i}
          className="flex flex-col flex-wrap justify-center items-start mb-2 mr-0 sm:mr-4"
        >
          <div className="one flex justify-center items-baseline md:items-center">
            <input
              type="radio"
              name={key}
              checked={opans[qId]==data.questions_options[i].id}
              onChange={(e) =>
              
             {  

                handleChange(
                  qId,
                  qCatId,
                  e.target.name,
                  data.questions_options[i].id,
                 
                )
                setopans({...opans,[qId]:data.questions_options[i].id})
               
              }
              }
              value={i}
              id={1}
              className={
                "form-check-input md:translate-y-[0px] translate-y-[4px] form-check-input  appearance-none rounded-full h-[15px] min-w-[15px] sm:h-[20px]  sm:w-[20px] border-2 border-[#521350]  bg-white checked:bg-[#521350] checked:border-[#521350] focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain  2xl:rounded-full"
              }
            />

            <span className="text-left ml-4">
              <span className="text-left sm:text-[0.875rem]">
                {data.questions_options[i].option}
              </span>
            </span>
          </div>
        </div>
      );
    }
    return option_arr;
  };
const Resume=async(questions)=>{
  let apiopans ={}
  const data=await get("api/resume_assessment")

 const rsdata=data.data.data
 rsdata.map((data)=>{
  apiopans[data.question]=data.option_id
  let index=0;
questions.forEach((qdata,i)=>qdata.id==data.question?index=i:'')
qno[index]=1;

  
    
  })

  setQueno([...qno])
  
  setopans({...apiopans})
  setprogressCount(rsdata.length)
}
  const fetchData = () => {
    get(
      "api/get_questions/5"
    ).then((res) => {
      let questions = res.data.data;
      setQues(questions);
      Resume(questions)
      
    });
    
   
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [page, setPage] = useState(0);

  const FormTitles = ["1", "2", 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const PageDisplay = () => {
    if (page === 0) {
      const OneArray = ques.slice(0, 12);

      return (
        <div className="App  ">
          <section className=" w-full  py-10 bg-white lg:py-24">
            <div className=" w-full  px-4 mx-auto sm:px-6 lg:px-8">
              <div className=" items-center flex-col flex justify-center">
                {OneArray?.map((item, key) => (
                  <div
                    className=" min-w-[100%] sm:min-w-[80%] py-5 shadow-xl border boder-slate-300 px-5  
                            md:py-5 md:shadow-xl md:border 
                           
                           
                             sm:border sm:border-slate-300 sm:py-8  sm:mb-3
                            flex flex-col justify-center
                           lg:ml-0"
                    key={key}
                  >
                    <label className="mb-8 sm:text-[0.875rem] 2xl:text-base">
                      <b className="text-pink-800 sm:text-[0.875rem]">
                        {`${key + 1}. ${item.question}`}
                      </b>
                    </label>
                    <div className="flex flex-col ml-4">
                      {options(key, item.id, item.question_category, item)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      );
    } else if (page === 1) {
      const TwoArray = ques.slice(12, 24);

      return (
        <div className="App  ">
          <section className=" w-full  py-10 bg-white  lg:py-24">
            <div className=" w-full  px-4 mx-auto sm:px-6 lg:px-8">
              <div className=" mt-1   items-center flex-col flex justify-center">
                {TwoArray?.map((item, key) => (
                  <div
                    className=" min-w-[100%] sm:min-w-[80%] py-5 shadow-xl border boder-slate-300 px-5  
                            md:py-5 md:shadow-xl md:border 
                           
                           
                             sm:border sm:border-slate-300 sm:py-8  sm:mb-3
                            flex flex-col justify-center
                           lg:ml-0"
                    key={key + 12}
                  >
                    <label className="mb-8 sm:text-[0.875rem] 2xl:text-base">
                      <b className="text-pink-800 sm:text-[0.875rem]">
                        {`${key + 13}. ${item.question}`}
                      </b>
                    </label>
                    <div className="flex flex-col ml-4">
                      {options(key + 12, item.id, item.question_category, item)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      );
    } else if (page === 2) {
      const ThreeArray = ques.slice(24, 36);

      return (
        <div className="App  ">
          <section className=" w-full  py-10 bg-white sm:py-16 lg:py-24">
            <div className=" w-full  px-4 mx-auto sm:px-6 lg:px-8">
              <div className=" mt-12 sm:mt-16   items-center flex-col flex justify-center">
                {ThreeArray?.map((item, key) => (
                  <div
                    className=" min-w-[100%] sm:min-w-[80%] py-5 shadow-xl border boder-slate-300 px-5  
                    md:py-5 md:shadow-xl md:border 
                   
                   
                     sm:border sm:border-slate-300 sm:py-8  sm:mb-3
                    flex flex-col justify-center
                   lg:ml-0"
                    key={key + 24}
                  >
                    <label className="mb-8 sm:text-[0.875rem] 2xl:text-base">
                      <b className="text-pink-800 sm:text-[0.875rem]">
                        {`${key + 25}. ${item.question}`}
                      </b>
                    </label>
                    <div className="flex flex-col ml-4">
                      {options(key + 24, item.id, item.question_category, item)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      );
    } else if (page === 3) {
      const ThreeArray = ques.slice(36, 48);

      return (
        <div className="App  ">
          <section className=" w-full  py-10 bg-white sm:py-16 lg:py-24">
            <div className=" w-full  px-4 mx-auto sm:px-6 lg:px-8">
              <div className=" mt-12 sm:mt-16   items-center flex-col flex justify-center">
                {ThreeArray?.map((item, key) => (
                  <div
                    className="min-w-[100%] sm:min-w-[80%] py-5 shadow-xl border boder-slate-300 px-5  
                    md:py-5 md:shadow-xl md:border 
                   
                   
                     sm:border sm:border-slate-300 sm:py-8  sm:mb-3
                    flex flex-col justify-center
                   lg:ml-0"
                    key={key + 36}
                  >
                    <label className="mb-8 sm:text-[0.875rem] 2xl:text-base">
                      <b className="text-pink-800 sm:text-[0.875rem]">
                        {`${key + 37}. ${item.question}`}
                      </b>
                    </label>
                    <div className="flex flex-col ml-4">
                      {options(key + 36, item.id, item.question_category, item)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      );
    } else if (page === 4) {
      const ThreeArray = ques.slice(48, 60);

      return (
        <div className="App  ">
          <section className=" w-full  py-10 bg-white sm:py-16 lg:py-24">
            <div className=" w-full  px-4 mx-auto sm:px-6 lg:px-8">
              <div className=" mt-12 sm:mt-16   items-center flex-col flex justify-center">
                {ThreeArray?.map((item, key) => (
                  <div
                    className=" min-w-[100%] sm:min-w-[80%] py-5 shadow-xl border boder-slate-300 px-5  
                    md:py-5 md:shadow-xl md:border 
                   
                   
                     sm:border sm:border-slate-300 sm:py-8  sm:mb-3
                    flex flex-col justify-center
                   lg:ml-0"
                    key={key + 48}
                  >
                    <label className="mb-8 sm:text-[0.875rem] 2xl:text-base">
                      <b className="text-pink-800 sm:text-[0.875rem]">
                        {`${key + 49}. ${item.question}`}
                      </b>
                    </label>
                    <div className="flex flex-col ml-4">
                      {options(key + 48, item.id, item.question_category, item)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      );
    } else if (page === 5) {
      const ThreeArray = ques.slice(60, 72);

      return (
        <div className="App  ">
          <section className=" w-full  py-10 bg-white sm:py-16 lg:py-24">
            <div className=" w-full  px-4 mx-auto sm:px-6 lg:px-8">
              <div className=" mt-12 sm:mt-16   items-center flex-col flex justify-center">
                {ThreeArray?.map((item, key) => (
                  <div
                    className="min-w-[100%] sm:min-w-[80%] py-5 shadow-xl border boder-slate-300 px-5  
                    md:py-5 md:shadow-xl md:border 
                   
                   
                     sm:border sm:border-slate-300 sm:py-8  sm:mb-3
                    flex flex-col justify-center
                   lg:ml-0"
                    key={key + 60}
                  >
                    <label className="mb-8 sm:text-[0.875rem] 2xl:text-base">
                      <b className="text-pink-800 sm:text-[0.875rem]">
                        {`${key + 61}. ${item.question}`}
                      </b>
                    </label>
                    <div className="flex flex-col ml-4">
                      {options(key + 60, item.id, item.question_category, item)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      );
    } else if (page === 6) {
      const ThreeArray = ques.slice(72, 84);

      return (
        <div className="App  ">
          <section className=" w-full  py-10 bg-white sm:py-16 lg:py-24">
            <div className=" w-full  px-4 mx-auto sm:px-6 lg:px-8">
              <div className=" mt-12 sm:mt-16   items-center flex-col flex justify-center">
                {ThreeArray?.map((item, key) => (
                  <div
                    className=" min-w-[100%] sm:min-w-[80%] py-5 shadow-xl border boder-slate-300 px-5  
                    md:py-5 md:shadow-xl md:border 
                   
                   
                     sm:border sm:border-slate-300 sm:py-8  sm:mb-3
                    flex flex-col justify-center
                   lg:ml-0"
                    key={key + 72}
                  >
                    <label className="mb-8 sm:text-[0.875rem] 2xl:text-base">
                      <b className="text-pink-800 sm:text-[0.875rem]">
                        {`${key + 73}. ${item.question}`}
                      </b>
                    </label>
                    <div className="flex flex-col ml-4">
                      {options(key + 72, item.id, item.question_category, item)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      );
    }
   
  };
  const pauseFun=async(e)=>{
    if(testStatus=="Pause")
   { const answer=answersData.map((data)=>{
  return{
    question_category:data.question_category,
    question:data.question,
    question_no: data.question_no,
    option_id:data.option_id
  }
    })
   const data=await post("api/pause_assessment",answer)
   if(data.data.success&&e)
   toast.success('Report Paused', {
    position: toast.POSITION.TOP_RIGHT
  });
  
  }
  // else{
  //   const data=await get("api/resume_assessment")

  
  //   setTestStatus("Pause")
  //   let apiopans ={}
  // data.data.data.map((data)=>{
  //   apiopans[data.question]=data.option_id
      
  //   })
  //   setopans({...apiopans})
  //   setprogressCount(data.data.data.length)
  // }
    
   }
  return (
    <React.Fragment>
       { ques.length===0?<div className=" flex justify-center items-center ">
    <Dna
    visible={true}
    height="80"
    width="80"
    ariaLabel="dna-loading"
    wrapperStyle={{}}
    wrapperClass="dna-wrapper"
  /></div>:
      <div
        id="progress"
        className="h-fit mt-12 text-black mb-5 flex  align-center flex-col"
      >
        <div className=" flex md:justify-center items-center z-[2]  sticky top-[75px] md:top-[90px]">
          <div
            className="flex justify-center items-center
               w-[76%]  bg-[#f4d9f4] rounded py-2 iteams-center"
          >
            <div className="progressbar ">
              <div
                style={{
                  width:
                    progressCount === 0
                      ? "0%"
                      : progressCount === 1
                      ? "1%"
                      : progressCount === 2
                      ? "2%"
                      : progressCount === 3
                      ? "3.5%"
                      : progressCount === 4
                      ? "7%"
                      : progressCount === 5
                      ? "8%"
                      : progressCount === 6
                      ? "9%"
                      : progressCount === 7
                      ? "10%"
                      : progressCount === 8
                      ? "11%"
                      : progressCount === 9
                      ? "12%"
                      : progressCount === 10
                      ? "13%"
                      : progressCount === 11
                      ? "14%"
                      : progressCount === 12
                      ? "15%"
                      : progressCount === 13
                      ? "16%"
                      : progressCount === 14
                      ? "17%"
                      : progressCount === 15
                      ? "18%"
                      : progressCount === 16
                      ? "19%"
                      : progressCount === 17
                      ? "20%"
                      : progressCount === 18
                      ? "21%"
                      : progressCount === 19
                      ? "22%"
                      : progressCount === 20
                      ? "23%"
                      : progressCount === 21
                      ? "24.5%"
                      : progressCount === 22
                      ? "25.5%"
                      : progressCount === 23
                      ? "27%"
                      : progressCount === 24
                      ? "28.5%"
                      : progressCount === 25
                      ? "30%"
                      : progressCount === 26
                      ? "31.5%"
                      : progressCount === 27
                      ? "33%"
                      : progressCount === 28
                      ? "34.5%"
                      : progressCount === 29
                      ? "35%"
                      : progressCount === 40
                      ? "36%"
                      : progressCount === 31
                      ? "37%"
                      : progressCount === 32
                      ? "38%"
                      : progressCount === 33
                      ? "39%"
                      : progressCount === 34
                      ? "40%"
                      : progressCount === 35
                      ? "41%"
                      : progressCount === 36
                      ? "42%"
                      : progressCount === 37
                      ? "43%"
                      : progressCount === 38
                      ? "44%"
                      : progressCount === 39
                      ? "45%"
                      : progressCount === 40
                      ? "46%"
                      : progressCount === 41
                      ? "47%"
                      : progressCount === 42
                      ? "48%"
                      : progressCount === 43
                      ? "49%"
                      : progressCount === 44
                      ? "50%"
                      : progressCount === 45
                      ? "51%"
                      : progressCount === 46
                      ? "52%"
                      : progressCount === 47
                      ? "53%"
                      : progressCount === 48
                      ? "54%"
                      : progressCount === 49
                      ? "55%"
                      : progressCount === 50
                      ? "56%"
                      : progressCount === 51
                      ? "57%"
                      : progressCount === 52
                      ? "58%"
                      : progressCount === 53
                      ? "59%"
                      : progressCount === 54
                      ? "59%"
                      : progressCount === 55
                      ? "60%"
                      : progressCount === 56
                      ? "61%"
                      : progressCount === 57
                      ? "62%"
                      : progressCount === 58
                      ? "63%"
                      : progressCount === 59
                      ? "64%"
                      : progressCount === 60
                      ? "65%"
                      : progressCount === 61
                      ? "66%"
                      : progressCount === 62
                      ? "67%"
                      : progressCount === 63
                      ? "68%"
                      : progressCount === 64
                      ? "69%"
                      : progressCount === 65
                      ? "70%"
                      : progressCount === 66
                      ? "73%"
                      : progressCount === 67
                      ? "74%"
                      : progressCount === 68
                      ? "75%"
                      : progressCount === 69
                      ? "76%"
                      : progressCount === 70
                      ? "78%"
                      : progressCount === 71
                      ? "80%"
                      : progressCount === 72
                      ? "82%"
                      : progressCount === 73
                      ? "84%"
                      : progressCount === 74
                      ? "86%"
                      : progressCount === 75
                      ? "87%"
                      : progressCount === 76
                      ? "89%"
                      : progressCount === 77
                      ? "92%"
                      : progressCount === 78
                      ? "93%"
                      : progressCount === 79
                      ? "94%"
                      : progressCount === 80
                      ? "95%"
                      : progressCount === 81
                      ? "96%"
                      : progressCount === 82
                      ? "98%"
                      : progressCount === 83
                      ? "99%"
                      : progressCount === 84
                      ? "100%"
                      : "100%",
                }}
              >
              </div>
             
            </div>
            <h1 className="pr-2">{progressCount}/84</h1>
          </div>
          
          <button className="absolute right-0 md:left-[90%] text-white hover:bg-[#944294] bg-[#852b85] rounded-md px-5 py-2" onClick={()=>pauseFun(true)}>{testStatus}</button>
             
        </div>
        <div className="justify-around mt-1 mb-1">{PageDisplay()}</div>
        <div className=" flex justify-evenly ">
          <button
            disabled={page === 0}
            onClick={() => {
              document.getElementById("go").click();
              setPage((currPage) => currPage - 1);
            }}
            className="bg-white text-slate-400 uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer 
                 border-2 border-slate-300 hover:bg-slate-700 
                hover:text-white transition duration-200 ease-in-out"
          >
            Back
          </button>
          <a href="#progress" className="none" id="go"></a>
          {page < 6 ? (
            <button
              disabled={page === FormTitles.length - 1}
              onClick={() => {
                
                if((page+1)*12>progressCount)
               
                {setErrMsg("Please Tick All Questions")
                setErrBtn(true)
              return
              }
                document.getElementById("go").click();
                setPage((currPage) => currPage + 1);
              }}
              className="bg-[#480740] text-white uppercase py-2 px-4 rounded-xl 
                 font-semibold cursor-pointer sm:rounded-xl sm:text-white sm:py-2 sm:px-4  sm:border-2 sm:border-slate-300
                  border-2 border-slate-300 hover:bg-[#480740c6]  sm:tranition sm:duration-200 sm:ease-in-out  
                hover:text-white transition duration-200 ease-in-out"
            >
              Next
            </button>
          ) : (
            <p
              onClick={() => {
                if (progressCount >= 84)  submitAnswers();
                else { {setErrMsg("Please Tick All Questions")
                setErrBtn(true)}}
              }}
              className="bg-[#480740] flex gap-x-4 text-white uppercase py-2 px-4 rounded-xl 
              font-semibold cursor-pointer sm:rounded-xl sm:text-white sm:py-2 sm:px-4  sm:border-2 sm:border-slate-300 
               border-2 border-slate-300  hover:bg-[#480740c6]  sm:tranition sm:duration-200 sm:ease-in-out  
             hover:text-white transition duration-200 ease-in-out"
            >
              submit {loading&&<div class="w-4 h-4 border-2  border-dashed rounded-full animate-spin border-[white]"></div>}
            </p>
          )}
        </div>
      </div>}

      <Modal onClose={handleOnClose} visible={showModal} />
      {subBtn && (
        <div class="fixed z-10 inset-0 overflow-y-auto">
          <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity">
              <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div
              class={
                "inline-block align-bottom rounded-lg bg-gradient-to-t from-[#641662] to-pink-200  text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              }
            >
              <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      class="h-6 w-6 text-green-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div class="mt-3  sm:mt-0 sm:ml-4 sm:text-left ">
                    <h3 class="text-lg leading-6 font-medium text-white">
                    Dear {JSON.parse(localStorage.getItem("login")).fullname},
                    </h3>
                    <p class="text-sm text-white">
                      You have successfully completed the free KnotRiteReadiness
                      test. The score below indicates your current readiness for
                      marriage or relationship.
                    </p>
                    <p class="text-sm my-2 text-white text-[15px]">{Score}</p>
                    <div class="mt-4">
                      {/* <div className="text-center">
                        <p> KnotRiteReadiness Score : HIGH</p>
                      </div> */}
                      <div className="mt-3">
                        <p className="text-sm text-white">
                          {" "}
                          {"         "}
                          
                          For detailed assessment report describing your
                          specific attributes, we request you to kindly signup
                          with your basic details.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={() => {
                    setsubBtn(false);
                    submitAnswers();
                  }}
                  class=" mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-[0.875rem]"
                >
                  {localStorage.getItem("login") != null
                    ? "Send Email"
                    : " Sign Up"}
                </button>
                <button
                  type="button"
                  onClick={() => {
                   
                    setsubBtn(false);

                  }}
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  sm:ml-3 sm:w-auto sm:text-[0.875rem]"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {ErrBtn && (
        <div class="fixed z-10 inset-0 overflow-y-auto">
          <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
           

            <div
              class={
                "inline-block align-bottom bg-gradient-to-t from-[#641662] to-pink-200 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              }
            >
               <div class=" relative inset-0 w-full transition-opacity">
              <div class=" absolute right-0 top-2 text-white mr-4 font-[700] cursor-pointer scale-125  opacity-75" onClick={()=>setErrBtn(false)}>X</div>
            </div>
              <div class=" px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                 {navstate&& <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      class="h-6 w-6 text-green-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>}
                  <div class="mt-3  sm:mt-0 sm:ml-4 sm:text-left text-white">
                    <h3>{ErrMsg}</h3>
                  </div>
                </div>
              </div>
              <div class=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
               {ErrMsg!="Please Tick All Questions"&& <button
                  type="button"
                  onClick={() => {
                    navstate&&nav("/myaccount")
                    setErrBtn(false);
                  }}
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  sm:ml-3 sm:w-auto sm:text-[0.875rem]"
                >
                  Go To My Account
                </button>}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
