import * as Yup from "yup"
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const AddPartnerSchema=Yup.object({
    name:Yup.string().min(2).max(25).required("Please Enter Your Partner's Name"),
    email:Yup.string().email().required("Please Enter Your Partner's Email"),
    // name:Yup.string().min(8).required("Please Enter Your Password"),
    mobile_number:Yup.string().min(10,"Phone number is not valid").max(10,"Phone number is not valid").matches(phoneRegExp, 'Phone number is not valid').required("Please Enter Your Partner's Mobile Number"),

})
export const LoginSchema=Yup.object({
  
    name:Yup.string().min(2).max(25).required("Please Enter Your Name"),
    surname:Yup.string().min(2).max(25).required("Please Enter Your surname"),
   
    // name:Yup.string().min(8).required("Please Enter Your Password"),
    mobile_number:Yup.string().min(10,"Phone number is not valid").max(10,"Phone number is not valid").matches(phoneRegExp, 'Phone number is not valid').required("Please Enter Your Mobile Number"),
    email:Yup.string().email().required("Please Enter Your Email"),
    password: Yup
    .string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
})
export const SignupSchema = Yup.object().shape({
  name:Yup.string().min(2).max(25).required("Please Enter Your Name"),
  surname:Yup.string().min(2).max(25).required("Please Enter Your surname"),
 
  // name:Yup.string().min(8).required("Please Enter Your Password"),
  mobile_number:Yup.string().min(10,"Phone number is not valid").max(10,"Phone number is not valid").matches(phoneRegExp, 'Phone number is not valid').required("Please Enter Your Mobile Number"),
  email:Yup.string().email().required("Please Enter Your Email"),
  password: Yup
  .string()
  .required('Please Enter your password')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  ),
  // dob: Yup.date()
  //   .max(new Date(), 'Date cannot be in the future')
  //   .required('Date is required!'),
});
export const Contact = Yup.object().shape({
  name:Yup.string().min(2).max(25).required("Please Enter Your Name"),
  subject:Yup.string().min(2).required("Please Enter Some Subject"),
 
  message:Yup.string().min(2).required("Please message"),
 
  // name:Yup.string().min(8).required("Please Enter Your Password"),
  // mobile_number:Yup.string().min(10,"Phone number is not valid").max(10,"Phone number is not valid").matches(phoneRegExp, 'Phone number is not valid').required("Please Enter Your Mobile Number"),
  email:Yup.string().email().required("Please Enter Your Email"),
 
});