import React from "react";
import Form from "../form/Form";
import "./../Style.css";
import TestHeading from "../test/testHeading";

const Instruct = () => {
  return (
    <React.Fragment>
      <div className=" h-fit bg-white  text-black bg-fixed w-full ">
        <div className="md:text-center md:scroll-px-10 md:mx-40 m-5   ">
          <TestHeading heading="Test  Instructions" />
          <p
            className=" text-[13px] md:text-justify 
                "
          >
            {" "}
            Hey, you have taken the first step to Happiness! <br />

            Curious to know your likely attitude and behaviour in a relationship? Get to know  your comfort levels with intimacy and other interesting and important  aspects . This quick assessment will be worth your time - It’s based on proven scientific principle - Attachment Style Theory .
          </p>
          <br />
         
        </div>
        <div className=" m-5 md:mx-40 md:mt-10 text-[13px] md:text-justify  md:my-5 sm:mx-5  justify-left 2xl:mt-0">
          <div className="flex mb-5">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>Each question has can have seven alternative responses ranging from <b>strongly agree</b> (extreme left button) to strongly disagree (extreme right button). You can choose any of the seven buttons which you feel best answers the question. </p>
          </div>
          <div className="mb-5 flex">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>
            While taking this assessment, please <b>DO NOT </b>think of <b>work</b>, being a <b>student</b>, or any other prescribed role but rather your <b>natural preferences</b> in a romantic relationship.  
            </p>
          </div>
          <div className="mb-5 flex">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>
           <b>Don’t focus </b> on what your partner might want, pay attention to <b>what you want now </b>and answer the questions accordingly. 
            </p>
          </div>
          <div className="mb-5 flex">
            <input
              type="checkbox"
              checked="checked"
              value="true"
              className="form-checkbox mt-1 border-black rounded-full mr-5 checked:bg-[#641662] checked:border-transparent"
            />
            <p>
            For any other queries, ping on the chat icon alongside.
            </p>
          </div>
          
          
          <Form />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Instruct;
