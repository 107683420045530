import inr1 from "../asset/images/inr1.png";
import inr2 from "../asset/images/inr2.png";
import inr4 from "../asset/images/inr4.jpg";
import cmr3 from "../asset/images/inrmeter.png";
import cmrmt from "../asset/images/cmr-mt.png";
import inr3 from "../asset/images/inr3.jpg";
import knotmeter from "../asset/images/knotmeter.png";
import Content from "../components/reports/filledColorHeading";
import finalFlag from "../asset/images/finalFlag.jpg";
export default function IndividualReport() {
  return (
    <>
      <div className="mt-[12%] p-8">
        <h1 className="text-[#67365E] font-[600]  text-[30px]">Findings</h1>
        <p className="text-[#67365E] text-[13px] text-justify ">
          Here is our assessment of you as an potential partner given your
          personality type. This profile has been determined by our algorithm
          based on the questionnaire you have completed. At the outset, we would
          like to point out that your EMOTIONAL RESILIENCE is very low, which
          may have a significant effect on your close relationships and
          marriage. Each parameter is described exhaustively in succeeding pages
          and also indicated as a summary through a KnotriteMeter icon (red as
          poor and green as excellent). We are sure you will find that our
          assessments of the parameters will resonate with you.
        </p>
        <div className="flex flex-col items-center">
          <h3 className="text-white bg-[#67365E] font-500 px-3 ">
            Your KnotRiteType: The Conceptual Planner-H
          </h3>
          <img src={inr1} alt="person" />
          <img src={knotmeter} alt="person" width={800} />
          <h3 className="text-white my-3 w-full bg-[#67365E] font-500 px-3 ">
            Overview
          </h3>
          <p className="text-[#67365E] text-[13px] text-justify ">
            The ever-elusive snow leopard type personality, Visionary, Reserved,
            Independent, Unorthodox, Future-Oriented, Intelligent, Intellectual,
            Logical, Driven, Authentic (What you see is what you get, no
            “smiling face” charisma) As one of the world’s rarest personalities,
            you are extremely private, the most rational, strategic planner, and
            problem solver of all Knotrite types. Being highly intellectual and
            a deep thinker, you have a quest for knowledge and an affinity for
            learning complex concepts and theories. Your worldview lies in logic
            and reason, i.e.- "does that make sense? Is it the most efficient
            option?" than having an humanistic approach like “how will it help
            <img src={inr2} alt="" />
            others? Or, make the world a better place?” You absolutely despise
            people who are by-the-book, bossy and are very conventional and
            orthodox in their thinking. You are not naturally socially adept,
            and typically romance is a social skill, you struggle a bit to cue
            in into your partner's feelings or expose your vulnerabilities. You
            can be quite romantic if you want to, but it doesn't come to you
            naturally. Though you are highly independent, but you are extremely
            loyal and devoted once you find the “right one.” You are not a
            "flower and chocolate" person, you prefer an honest, authentic, and
            intellectually stimulating connection over a sentimental, overtly
            gooey, co-dependent, or frivolous, shallow, casual one.
          </p>
          <div className="bg-[#deb7d7ad] p-5 rounded-md ">
            <h2 className="text-[#67365E] font-[600]  text-[15px]">
              You share some personality traits with….
            </h2>
            <p className="text-[#67365E] text-[13px] text-justify ">
              Jagadish Chandra Bose (Scientist), Homi Jahangir Bhabha (Nuclear
              Physicist), Sir Isaac Newton (Scientist), Stephen Hawking
              (Scientist), Isaac Asimov (Sci-fi Writer), Lewis Carroll (Author)
              D. Eisenhower (34th US Pesident), Ayn Rand (Writer & Philosopher),
              Peter Drucker (Educator, Management Consultant),
            </p>
          </div>
          <h2 className="text-[red] my-3 font-[600]  text-[15px]">
            "Difficulties are highly stimulating to the "Strategic", who love
            responding to a problem that requires a creative solution." - David
            Keirsey
          </h2>
          <img src={cmr3} alt="" />
          <Content
            img={cmrmt}
            data={
              "Your communication style is direct and detached, you prefer to tell it 'like it is', without sugar coating. You prefer to sound competent and thoughtful than warm, fuzzy, friendly, and touchy-feely. You tend to get bored with gossip, mundane details, and transactional conversation. You have a hard time staying engaged if your partner starts discussing his/ her struggle with a bossy or whiny colleague or some recent OTT series everyone going ga-ga about. You want to explore the meaning of life, why people do what they do, the concept and meaning of love, the truth or falsehood of organized religion, the dynamics of world politics etc. You fill your language with rich symbols and imagery and expect your partner to connect the dots. You feel exhausted if you have to  explain and justify your ideas and decisions all the time. "
            }
            dataheading={
              "Direct, Brief, To-the-Point, Futuristic, Abstract, Intuitive, Big-Picture, The “why” of everything"
            }
            heading={"How you communicate"}
          />
          <div className="bg-[#deb7d7ad] p-5 rounded-md ">
            <p className="text-[#67365E] text-[13px] text-justify ">
              Tips: : You might want to understand your own blind spots to be
              able to have a healthy relationship. You may think you are
              forthright in your communication, but your partner might see you
              as judgmental, and opinionated. It will be better if you learn to
              be a bit patient and diplomatic while voicing your ideas and
              opinion. You must understand that not everyone is as quick to
              process information as you, you might have to learn to deal with
              partners who require step by step explanation before understanding
              your point
            </p>
          </div>
          <Content
            img={""}
            data={
              "As flirting and dating are not your natural forte, you don't look for love and romance actively. You are generally reserved and quiet, and you utterly dislike emotional drama so expecting you to follow the normal lovey-dovey romantic routine is a sacrilege. You have rigorous checklist about your partner but once you commit you devote yourself fully to the relationship. Otherwise distant, once committed, you let your partner in and show them your goofy side. You are passionate about your own goal, and you encourage your partner to pursue their goals as well. Traditions and special days do not have much meaning to you, you prefer to show your love through your actions by supporting and helping him or her to solve their problems or reach their goals. You are highly independent and prefer an equally independent partner than a touchy-feely co-dependent one.               "
            }
            dataheading={
              "Reserved, Intellectual, Intriguing, Unflinchingly Loyal, Supportive, Goofy (Only to a close few)             "
            }
            heading={"How you handle romantic relationships"}
          />
          <Content
            img={cmrmt}
            data={
              "You value someone who encourages your drive to achieve your goals and one who appreciates your efficacy, and insight. Intellectual compatibility is your priority, so unless your partner is intellectually stimulating it is difficult for you to make the relationship work. You can sense insincerity and phoniness from a mile, and won’t hesitate to cut someone off if you think you are being manipulated"
            }
            dataheading={"Continued…."}
            heading={"How you handle romantic relationships"}
          />
          <div className="bg-[#deb7d7ad] p-5 rounded-md ">
            <p className="text-[#67365E] text-[13px] text-justify ">
              Tips: There are a few things that are deal-breakers for you –
              dishonesty, superficiality, shallowness, and manipulation to name
              a few. Dating and flirting are common social skills which you
              might struggle with, also you might find celebrating anniversaries
              and valentine’s days meaningless. You might come across as
              emotionally tone-deaf sometimes. So, you need to learn to exercise
              patience if your partner is emotionally evolved and prefers to
              connect emotionally than intellectually
            </p>
          </div>
          <Content
            img={cmrmt}
            data={
              "By nature you are emotionally tone-deaf, so getting into a conflict is no big deal for you. But you weigh carefully whether the issue is serious enough for you to spend your time and energy in a conflict. A logical conflict is mostly a debate or difference of opinion to you. Many a time you take it as a mental exercise, gathering additional information or correcting others’ logical flaws.  If you have an issue with someone you dislike, you simply avoid them. Conflict managed! It’s difficult for you to address irrational or overtly emotional conflict, you simply shut up and give time to your partner to calm down and make sense again. If your partner makes an assertion that is flat-out false or derogatory, you tend to snap into an authoritative voice and put them in their place. This doesn’t happen a lot and tends to shock them. If someone close to you breaks your trust, it's pretty much a deal-breaker. If you are seriously hurt, angered, or betrayed, you may put up a wall and disappear altogether.             "
            }
            dataheading={"Blunt, Insensitive, Cut-throat, Dismissive"}
            heading={"How you manage conflict            "}
          />
          <div className="bg-[#deb7d7ad] p-5 rounded-md ">
            <p className="text-[#67365E] text-[13px] text-justify ">
              Tips: In close relationships, you may hesitate to start an
              argument and create a distance with your partner expecting them to
              calm down, but it might make you look cold, heartless and aloof.
              You will do better if you engage with your partners and offer
              assurance rather than shutting them out.
            </p>
          </div>
          <Content
            img={cmrmt}
            data={
              "You can’t stand loud, Inconsistent, indecisive, stupid, boring, chronically late people, gossips and small talk, unnecessary social niceties and public display of affection. You hate being interrupted as well. It's a sign of disrespect to you, as well as it breaks the chain of your thought while talking. You also dislike being manipulated, and being forced to accept someone else's decision as your own. Temper tantrums, irrational behaviour and sudden emotional outbursts puts you off. Another big turn-off is being pestered for constant attention and approval. You value independence and enjoy space; you expect your partner to understand the same. Overtly clingy and needy behaviour is a complete turn-off to you. Lack of enthusiasm for intellectual rigor, learning, curiosity, and deep thinking is also a no-no. If your partner dislikes change or is not ambitious, you may find it difficult make the relationship work.               "
            }
            dataheading={
              "Interruptions, Manipulation, Emotional melodrama, Co-dependency"
            }
            heading={"What gets you annoyed"}
          />
          <div className="bg-[#deb7d7ad] p-5 rounded-md ">
            <p className="text-[#67365E] text-[13px] text-justify ">
              Tips: You should be careful and keep these things in mind while
              choosing your partner. Some people are very happy-go-lucky,
              overtly touchy-feely, too social and always look for ways to
              entertain themselves. Constant stimulation like these can make you
              feel drained out which in turn might make you cranky. .
            </p>
          </div>
          <Content
            img={cmrmt}
            data={
              "Money is a tool to live a comfortable life, acquire knowledge, and achieve your mission, and not a means to show off or flaunt your wealth. You are not a great spender, you are not frugal either. You do not focus too much on spending or saving if you can accumulate enough wealth to live comfortably. Brand names don't sway you but you do not buy cheap things either. You always have a reason to spend and prefer to think through before spending. Instant gratification isn't a thing for you.                 "
            }
            dataheading={"Continued…..."}
            heading={"How you manage responsibilities / finances            "}
          />
          <div className="bg-[#deb7d7ad] p-5 rounded-md ">
            <p className="text-[#67365E] text-[13px] text-justify ">
              Tips: You prefer to spend your free time with people who you find
              intellectually compatible and stimulating. Someone with a
              conventional idea of family won’t gel well with you as unlike you,
              they might want to spend more time with their extended family
              members. You should carefully choose your partner because some
              people have poor impulse control and seek instant gratification by
              splurging. This trait may not go well with you. You should clarify
              your likes and dislikes in an early stage of relationship to avoid
              trouble later.
            </p>
          </div>
          <Content
            img={cmrmt}
            data={
              "You should be careful about getting into a serious committed relationship as your emotional resilience is quote low, which may impact the health and quality of your relationship with your partner. You may have to pay more attention to your behaviour before entering a long-term partnership. As your emotional stability is in question, you may find this report a bit exasperating and accusatory and can deny the fact that you do struggle to control your impulse in daily life and exhibit instability from time to time. It may be difficult for you to accept that you have a tendency of being unreliable and ending a greater number of conflicts which limits you to have a happy, healthy, and peaceful relationship with your partner. Please note, the objective of this analysis is not to criticize and condemn you but to create awareness of your blind spots so that you can take the remedial course of action if needed. "
            }
            heading={"Emotional resilience"}
          />
          <img src={inr3} alt="" />
          <p className="text-[#67365E] text-[13px] text-justify ">
            The next series of questions are intended to make you introspect and
            reflect on your own behaviour
          </p>
          <ul className="list-disc text-[#67365E] p-5 text-[13px] text-justify">
            <li>
              Do you tend to get upset at the drop of a hat and are prone to
              mood swings and lash back?
            </li>
            <li>
              Are you always irritable, constantly worrying about some imaginary
              troubles, and continuously complaining and blaming?
            </li>
            <li>
              Do you find it difficult to get day-to-day things done and always
              gripe hoping someone else will do it for you?
            </li>
            <li>
              Do you have trouble getting along at work and come across as too
              needy, ill-tempered, and argumentative?
            </li>
            <li>
              Do you tend to people-please and expect others' approval always?
              Do you always expect that others will hand-hold you?
            </li>
          </ul>
          <p className="text-[#67365E] text-[13px] text-justify font-[600]">
            If these situations sound familiar then our recommendation is to
            address these issues before starting to search for a partner. You
            have very poor self-regulation or ability to manage urges, have
            trouble dealing with stress, display a strong reaction to perceived
            threats, and a tendency to constant complaints and venting. Dealing
            with you becomes difficult for others as you tend to be always
            high-strung and others must walk on eggshells to accommodate you.
            You tend to interpret any minor problem as overwhelming and regular
            situations as threatening without any provocation. You are somewhat
            self-centred and tend to be critical of others but fail to see that
            the problem lies with you most of the time. Unless you pay conscious
            attention, you will have a lot of conflict with your significant
            other and the people around you. Learning to manage emotion is
            crucial for you to establish a strong and healthy bond with your
            partner. We strongly urge you to try counselling and/ or
            psychotherapy to manage your own emotional well-being.
          </p>
          <Content
            img={inr4}
            data={
             "As you noticed your Emotional Resilience is very low, you need to be aware that it is going to have a significant effect on the quality of your relationship in future. Individuals with low emotional resilience are less satisfied in intimate relationship because you tend to interpret every behavior as a perceived threat that triggers your negative responses i.e.- anger, frustration, sadness, anxiety etc. On the other hand, you can come across as too submissive, clingy and needy in a relationship. You should seek professional help to manage your low emotional resilience before embarking on a serious relationship. If you already have a partner, you should understand that he/ she can't be your saviour and is not responsible for your mental health. Even when you learn to understand and manage your mental state, you should look for someone who is kind and forgiving by nature and has lots of patience to handle your occasional mood swings...             "
            }
            heading={"Your Ideal  Partner             "}
          />
          <Content
            img={""}
            data={
            "The above analysis would have given you a clear insight about your low Emotional Resilience trait and how it can affect your intimate relationship in future. We strongly suggest you to seek professional help and counseling to understand your own trait and to develop emotional resilience by avoiding unhealthy coping mechanisms (Over or under eating, impulsive spending, substance abuse, avoiding real issues and problems, codependence, believing in positive thoughts only rather than taking positive actions, being in denial etc.). We @ Knotrite can help you building Emotional Resilience through counseling if you are committed to make long lasting change.            "
            }
            dataheading={"Though any two mature adults can have a perfect relationship despite the different traits, temperament, life philosophy and world view but having a clear cut ideas about it generates awareness and makes life easier for both"}
            heading={"Final Thoughts…..            "}
          />
          <img src={finalFlag} alt="" />
        </div>
      </div>
    </>
  );
}
