import { useNavigate } from "react-router-dom";

export default function OfferCard(props) {
  const nav=useNavigate()
  return (
    <>
    <div className="flex flex-col items-center  mb-[4%] md:mb-[2%]   " >
    <div    style={{backgroundImage:`url(${props.img})`,backgroundSize:'100% 100%',backgroundPosition:'center'}} className="rounded-full  h-[150px] w-[150px] bg-[#ffff]  translate-y-[60%]">
          {/* <img
            src={props.img}
            alt=""
            // width={"100%"}
            className="rounded-full h-[150px] w-[150px]  bg-[#ffff]  "
          /> */}
        </div>
      <div className={`w-[310px]  ${props.isBlog?"bg-[#f0daf4d0] min-h-[200px] pt-[35%] ":"bg-[#67365E] min-h-[350px] pt-[50%] "} rounded-md flex flex-col shadow-2xl  p-5`}>
        
        <p className={`font-[600]    text-[13px] mb-5 ${!props.isBlog?"text-white":"text-[#67365E] hover:cursor-pointer"}  text-center`} onClick={()=>props.url?nav("marriage-toolkit/"+props.url):""}>
          {props.text}
        </p>

        {props.check?.map((check, i) => {
          return (
            <div className="flex justify-start items-baseline text-white">
            
              <p className="text-[13px] mb-4 font-[500]  flex justify-start items-baseline">
                <div className="w-[20px]">
                <svg
                  width="18"
                  height="13"
                  viewBox="0 0 18 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-right"
                >
                  <path
                    d="M6.99999 10.172L16.192 0.979004L17.607 2.393L6.99999 13L0.635986 6.636L2.04999 5.222L6.99999 10.172Z"
                    fill="white"
                  />
                </svg>
                </div>
                &nbsp;{check}
              </p>
            </div>
          );
        })}
      </div>
      </div>
    </>
  );
}
