import React from "react";
import '../components/Style.css';
import Navbar from '../components/header/Navbar'
import Wave from "../components/header/Wave";
import Instruct from "../components/instruction/Instruct";
import Footer from "../components/Footer";



export default function Homepage() {
  
return(
        <React.Fragment>
            <section >
               
                <Wave title="KnotRiteReadiness" subTitle="Free Assessment For Marriage/relationship Readiness" current="Free Assesment"/>
                <Instruct />
              
               
              
            </section>
        </React.Fragment>
    );
}
