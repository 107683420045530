import React,{useState,useEffect} from "react";
import "../components/Style.css";
import Wave from "../components/header/Wave";
import Price from "../components/Pricing/price";
import { useNavigate, useParams } from "react-router-dom";
import { post, get } from "../services/apiHandler";
import { toast } from 'react-toastify';
import { Dna } from "react-loader-spinner";

export default function PricingPlan() {
  const Nav=useNavigate();
  const [plans, setPlans] = useState([]);
  const {status}=useParams()
 


useEffect(() => {
  if(status&&status=="false"){
    toast.warning("Payment Failed. Please retry or use another payment method")
  }
  get("api/get_subscription_plans")
  .then((res) => {
    if (res.data.success == true) {
      const pr_upgrade=res.data.data.map((pr)=>{
        pr.subscription_plan_discount[0].discount_amount=Math.ceil(pr.subscription_plan_discount[0]?.discount_amount)
        pr.actualprice=Math.ceil(pr.price-pr.price *(pr?.subscription_plan_discount.length > 0 ? pr?.subscription_plan_discount[0]?.discount_amount/100:0))
        return pr;
      })
      console.log("pr-->",pr_upgrade);
      setPlans(pr_upgrade)
    }
  })
  .catch((error) => {
    
  });
}, [])


  return (

    <React.Fragment>
       {
      plans.length==0?
    <div className="min-h-[100vh] flex justify-center items-center ">
    <Dna
    visible={true}
    height="80"
    width="80"
    ariaLabel="dna-loading"
    wrapperStyle={{}}
    wrapperClass="dna-wrapper"
  /></div>
    :<>
      <section className="w-full overflow-hidden">
        
        <Wave title="Pricing Plan" subTitle="" current="Pricing Plan" />
        <div className="flex justify-center md:justify-around md:w-full mx-2 md:mx-4 my-10 md:flex-nowrap flex-wrap">
          {plans.length > 0 && 
          <>
          {plans?.filter((item) => item.id === 1)[0]&&<Price
          id={1}
            type={<span>{plans?.filter((item) => item.id === 1)[0]?.name} <br /><br /></span>}
            preprice={plans?.filter((item) => item.id === 1)[0]?.price}
            name={plans?.filter((item) => item.id === 1)[0]?.name}
            actualprice={plans?.filter((item) => item.id === 1)[0]?.actualprice}
            plan_id={plans?.filter((item) => item.id === 1)[0]?.id}
            discount_id={plans?.filter((item) => item.id === 1)[0]?.subscription_plan_discount[0]?.id}
            discount_amount={plans?.filter((item) => item.id === 1)[0]?.subscription_plan_discount[0]?.discount_amount}
            des={[
              "Individual assesment (KnotriteType)for yourself",
              "Includes personalised evaluation on 6 marriage requirement parameters.   ",
              "Recommendation on potential matches",
            ]}
            // upgradePlan={(plans?.filter((item) => item.id === 2)[0]?.price-(plans?.filter((item) => item.id === 2)[0]?.price) *(plans?.filter((item) => item.id === 2)[0]?.subscription_plan_discount.length > 0 ? plans?.filter((item) => item.id === 2)[0]?.subscription_plan_discount[0]?.discount_amount/100 : 0))-(plans?.filter((item) => item.id === 1)[0]?.price-(plans?.filter((item) => item.id === 1)[0]?.price) *(plans?.filter((item) => item.id === 1)[0]?.subscription_plan_discount.length > 0 ? plans?.filter((item) => item.id === 1)[0]?.subscription_plan_discount[0]?.discount_amount/100 : 0))}
            upgradePlan={plans?.filter((item) => item.id === 1)[0].subscription_plan_children[0].price}
            // sub_plan_id={plans?.filter((item) => item.id === 9)[0]?.id}
/>}
          
           {plans?.filter((item) => item.id === 6)[0]&&<Price
            id={6}
            type={<span>{plans?.filter((item) => item.id === 6)[0]?.name} <br /> <br /></span>}
            preprice={plans?.filter((item) => item.id === 6)[0]?.price}
            isPremimum={true}
            name={plans?.filter((item) => item.id === 6)[0]?.name}
            actualprice={plans?.filter((item) => item.id === 6)[0]?.actualprice}
            plan_id={plans?.filter((item) => item.id === 6)[0]?.id}
            discount_id={plans?.filter((item) => item.id === 6)[0]?.subscription_plan_discount[0]?.id}
            discount_amount={plans?.filter((item) => item.id === 6)[0]?.subscription_plan_discount[0]?.discount_amount}
            des={[
              "Couple compatibility assesment with prospective partner.",
              "Includes indepth couple evaluation on 8 married life parameters",
              "The Invitation can be sent to only paid member who has already completed the KnotRiteType Test(individual assesment)",

            ]}
            addPartner={plans?.filter((item) => item.id === 6)[0].subscription_plan_children}
            sub_plan_id={plans?.filter((item) => item.id === 10)[0]?.id}
            // upgradePlan={(plans?.filter((item) => item.id === 2)[0]?.price-(plans?.filter((item) => item.id === 6)[0]?.price) *(plans?.filter((item) => item.id === 6)[0]?.subscription_plan_discount.length > 0 ? plans?.filter((item) => item.id === 6)[0]?.subscription_plan_discount[0]?.discount_amount/100 : 0))}
          />}
          {plans?.filter((item) => item.id === 2)[0]&& <Price
            type={<span>{plans?.filter((item) => item.id === 2)[0]?.name} <br /> <br /></span>}
            name={plans?.filter((item) => item.id === 2)[0]?.name}
            id={2}
            isPremimum={true}
            preprice={plans?.filter((item) => item.id === 2)[0]?.price}
            // .filter((item) => item.id === 2)[0]?.price-(plans?.filter((item) => item.id === 2)[0]?.price) *(plans?.filter((item) => item.id === 2)[0]?.subscription_plan_discount.length > 0 ? plans?.filter((item) => item.id === 2)[0]?.subscription_plan_discount[0]?.discount_amount/100 : 0)
            actualprice={plans?.filter((item) => item.id === 2)[0].actualprice}
            plan_id={plans?.filter((item) => item.id === 2)[0]?.id}
            discount_id={plans?.filter((item) => item.id === 2)[0]?.subscription_plan_discount[0]?.id}
            discount_amount={plans?.filter((item) => item.id === 2)[0]?.subscription_plan_discount[0]?.discount_amount}
            des={[
              "Combined package of Standard and Couple Match",
              "Individual assesment (KnotriteType)for yourself and prospective partner.Invited Partner need not be a paid member.",
              "Recommendation on potential matches for each partner",
              "Couple compatibility assesment with the prospective partner.Includes indepth couple evaluation on 8 married life parameters"
            ]}
            addPartner={plans?.filter((item) => item.id === 2)[0].subscription_plan_children}
            sub_plan_id={plans?.filter((item) => item.id === 9)[0]?.id}
          />}
          {plans?.filter((item) => item.id ===8)[0]&& <Price
            id={8}
            type={plans?.filter((item) => item.id ===8)[0]?.name}
            name={plans?.filter((item) => item.id ===8)[0]?.name}
            preprice={plans?.filter((item) => item.id ===8)[0]?.price}
            actualprice={plans?.filter((item) => item.id ===8)[0]?.actualprice}
            plan_id={plans?.filter((item) => item.id ===8)[0]?.id}
            discount_id={plans?.filter((item) => item.id ===8)[0]?.subscription_plan_discount[0]?.id}
            discount_amount={plans?.filter((item) => item.id ===8)[0]?.subscription_plan_discount[0]?.discount_amount}
            des={[
              "Plans based on assessment results.",
              "Experts advise on specific areas of improvement.",
              "Plan duration according to individual needs.",
              "Longer plans may be eligible for further discounts."
            ]}
          />}
        </>}
        </div>
       
        <div className="md:mx-32 mx-4 font-[500] text-[13px] leading-[22px]">
        <button className={"rounded-full mb-3 md:min-w-[100px] cursor-pointer w-[unset] bg-[#67365E] text-[14px] md:px-1 px-4 py-1 text-base  font-medium text-white transition duration-200 "} 
               onClick={()=>Nav("/faq",{state:{id:4}})}
               >
                  FAQ
                </button>
        <p className="text-[15px] font-semibold">Note:<br /> <br />
      All partner invitations under Couple Match and Premium packages will be sent by text and email. Invited Partner will have to signup(if not done already) or sign in and then  visit My Account Page to accept Invitation.					  <br /> <br />	
To ensure  that you do not pay in excess, some buttons of "buy now" above will be disabled under certain conditions. These conditions as below</p>		

			
	<ul className="list-item font-[300] mx-2 ml-7 list-disc text-justify">
    <li className="my-3 font-[300]">If you have already purchased any plan(Standard/Premium/Couple match), that particular button will be disabled.		</li>
    <li className="my-3 font-[300]">You have purchased standard plan , you can upgrade to premium by "upgrade to premium" .However if you continue to be standard member, the buttons under premium will be disabled. 					
	</li>
  <li className="my-3 font-[300]">You have not purchased the main plan and therefore the add-on plan cannot be bought.For example Add on plan under Couple Match cannot be bought unless you buy the Couple match plan.					
	</li>
  <li className="my-3 font-[300]">You have already purchased the upgraded plan . For example you have upgraded to Premium, so the buttons under standard and couple match plan will be disabled.</li>
    </ul>			
    </div>
        <div className="text-center   my-4 font-[400] text-[17px] text-[#363636]">
        Please visit our &nbsp; <strong className="hover:text-[#AC4884] cursor-pointer duration-150" onClick={()=>Nav("/faq")}>FAQ</strong>&nbsp; page under pricing to understand the most cost effective plan .
        </div>
      </section>
   </>}
    </React.Fragment>
  );
}
