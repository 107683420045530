import React, { useEffect, useState } from "react";
import Button from "../modal/Button";
import Login from "../modal/Login";
import img from "../../asset/images/logo.png";
import "./../Style.css";
import ForgotPassword from "../modal/forgotPassword";
import imgWH from "../../asset/images/knotrite_logo_white.png";
import { useLocation, useNavigate } from "react-router-dom";
import Subbutton from "../modal/Subbutton";
import Modal from "../modal/Modal";
import signUpImg from '../../asset/images/signup.jpg';
import myAccount from '../../asset/images/myAccount.png';
const Navbar = (props) => {
  const location = useLocation();
  const [showLogin, setShowLogin] = useState(false);
  const [showSignUp, setshowSignUp] = useState(false);
const [fv,setfv]=useState(false)
  const handleClose = () => setShowLogin(false);
  const handleSingUpClose = () => setshowSignUp(false);
 
  const handleLogin = () => {
    
   Nav("/signup")
  }
  const handleSignUp = () => {
    
    handleClose()
    setshowSignUp(true);
  }
 const handleForgotPasswordClose=()=>{
setfv(!fv)
handleClose()
  }
   
  const [visible, setVisible] = useState({ display: "none" });
  const [dropDown, setDropDown] = useState({ display: "none" });
  const Nav = useNavigate();
  const [bol, setbol] = useState(true);
  let Links = [
    { name: "HOME", link: "/" },

    { name: "TAKE FREE TEST", link: "/free-test" },
  ];
  let subLinks = [
    { name: "SERVICES", link: "/services" },
   
    // { name: "PAID ASSESSMENT", link: "/individualPaidTest" },
    { name: "MARRIAGE TOOLKIT", link: "/marriage-toolkit" },
    { name: "PRICING", link: "/pricing" },
  ];
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
 
  }, [location.pathname]);
  useEffect(() => {
    
    document.onscroll = () => {
      if (window.pageYOffset > 567) {
        setbol(false);
      } else setbol(true);
    };
    document.onclick = function (e) {
      if (
        e.target.id !== "menu__toggle" &&
        e.target.id !== "mobile_user" &&
        e.target.id !== "mobile_user_ic" &&
        e.target.id !== "mobile_user_it"
      ) {
        {
          document.getElementById("menu__toggle").checked = false;
        }
      }
    };
  }, []);

  
  return (
    <React.Fragment>
      <div
        className={
          bol && location.pathname == "/"
            ? "bg-transparent shadow-md w-full duration-700 fixed top-0 left-0  h-[70px] sm:h-[90px] bottom-4  z-[10] "
            : "bg-white shadow-md w-full fixed top-0 left-0  h-[70px] sm:h-[90px] bottom-4  z-[10] "
        }
      >
        <div
          className={
            bol && location.pathname == "/"
              ? " absolute w-full  items-end duration-700 justify-between  space-x-10   px-1 h-20 sm:h-[90px] hidden sm:flex"
              : " absolute w-full  items-end justify-between  space-x-10   px-1 h-20 sm:h-[90px] bg-gradient-to-t from-[#f0daf4] via-[#f4eef4] to-[#67365e] hidden sm:flex"
          }
        >
          {bol && location.pathname == "/" ? (
            <div className="flex justify-end items-end  ">
              <div className="flex justify-end items-end flex-col ">
                <img
                  src={imgWH}
                  alt=""
                  className="  w-32 sm:w-32  cursor-pointer"
                  onClick={() => Nav("/")}
                />
                <p className="text-white text-[13px] font-[500] ">
                  Uniting Destinies
                </p>
              </div>
            </div>
          ) : (
            <img
              src={img}
              alt=""
              className="h-fit w-[100px] sm:w-18 mt-2 sm:mb-3 ml-5 cursor-pointer"
              onClick={() => Nav("/")}
            />
          )}

          <ul className="flex w-full sm:w-fit sm:min-w-[50%] md:items-end justify-between ml-2 mr-4 mb-2 mt-5">
            {Links.map((Link) => (
              <>
                <li
                  key={Link.name}
                  className={
                    bol && location.pathname == "/"
                      ? "md:ml-2 pt-5 text-[13px] text-white font-[600] cursor-pointer"
                      : "md:ml-2 pt-5 text-[13px] text-[#67365e] font-[600] cursor-pointer"
                  }
                >
                  <a
                    onClick={() => {
                      Nav(Link.link);
                    }}
                  >
                    {Link.name}
                  </a>
                </li>
                {Link.name === "HOME" && (
                  <div
                    className="relative "
                    data-te-dropdown-ref
                    onMouseEnter={() => {
                      setDropDown({ display: "block" });
                    }}
                    onMouseLeave={() => {
                      setDropDown({ display: "none" });
                    }}
                  >
                    <li
                      className={
                        bol && location.pathname == "/"
                          ? "md:ml-2 pt-5 text-[13px] text-white font-[600] cursor-pointer flex items-center"
                          : "md:ml-2 pt-5 text-[13px] text-[#67365e] font-[600] cursor-pointer flex items-center"
                      }
                    >
                      <a onClick={() => {}}>EXPLORE</a>&nbsp;
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        width={15}
                        className="mt-1"
                      >
                        <path
                          fill={
                            bol && location.pathname == "/"
                              ? "#ffff"
                              : "#67365e"
                          }
                          d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
                        />
                      </svg>
                    </li>
                    <ul
                      style={dropDown}
                      className="absolute z-[1000]  left-[-50px] m-0  min-w-[200px] list-none overflow-hidden rounded-lg border-none bg-[#f4eef4] bg-clip-padding text-left text-base shadow-lg  "
                    >
                      {subLinks.map((Link) => (
                        <li
                          key={Link.name}
                          className=" pt-1 text-[13px] text-[#67365e] font-[550] cursor-pointer"
                        >
                          <a
                            onClick={() => {
                              Nav(Link.link);
                            }}
                            className="flex justify-left items-center cursor-pointer w-full whitespace-nowrap bg-transparent px-4 py-1  text-[12px] text-[#67365e] font-[500]  hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 "
                          >
                            {Link.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            ))}

            <li
              className={
                bol && location.pathname == "/"
                  ? " pt-5 text-[13px] font-bold text-white cursor-pointer"
                  : "pt-5 text-[13px] font-bold text-pink-700 cursor-pointer"
              }
              onClick={() => {
                Nav("/faq");
              }}
            >
              FAQ's
            </li>

            {/* <Subbutton class={"bg-[#480740] text-white text-[13px] py-2 px-6 rounded-3xl mt-3 md:ml-8 hover:bg-pink-800 duration-500 cursor-pointer"}>SIGN UP</Subbutton> */}
            {JSON.parse(localStorage.getItem("login"))?.token ? (
              <div className="mr-[10px] flex justify-end items-end">
                <div
                  className="relative  "
                  data-te-dropdown-ref
                  onMouseEnter={() => {
                    setVisible({ display: "block" });
                  }}
                  onMouseLeave={() => {
                    setVisible({ display: "none" });
                  }}
                >
                  <button
                    className="bg-[#480740] font-[600] w-full text-white text-[13px]  flex justify-around items-center py-[5px] px-6 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer"
                    id="mobile_user"
                    type="button"
                  >
                    <svg
                      id="mobile_user_ic"
                      width="14"
                      height="14"
                      viewBox="0 0 14 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.00012 8C7.99468 8 8.94851 7.60491 9.65177 6.90165C10.355 6.19839 10.7501 5.24456 10.7501 4.25C10.7501 3.25544 10.355 2.30161 9.65177 1.59835C8.94851 0.895088 7.99468 0.5 7.00012 0.5C6.00556 0.5 5.05173 0.895088 4.34847 1.59835C3.64521 2.30161 3.25012 3.25544 3.25012 4.25C3.25012 5.24456 3.64521 6.19839 4.34847 6.90165C5.05173 7.60491 6.00556 8 7.00012 8V8ZM7.00012 9.875C3.24262 9.875 0.182617 12.395 0.182617 15.5C0.182617 15.71 0.347617 15.875 0.557617 15.875H13.4426C13.6526 15.875 13.8176 15.71 13.8176 15.5C13.8176 12.395 10.7576 9.875 7.00012 9.875Z"
                        fill="white"
                      />
                    </svg>{" "}
                    <span className="text-[13px]" id="mobile_user_it">
                      &nbsp;HI {JSON.parse(localStorage.getItem("login"))?.name}
                    </span>
                  </button>
                  <ul
                    style={visible}
                    className="absolute z-[1000] float-left m-0  min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg  "
                  >
                    <li>
                      <a
                        className="flex justify-left items-center cursor-pointer w-full whitespace-nowrap bg-transparent px-4 py-2  text-[12px] font-[500] text-[#171717E5] hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 "
                        onClick={() => {
                          Nav("/myaccount");
                        }}
                      >
                        <svg
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.00012 8C7.99468 8 8.94851 7.60491 9.65177 6.90165C10.355 6.19839 10.7501 5.24456 10.7501 4.25C10.7501 3.25544 10.355 2.30161 9.65177 1.59835C8.94851 0.895088 7.99468 0.5 7.00012 0.5C6.00556 0.5 5.05173 0.895088 4.34847 1.59835C3.64521 2.30161 3.25012 3.25544 3.25012 4.25C3.25012 5.24456 3.64521 6.19839 4.34847 6.90165C5.05173 7.60491 6.00556 8 7.00012 8ZM7.00012 9.875C3.24262 9.875 0.182617 12.395 0.182617 15.5C0.182617 15.71 0.347617 15.875 0.557617 15.875H13.4426C13.6526 15.875 13.8176 15.71 13.8176 15.5C13.8176 12.395 10.7576 9.875 7.00012 9.875Z"
                            fill="#67365E"
                          />
                        </svg>
                        &nbsp; My Account
                      </a>
                    </li>
                    <li>
                      <a
                        className="flex w-full justify-left items-center cursor-pointer whitespace-nowrap bg-transparent px-4 py-2 text-[12px] font-[500] text-[#171717E5] hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 "
                        onClick={() => {
                          Nav("/changepassword");
                        }}
                      >
                        <svg
                          width="16"
                          height="10"
                          viewBox="0 0 16 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.0625 0.575C12.7325 0.53 12.3575 0.5075 11.9375 0.5H4.0625C3.6425 0.5075 3.2675 0.53 2.9375 0.575C1.025 0.8075 0.5 1.745 0.5 4.25V5.75C0.5 8.75 1.25 9.5 4.25 9.5H11.75C14.75 9.5 15.5 8.75 15.5 5.75V4.25C15.5 1.745 14.975 0.8075 13.0625 0.575ZM5.5325 5.5325C5.39 5.6675 5.195 5.75 5 5.75C4.9025 5.75 4.805 5.7275 4.715 5.69C4.6175 5.6525 4.5425 5.6 4.4675 5.5325C4.3325 5.39 4.25 5.195 4.25 5C4.25 4.9025 4.2725 4.805 4.31 4.715C4.3475 4.625 4.4 4.5425 4.4675 4.4675C4.5425 4.4 4.6175 4.3475 4.715 4.31C4.9925 4.19 5.3225 4.2575 5.5325 4.4675C5.6 4.5425 5.6525 4.625 5.69 4.715C5.7275 4.805 5.75 4.9025 5.75 5C5.75 5.195 5.6675 5.39 5.5325 5.5325ZM8.69 5.285C8.6525 5.375 8.6 5.4575 8.5325 5.5325C8.39 5.6675 8.195 5.75 8 5.75C7.7975 5.75 7.61 5.6675 7.4675 5.5325C7.4 5.4575 7.3475 5.375 7.31 5.285C7.27148 5.19486 7.25109 5.09802 7.25 5C7.25 4.7975 7.3325 4.61 7.4675 4.4675C7.745 4.19 8.2475 4.19 8.5325 4.4675C8.6675 4.61 8.75 4.7975 8.75 5C8.75 5.0975 8.7275 5.195 8.69 5.285ZM11.5325 5.5325C11.39 5.6675 11.195 5.75 11 5.75C10.805 5.75 10.61 5.6675 10.4675 5.5325C10.3325 5.39 10.25 5.2025 10.25 5C10.25 4.7975 10.3325 4.61 10.4675 4.4675C10.7525 4.19 11.255 4.19 11.5325 4.4675C11.5625 4.505 11.5925 4.5425 11.6225 4.5875C11.6525 4.625 11.675 4.67 11.69 4.715C11.7125 4.76 11.7275 4.805 11.735 4.85C11.7425 4.9025 11.75 4.955 11.75 5C11.75 5.195 11.6675 5.39 11.5325 5.5325Z"
                            fill="#67365E"
                          />
                        </svg>{" "}
                        &nbsp; Change Password
                      </a>
                    </li>
                    <li>
                      <a
                        className="flex justify-left items-center w-full cursor-pointer whitespace-nowrap bg-transparent px-4 py-2 text-[12px] font-[500] text-[#171717E5] hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 "
                        onClick={() => {
                          localStorage.removeItem("login");
                          Nav("/",{state:{redirect:true}})
                        }}
                      >
                        <svg
                          width="3"
                          height="9"
                          viewBox="0 0 3 9"
                          className="translate-x-3 translate-y-[-5px]"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.314453"
                            width="1.82857"
                            height="8.68571"
                            rx="0.914286"
                            fill="#67365E"
                          />
                        </svg>{" "}
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.6631 0.735243C14.9695 0.328706 15.551 0.244411 15.926 0.588753C17.1668 1.72829 18.0866 3.18157 18.5833 4.80291C19.1703 6.71905 19.1364 8.77176 18.4863 10.6674C17.8362 12.5631 16.6032 14.2046 14.9638 15.3572C13.3244 16.5098 11.3624 17.1145 9.3586 17.0846C7.35478 17.0548 5.41171 16.3921 3.80731 15.1912C2.20292 13.9903 1.01938 12.3128 0.425995 10.3986C-0.167394 8.48441 -0.140243 6.4316 0.503565 4.53378C1.04832 2.92795 2.01097 1.50269 3.28514 0.400589C3.67017 0.0675594 4.24892 0.169126 4.54309 0.584602C4.83725 1.00008 4.73439 1.57154 4.35725 1.91348C3.39671 2.78435 2.66911 3.88867 2.24936 5.12602C1.73049 6.65556 1.7086 8.31001 2.18684 9.85274C2.66508 11.3955 3.61895 12.7474 4.912 13.7153C6.20506 14.6831 7.77107 15.2173 9.38604 15.2413C11.001 15.2654 12.5822 14.7781 13.9035 13.8491C15.2248 12.9202 16.2185 11.5972 16.7424 10.0694C17.2664 8.5416 17.2938 6.88723 16.8206 5.34292C16.4379 4.09363 15.7435 2.96814 14.8093 2.06906C14.4425 1.71605 14.3567 1.14178 14.6631 0.735243Z"
                            fill="#67365E"
                          />
                        </svg>
                        &nbsp; Log Out
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <button
                    onClick={() => setShowLogin(true)}
                    className=" w-[50%] ml-6 text-[13px] flex justify-around items-center py-1 px-5 rounded-3xl mt-3 border-[1px] border-black hover:bg-pink-800 duration-500 cursor-pointer"
                  >
                    Login
                  </button> */}
                <button
                  onClick={() => {
                    localStorage.removeItem("login");
                    
                    if(window.location.pathname=="/")
                    window.location.reload()
                  else
                    Nav("/",{state:{redirect:true}})
                  }}
                  className="bg-[#480740] ml-2 font-[600] text-white text-[13px]  flex justify-around items-center py-[5px] px-6 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer"
                >
                  LOGOUT
                </button>
              </div>
            ) : (
              <div className="flex mr-4">
                {/* <button
                       className="bg-[#480740] w-[60%] text-white text-[13px] flex justify-around items-center py-2 px-5 rounded-3xl mt-3  hover:bg-pink-800 duration-500 cursor-pointer"
                       id="mobile_user"
                       type="button"
                     >
                     
                       <span className="text-[13px]" id="mobile_user_it">
                         &nbsp;Sign Up
                       </span>
                     </button> */}
                <button
                 onClick={() => Nav("/signup")}
                  class={
                    "bg-[#480740] ml-6  text-white text-[13px] flex justify-around items-center py-1 px-5 rounded-3xl mt-3  hover:bg-pink-800 duration-500 cursor-pointer"
                  }
                  id="navSignUp"
                >
                  Sign up
                </button>

                <button
                  onClick={() => Nav("/login")}
                  id="navLogin"
                  className=" ml-6 text-[13px] flex justify-around items-center py-1 px-5 rounded-3xl mt-3 border-[1px] border-black hover:border-pink-800 hover:text-[13px]ink-800 duration-500 cursor-pointer"
                >
                  Login
                </button>
              </div>
            )}
          </ul>
        </div>
        <div
          className={
            bol && location.pathname == "/"
              ? " absolute w-full  items-center justify-between space-x-10 pt-5  px-1 h-[80px]  flex sm:hidden"
              : "bg-transprant absolute w-full  items-center justify-between space-x-10 pt-5  px-1 h-[80px]  bg-gradient-to-t from-[#f0daf4] via-[#f4eef4] to-[#67365e] flex sm:hidden"
          }
        >
          {bol && location.pathname == "/" ? (
            <div className="flex justify-end items-end  ">
              <div className="flex justify-start items-start flex-col ">
                <img
                  src={imgWH}
                  alt=""
                  className=" w-[85px] sm:w-32  cursor-pointer"
                  onClick={() => Nav("/")}
                />
                <p className="text-white text-[8px] font-[500] ml-[5%] ">
                  Uniting Destinies
                </p>
              </div>
            </div>
          ) : (
            <img
              src={img}
              alt=""
              className="h-fit w-[85px] sm:w-28 mt-2 ml-5"
            />
          )}
          <section className="MOBILE-MENU flex lg:hidden mt-[10%]">
            <div
              className="around lg:hidden"
              // onBlur={() => document.getElementById("menu__toggle").checked=false}
            >
              <div className="hamburger-menu">
                <input id="menu__toggle" type="checkbox" />
                <label className="menu__btn" for="menu__toggle">
                  <span
                    className={
                      bol && location.pathname == "/"
                        ? " bg-white before:bg-white after:bg-white"
                        : "bg-[#171616] before:bg-black after:bg-black"
                    }
                  ></span>
                </label>

                <ul className="menu__box ">
                  {Links.map(
                    (Link, i) =>
                      (
                        <li key={Link.name}>
                          <a
                            onClick={() => {
                              Nav(Link.link);
                            }}
                            className="text-[13px] menu__item cursor-pointer "
                          >
                            {Link.name}
                          </a>
                        </li>
                      )
                  )}
                  {subLinks.map((Link) => (
                    <li key={Link.name}>
                      <a
                        onClick={() => {
                          Nav(Link.link);
                        }}
                        className="text-[13px] menu__item cursor-pointer "
                      >
                        {Link.name}
                      </a>
                    </li>
                  ))}
                  
                  <li>
                    <a
                      className="menu__item text-[13px]"
                      onClick={() => {
                        Nav("/faq");
                      }}
                    >
                      FAQ's
                    </a>
                  </li>
                  <li>
                    <a
                      className="menu__item text-[13px]"
                      onClick={() => {
                        Nav("/myaccount");
                      }}
                    >
                      MY ACCOUNT
                    </a>
                  </li>
                  {/* <Subbutton class={"bg-[#480740] text-white text-[13px] py-2 px-6 rounded-3xl mt-3 md:ml-8 hover:bg-pink-800 duration-500 cursor-pointer"}>SIGN UP</Subbutton> */}
                  {JSON.parse(localStorage.getItem("login"))?.token  ? (
                    <div>
                      <div
                        className="relative menu__item "
                        data-te-dropdown-ref
                        onMouseEnter={() => {
                          setVisible({ display: "block" });
                        }}
                        onMouseLeave={() => {
                          setVisible({ display: "none" });
                        }}
                      >
                        <button
                          className="bg-[#480740] w-[60%] text-white text-[13px] flex justify-around items-center py-2 px-5 rounded-3xl mt-3  hover:bg-pink-800 duration-500 cursor-pointer"
                          id="mobile_user"
                          type="button"
                        >
                          <svg
                            id="mobile_user_ic"
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.00012 8C7.99468 8 8.94851 7.60491 9.65177 6.90165C10.355 6.19839 10.7501 5.24456 10.7501 4.25C10.7501 3.25544 10.355 2.30161 9.65177 1.59835C8.94851 0.895088 7.99468 0.5 7.00012 0.5C6.00556 0.5 5.05173 0.895088 4.34847 1.59835C3.64521 2.30161 3.25012 3.25544 3.25012 4.25C3.25012 5.24456 3.64521 6.19839 4.34847 6.90165C5.05173 7.60491 6.00556 8 7.00012 8V8ZM7.00012 9.875C3.24262 9.875 0.182617 12.395 0.182617 15.5C0.182617 15.71 0.347617 15.875 0.557617 15.875H13.4426C13.6526 15.875 13.8176 15.71 13.8176 15.5C13.8176 12.395 10.7576 9.875 7.00012 9.875Z"
                              fill="white"
                            />
                          </svg>{" "}
                          <span className="text-[13px]" id="mobile_user_it">
                            &nbsp;HI {JSON.parse(localStorage.getItem("login"))?.name}
                          </span>
                        </button>
                        <ul
                          style={visible}
                          className="absolute z-[1000] float-left m-0  min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg  "
                        >
                           <li>
                            <a
                              className="flex justify-left w-full items-center cursor-pointer whitespace-nowrap bg-transparent px-4 py-2 text-[12px] font-[500] text-[#171717E5] hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 "
                              onClick={() => {
                                Nav("/myaccount");
                              }}
                            >
                         
<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12" cy="6" r="4" stroke="#1C274C" stroke-width="1.5"/>
<path d="M19.9975 18C20 17.8358 20 17.669 20 17.5C20 15.0147 16.4183 13 12 13C7.58172 13 4 15.0147 4 17.5C4 19.9853 4 22 12 22C14.231 22 15.8398 21.8433 17 21.5634" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
</svg>
                              &nbsp; My Account
                            </a>
                          </li>
                          <li>
                            <a
                              className="flex w-full justify-left items-center cursor-pointer whitespace-nowrap bg-transparent px-4 py-2 text-[12px] font-[500] text-[#171717E5] hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 "
                              onClick={() => {
                                Nav("/changepassword");
                              }}
                            >
                              <svg
                                width="16"
                                height="10"
                                viewBox="0 0 16 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.0625 0.575C12.7325 0.53 12.3575 0.5075 11.9375 0.5H4.0625C3.6425 0.5075 3.2675 0.53 2.9375 0.575C1.025 0.8075 0.5 1.745 0.5 4.25V5.75C0.5 8.75 1.25 9.5 4.25 9.5H11.75C14.75 9.5 15.5 8.75 15.5 5.75V4.25C15.5 1.745 14.975 0.8075 13.0625 0.575ZM5.5325 5.5325C5.39 5.6675 5.195 5.75 5 5.75C4.9025 5.75 4.805 5.7275 4.715 5.69C4.6175 5.6525 4.5425 5.6 4.4675 5.5325C4.3325 5.39 4.25 5.195 4.25 5C4.25 4.9025 4.2725 4.805 4.31 4.715C4.3475 4.625 4.4 4.5425 4.4675 4.4675C4.5425 4.4 4.6175 4.3475 4.715 4.31C4.9925 4.19 5.3225 4.2575 5.5325 4.4675C5.6 4.5425 5.6525 4.625 5.69 4.715C5.7275 4.805 5.75 4.9025 5.75 5C5.75 5.195 5.6675 5.39 5.5325 5.5325ZM8.69 5.285C8.6525 5.375 8.6 5.4575 8.5325 5.5325C8.39 5.6675 8.195 5.75 8 5.75C7.7975 5.75 7.61 5.6675 7.4675 5.5325C7.4 5.4575 7.3475 5.375 7.31 5.285C7.27148 5.19486 7.25109 5.09802 7.25 5C7.25 4.7975 7.3325 4.61 7.4675 4.4675C7.745 4.19 8.2475 4.19 8.5325 4.4675C8.6675 4.61 8.75 4.7975 8.75 5C8.75 5.0975 8.7275 5.195 8.69 5.285ZM11.5325 5.5325C11.39 5.6675 11.195 5.75 11 5.75C10.805 5.75 10.61 5.6675 10.4675 5.5325C10.3325 5.39 10.25 5.2025 10.25 5C10.25 4.7975 10.3325 4.61 10.4675 4.4675C10.7525 4.19 11.255 4.19 11.5325 4.4675C11.5625 4.505 11.5925 4.5425 11.6225 4.5875C11.6525 4.625 11.675 4.67 11.69 4.715C11.7125 4.76 11.7275 4.805 11.735 4.85C11.7425 4.9025 11.75 4.955 11.75 5C11.75 5.195 11.6675 5.39 11.5325 5.5325Z"
                                  fill="#67365E"
                                />
                              </svg>{" "}
                              &nbsp; Change Password
                            </a>
                          </li>
                          <li>
                            <a
                              className="flex justify-left items-center w-full cursor-pointer whitespace-nowrap bg-transparent px-4 py-2 text-[12px] font-[500] text-[#171717E5] hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 "
                              onClick={() => {
                                localStorage.removeItem("login");
                                Nav("/",{state:{redirect:true}})
                              }}
                            >
                              <svg
                                width="3"
                                height="9"
                                viewBox="0 0 3 9"
                                className="translate-x-3 translate-y-[-5px]"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="0.314453"
                                  width="1.82857"
                                  height="8.68571"
                                  rx="0.914286"
                                  fill="#67365E"
                                />
                              </svg>{" "}
                              <svg
                                width="19"
                                height="18"
                                viewBox="0 0 19 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.6631 0.735243C14.9695 0.328706 15.551 0.244411 15.926 0.588753C17.1668 1.72829 18.0866 3.18157 18.5833 4.80291C19.1703 6.71905 19.1364 8.77176 18.4863 10.6674C17.8362 12.5631 16.6032 14.2046 14.9638 15.3572C13.3244 16.5098 11.3624 17.1145 9.3586 17.0846C7.35478 17.0548 5.41171 16.3921 3.80731 15.1912C2.20292 13.9903 1.01938 12.3128 0.425995 10.3986C-0.167394 8.48441 -0.140243 6.4316 0.503565 4.53378C1.04832 2.92795 2.01097 1.50269 3.28514 0.400589C3.67017 0.0675594 4.24892 0.169126 4.54309 0.584602C4.83725 1.00008 4.73439 1.57154 4.35725 1.91348C3.39671 2.78435 2.66911 3.88867 2.24936 5.12602C1.73049 6.65556 1.7086 8.31001 2.18684 9.85274C2.66508 11.3955 3.61895 12.7474 4.912 13.7153C6.20506 14.6831 7.77107 15.2173 9.38604 15.2413C11.001 15.2654 12.5822 14.7781 13.9035 13.8491C15.2248 12.9202 16.2185 11.5972 16.7424 10.0694C17.2664 8.5416 17.2938 6.88723 16.8206 5.34292C16.4379 4.09363 15.7435 2.96814 14.8093 2.06906C14.4425 1.71605 14.3567 1.14178 14.6631 0.735243Z"
                                  fill="#67365E"
                                />
                              </svg>
                              &nbsp; Log Out
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* <button
                    onClick={() => setShowLogin(true)}
                    className=" w-[50%] ml-6 text-[13px] flex justify-around items-center py-1 px-5 rounded-3xl mt-3 border-[1px] border-black hover:bg-pink-800 duration-500 cursor-pointer"
                  >
                    Login
                  </button> */}
                      <button
                        onClick={() => {
                          localStorage.removeItem("login");
                   Nav("/",{state:{redirect:true}})
                        }}
                        className=" ml-6  border-[1px] border-black hover:border-pink-800 hover:text-[13px]ink-800 w-[50%]  text-[13px] flex justify-around items-center py-1 px-5 rounded-3xl mt-3  hover:bg-pink-800 duration-500 cursor-pointer"
                      >
                        Logout
                      </button>
                    </div>
                  ) : (
                    <div>
                      {/* <button
                       className="bg-[#480740] w-[60%] text-white text-[13px] flex justify-around items-center py-2 px-5 rounded-3xl mt-3  hover:bg-pink-800 duration-500 cursor-pointer"
                       id="mobile_user"
                       type="button"
                     >
                     
                       <span className="text-[13px]" id="mobile_user_it">
                         &nbsp;Sign Up
                       </span>
                     </button> */}
                      <div
                        class={
                          "bg-[#480740] ml-6 w-[50%] text-white text-[13px] flex justify-around items-center py-1 px-5 rounded-3xl mt-3  hover:bg-pink-800 duration-500 cursor-pointer"
                        }
                        onClick={()=> Nav("/signup")}
                      >
                        Sign up
                      </div>

                      <button
                        // onClick={() => setShowLogin(true)}
                        onClick={()=>Nav("/login")}
                        className=" w-[50%] ml-6 text-[13px] flex justify-around items-center py-1 px-5 rounded-3xl mt-3 border-[1px] border-black hover:bg-pink-800 duration-500 cursor-pointer"
                      >
                        Login
                      </button>
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </section>
          {/* <ul className="flex w-full sm:w-[60%] md:items-center justify-between ml-2 mr-4 mt-5">
                 
                  {
                    Links.map((Link)=>(
                      <li key={Link.name} className="md:ml-2 pt-5 text-[13px]">
                        <a href="{Link.link}">{Link.name}</a>
                      </li>
                    ))
                  }
                  <li className="md:ml-8 pt-5 text-[13px] font-bold text-[13px]ink-700">FAQ's</li>
                  <Subbutton class={"bg-[#480740] text-white text-[13px] py-2 px-6 rounded-3xl mt-3 md:ml-8 hover:bg-pink-800 duration-500 cursor-pointer"}>SIGN UP</Subbutton>
                  <button onClick={()=> setShowLogin(true)} className="log">
                     Login
                  </button>
                   
                </ul> */}
        </div>
        <div>
          {/* <Login onClose={handleClose} onforgot={handleForgotPasswordClose} onSignUp={handleSignUp} visible={showLogin} />
          <Modal onClose={handleSingUpClose} onLogin={handleLogin} visible={showSignUp} />
          <ForgotPassword onClose={handleForgotPasswordClose} onLogin={handleLogin} visible={fv} /> */}
        </div>
      </div>

    {  (location.pathname != "/login"&&location.pathname != "/signup")&&<>{JSON.parse(localStorage.getItem("login"))?.token  ?    <div className="float-right cursor-pointer fixed  bottom-[4.2rem] right-6  font-[700] text-[15px] z-[9] flex items-center mt-5"><img onClick={() => Nav("/myAccount")} width={"37px"} src={myAccount}/>
</div>:<div className="float-right cursor-pointer fixed  bottom-[4.2rem] right-6  font-[700] text-[15px] z-[9] flex items-center mt-5"><img onClick={() => Nav("/signup")} width={"37px"} src={signUpImg}/>
</div>}
      <a href="https://wa.link/gypqcm" target="_blank"> <div className="float-right fixed  z-[9]  bottom-5 right-3  font-[700] text-[15px] flex items-center mt-5"><svg width="60" height="40" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">

<rect width="60" height="60" rx="5" fill="#64B161"/>
<path d="M10.1131 52.6889C9.86885 52.6889 9.63121 52.5923 9.45498 52.4141C9.22266 52.1791 9.13305 51.8379 9.22034 51.519L12.0484 41.1919C10.2877 38.0081 9.3594 34.4064 9.36072 30.7503C9.3657 18.8604 19.0416 9.1875 30.9305 9.1875C36.6971 9.18982 42.1155 11.436 46.1881 15.5116C50.2601 19.5876 52.5017 25.0057 52.5 30.7676C52.4947 42.6579 42.8188 52.3314 30.9305 52.3314C30.9305 52.3314 30.9216 52.3314 30.9212 52.3314C27.4487 52.3301 24.006 51.4838 20.9407 49.8808L10.3478 52.6587C10.2701 52.6789 10.1911 52.6889 10.1131 52.6889Z" fill="white"/>
<path d="M10.5273 51.4007L13.4013 40.9059C11.6285 37.8343 10.6961 34.35 10.6974 30.7804C10.7019 19.6133 19.7895 10.5281 30.9563 10.5281C36.3752 10.5304 41.4616 12.6396 45.2865 16.4678C49.1112 20.296 51.2168 25.3846 51.2148 30.7967C51.21 41.9634 42.1211 51.05 30.9563 51.05C30.9556 51.05 30.9566 51.05 30.9563 51.05H30.9475C27.557 51.0487 24.2258 50.1983 21.2668 48.5845L10.5273 51.4007ZM21.7642 44.9181L22.3794 45.2826C24.9641 46.8166 27.9276 47.6279 30.9497 47.6295H30.9563C40.2366 47.6295 47.7902 40.0773 47.7941 30.7954C47.7957 26.2972 46.0461 22.0674 42.8669 18.8857C39.6875 15.704 35.4603 13.9508 30.9624 13.9488C21.6746 13.9488 14.1214 21.5001 14.1178 30.7817C14.1165 33.9624 15.0063 37.0598 16.6921 39.7402L17.0927 40.3773L15.3913 46.5889L21.7642 44.9181Z" fill="white"/>
<path d="M11.5664 49.8093L14.2551 39.9908C12.5963 37.1174 11.7242 33.8575 11.7252 30.5186C11.7293 20.0717 20.231 11.5725 30.6769 11.5725C35.7469 11.5747 40.5054 13.5479 44.0829 17.1292C47.6614 20.7105 49.6308 25.4709 49.6289 30.5334C49.6245 40.9806 41.1225 49.4807 30.6778 49.4807C30.6769 49.4807 30.6781 49.4807 30.6778 49.4807H30.6693C27.4977 49.4795 24.381 48.6835 21.6131 47.1748L11.5664 49.8093Z" fill="#64B161"/>
<path d="M10.5273 51.4007L13.4013 40.9059C11.6285 37.8343 10.6961 34.35 10.6974 30.7804C10.7019 19.6133 19.7895 10.5281 30.9563 10.5281C36.3752 10.5304 41.4616 12.6396 45.2865 16.4678C49.1112 20.296 51.2168 25.3846 51.2148 30.7967C51.21 41.9634 42.1211 51.05 30.9563 51.05C30.9556 51.05 30.9566 51.05 30.9563 51.05H30.9475C27.557 51.0487 24.2258 50.1983 21.2668 48.5845L10.5273 51.4007ZM21.7642 44.9181L22.3794 45.2826C24.9641 46.8166 27.9276 47.6279 30.9497 47.6295H30.9563C40.2366 47.6295 47.7902 40.0773 47.7941 30.7954C47.7957 26.2972 46.0461 22.0674 42.8669 18.8857C39.6875 15.704 35.4603 13.9508 30.9624 13.9488C21.6746 13.9488 14.1214 21.5001 14.1178 30.7817C14.1165 33.9624 15.0063 37.0598 16.6921 39.7402L17.0927 40.3773L15.3913 46.5889L21.7642 44.9181Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.895 22.3137C25.5159 21.4708 25.1166 21.4538 24.7558 21.4392C24.4607 21.4265 24.1229 21.4271 23.7858 21.4271C23.4484 21.4271 22.9 21.5542 22.4362 22.0606C21.972 22.5671 20.6641 23.7917 20.6641 26.2823C20.6641 28.7732 22.4782 31.1798 22.7312 31.5179C22.9843 31.8556 26.2331 37.1299 31.3781 39.159C35.6541 40.8455 36.524 40.51 37.4523 40.4257C38.3805 40.3413 40.447 39.201 40.8688 38.0191C41.2909 36.8371 41.2909 35.8242 41.1642 35.6122C41.0375 35.4015 40.7001 35.2748 40.194 35.0217C39.6878 34.7686 37.1992 33.5437 36.7351 33.375C36.271 33.2059 35.9336 33.1219 35.5961 33.6287C35.2584 34.1348 34.2891 35.2748 33.9937 35.6122C33.6983 35.9506 33.4029 35.9929 32.8968 35.7395C32.3907 35.4858 30.7602 34.9517 28.8263 33.2271C27.3216 31.8856 26.3054 30.2284 26.01 29.7216C25.7149 29.2155 26.0051 28.9654 26.2325 28.6889C26.6418 28.1909 27.3287 27.2955 27.4974 26.9581C27.6662 26.62 27.5818 26.3246 27.4554 26.0712C27.3287 25.8182 26.3448 23.3149 25.895 22.3137Z" fill="white"/>
</svg>
</div></a>
</>}
    </React.Fragment>
  );
};
export default Navbar;
