import { useNavigate } from "react-router-dom"

export default function LatestPost(props) {
    const months=["JAN","FEB","MAR","APR","MAY","JUN","JULY","AUG","SEP","OCT","NOV","DEC"]
   const nav=useNavigate()
   return <>
    <div className="border-[1px]  max-w-[300px] border-[#814576] h-fit rounded-lg shadow-xl">
<h2 className="font-[700] py-2 px-2 text-[15px] border-b-[1px] border-[#814576]">Latest Post</h2>
   <div>
    <table>
    {props.topic&&props.data?.map((data,i)=>{
        return <>
        <tr key={i} className=" w-full cursor-pointer max-w-[400px] p-2" onClick={()=>{nav("/marriage-toolkit/"+data.url,{ state: {title:data.title,content:data.content,img:data.image_path,date:data.date[2]+" "+months[data.date[1]-1]+" " +data.date[0],subHeading:data.topic,latestPost:props.data,topic:props.topic} })}}>
        {/* <div key={i} className="flex w-full max-w-[400px] p-2" onClick={()=>{nav("/marriage-toolkitins",{ state: {title:data.title,content:data.content,img:data.image_path,date:data.date[2]+" "+months[data.date[1]-1]+" " +data.date[0],topic:data.topic,latestPost:props.data,topic:[]} })}}> */}
       <td className="flex mt-1 mx-1">
        <img  src={`https://knotrite.in/backend/storage/${data.image_path}`}  alt="" width={250} className='border-2 rounded-xl min-w-[70px] max-w-[70px]  overflow-hidden'/>
        </td>
        <td>
        <div className='pl-3 '>
            <h3 className='font-[600] min-w-[180px] text-[12px] text-[#1d1c1c]'  dangerouslySetInnerHTML={ {__html:data.title}}/>
            <p className='font-[500] text-[13px] text-[#1F1F1FAB]'>{data.date[2]+" "+months[data.date[1]-1]+" " +data.date[0]}</p>
        </div>
        </td>
    {/* </div> */}
    </tr>
        </>
    })}
    </table>
   </div>
    </div>
    </>
}