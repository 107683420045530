
import { useEffect, useState } from "react";
import Wave from "../components/header/Wave";

import LatestPost from "../components/marriageToolkit/latestPost";
import Topic from "../components/marriageToolkit/topic";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get } from "../services/apiHandler";
export default function MarriageToolkitInsights() {
  const {state} = useLocation();
  const {url}=useParams()
  const nav=useNavigate()
  const [topic,setTopic]=useState(state?.topic||[])
  const [latestPost,setLatestPost]=useState(state?.latestPost||[])
  const [title,setTitle]=useState(state?.title)
  const [date,setDate]=useState(state?.date)
  const[content,setContent]=useState(state?.content)
  const[img,setImg]=useState(state?.img)
  const [subHeading,setSubHeading]=useState(state?.subHeading)
  const { } = state||{};
  useEffect(()=>{
   setTopic(state?.topic||[])
   setLatestPost(state?.latestPost||[])
   setTitle(state?.title)
   setDate(state?.date)
   setContent(state?.content)
   setImg(state?.img)
   setSubHeading(state?.subHeading)

},[url])
  function loadData() {
    


    get("api/get_marriage_toolkit")
      .then((res) => {
      
      if(res.data.success){
     
        let Blog={}
       
        let post=res.data.data.map((data)=>{
          data.orgDate=data.date;
          data.date= data.date.split("-")
          console.log(data.url);
          if(data.url==url){
           Blog =data
          }
           return data
        })
        
        // apiData=[...res.data.data]
        // setapiData([...apiData])
        setLatestPost(post.splice(0,4))
        
        // let Blog=post.filter((data)=>{return data.url==url?true:false})[0]
      
        console.log("post==>",Blog);
      setTitle(Blog.title)
      setDate(Blog.orgDate)
      setContent(Blog.content)
     setImg(Blog.image_path)
      setSubHeading(Blog.subHeading)
        get("api/get_topic")
      .then((res) => {
      
      if(res.data.success){
        setTopic(res.data.data.reverse())
        
      }})
     
      
      }  // window.location.reload();
          //  onClose();
        
      })
      .catch((error) => {
        
      });
  }
  useEffect(()=>{
    if(!state)
    loadData()
  },[])
  const datafilter=(data)=>{
nav("/marriage-toolkit",{state:{ind:data}})

  }
  return (
    <>
      <section>
       
        <Wave title="Marriage Toolkit" subTitle="Relationship Insights" current={"Marriage Toolkit-Relationship Insights"} subCurrent="Marriage Toolkit" link="/marriage-toolkit" />
        
        <div className="flex justify-around flex-wrap w-full mt-6">
          <div className="md:w-[60%]  mx-4 ">
          <div className="my-4">
            <h1 className="font-[700]  text-[30px] text-[#67365E]"  dangerouslySetInnerHTML={ {__html:title}} >
           
            </h1>
            
            <div className="flex">
              <svg
                width="18"
                height="21"
                viewBox="0 0 18 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.75 2.56V1C13.75 0.59 13.41 0.25 13 0.25C12.59 0.25 12.25 0.59 12.25 1V2.5H5.75V1C5.75 0.59 5.41 0.25 5 0.25C4.59 0.25 4.25 0.59 4.25 1V2.56C1.55 2.81 0.24 4.42 0.04 6.81C0.02 7.1 0.26 7.34 0.54 7.34H17.46C17.75 7.34 17.99 7.09 17.96 6.81C17.76 4.42 16.45 2.81 13.75 2.56ZM17 8.84H1C0.45 8.84 0 9.29 0 9.84V16C0 19 1.5 21 5 21H13C16.5 21 18 19 18 16V9.84C18 9.29 17.55 8.84 17 8.84ZM6.21 17.21C6.11 17.3 6 17.37 5.88 17.42C5.76 17.47 5.63 17.5 5.5 17.5C5.37 17.5 5.24 17.47 5.12 17.42C5 17.37 4.89 17.3 4.79 17.21C4.61 17.02 4.5 16.76 4.5 16.5C4.5 16.24 4.61 15.98 4.79 15.79C4.89 15.7 5 15.63 5.12 15.58C5.36346 15.48 5.63654 15.48 5.88 15.58C6 15.63 6.11 15.7 6.21 15.79C6.39 15.98 6.5 16.24 6.5 16.5C6.5 16.76 6.39 17.02 6.21 17.21ZM6.42 13.38C6.37 13.5 6.3 13.61 6.21 13.71C6.11 13.8 6 13.87 5.88 13.92C5.76 13.97 5.63 14 5.5 14C5.37 14 5.24 13.97 5.12 13.92C5 13.87 4.89 13.8 4.79 13.71C4.7 13.61 4.63 13.5 4.58 13.38C4.52863 13.2598 4.50145 13.1307 4.5 13C4.5 12.87 4.53 12.74 4.58 12.62C4.63 12.5 4.7 12.39 4.79 12.29C4.89 12.2 5 12.13 5.12 12.08C5.36346 11.98 5.63654 11.98 5.88 12.08C6 12.13 6.11 12.2 6.21 12.29C6.3 12.39 6.37 12.5 6.42 12.62C6.47 12.74 6.5 12.87 6.5 13C6.5 13.13 6.47 13.26 6.42 13.38ZM9.71 13.71C9.61 13.8 9.5 13.87 9.38 13.92C9.26 13.97 9.13 14 9 14C8.87 14 8.74 13.97 8.62 13.92C8.5 13.87 8.39 13.8 8.29 13.71C8.11 13.52 8 13.26 8 13C8 12.74 8.11 12.48 8.29 12.29C8.39 12.2 8.5 12.13 8.62 12.08C8.86 11.97 9.14 11.97 9.38 12.08C9.5 12.13 9.61 12.2 9.71 12.29C9.89 12.48 10 12.74 10 13C10 13.26 9.89 13.52 9.71 13.71Z"
                  fill="#AC4884"
                />
              </svg>
              &nbsp;&nbsp;
              <p className="font-[500] text-[13px] text-[#1F1F1FC7]">
               {date}
              </p>
              </div>
            </div>
            <img src={`https://knotrite.in/backend/storage/${img}`}  className="w-full max-h-[400px] rounded-3xl" alt="" />
            <div className="my-4">
            <h1 className="font-[700]  text-[20px] text-[#67365E]">
             {subHeading!="undefined"&&subHeading!="null"&&subHeading}
            </h1>
            </div>
            <p className="font-[400] text-[14px] text-justify
            text-[#383838] leading-7 li"  dangerouslySetInnerHTML={ {__html:content}}>
             
            </p>
          </div>
          <div className="  my-[2.5%] sticky top-[100px]  h-fit  ">
           {latestPost&& <LatestPost  data={latestPost} topic={topic}/>}
            <div className="mt-2">
              <Topic  data={topic} datafilter={datafilter}/>
            </div>
          </div>
        </div>
       
      </section>
    </>
  );
}
